import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { isMatch } from "lodash";

import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import {
    hideModuleDashboard,
    showModuleDashboard,
} from "../../../components/ModulePage/ModulePage.Redux";
import withTruck, { WithTruckProps } from "../../../components/withTruck";
import DashPlayer from "components/DashPlayer";
import { IRouteToRaceParams, routeFactory } from "../Routes";
import formatTime from "core/formatTime";
import { getTruckById } from "state/selectors";

import {
    selectedDateChanged,
    selectedEnvironmentChanged,
    selectedRaceChanged,
    selectedVenueChanged,
} from "modules/triples/redux/Module.Redux";
import MeetingSelector from "./MeetingSelector";
import RaceControls from "modules/triples/components/RaceControls/RaceControls";
import RaceSelector from "./RaceSelector";
import TrackMap from "modules/triples/components/TrackMap";
import LeftColumn from "modules/triples/components/LeftColumn";

import "./TimingPage.styles.less";

function TimingPage(props: WithTruckProps) {
    const { moduleId } = props;

    const moduleState = useAppSelector((state) => state.tripleSModuleState);
    const compositors = useAppSelector((state) => state.compositor);

    const truckId = props.truckId;
    let streamUrl: string | null = null;
    let venkmanName = "";
    if (truckId) {
        const compositor = Object.values(compositors).find((c) =>
            Object.keys(c.trucks).includes(truckId),
        );
        streamUrl = compositor?.streamUrlDash ?? null;
        venkmanName = compositor?.name ?? "";
    }

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (moduleId) {
            dispatch(showModuleDashboard(moduleId));
        }

        return () => {
            if (moduleId) {
                dispatch(hideModuleDashboard(moduleId));
            }
        };
    }, [dispatch, moduleId]);

    const {
        date,
        environment,
        race,
        venueList,
        venue: venueId,
    } = useParams<IRouteToRaceParams>();

    useEffect(() => {
        const state = moduleState;

        if (date && !state.dates?.includes(date)) {
            return;
        }

        if (state.selectedDate != date) {
            dispatch(selectedDateChanged(date ?? null));
        }

        if (!date) {
            return;
        }

        if (
            environment &&
            Object.values(state.venues).findIndex((v) => !!v[environment]) == -1
        ) {
            return;
        }

        if (state.selectedEnvironment != environment) {
            dispatch(selectedEnvironmentChanged(environment ?? null));
        }

        if (!environment) {
            return;
        }

        if (
            venueList &&
            routeFactory
                .parseVenueList(venueList)
                .map((v) => state.venues[v]?.[environment])
                .findIndex((v) => !!v) == -1
        ) {
            // If some venue in the venue list does not have our selected
            // environment, stop
            return;
        }

        if (venueId && !state.venues?.[venueId]) {
            return;
        }

        if (state.selectedVenue != venueId) {
            dispatch(selectedVenueChanged(venueId ?? null));
        }

        if (!venueId) {
            return;
        }

        const raceNumber =
            race === undefined ? undefined : Number.parseInt(race);
        if (
            raceNumber &&
            !state.venues[venueId]?.[environment]?.races?.[raceNumber]
        ) {
            return;
        }

        if (state.selectedRace != raceNumber) {
            dispatch(
                selectedRaceChanged(
                    date,
                    venueId,
                    environment,
                    raceNumber ?? null,
                ),
            );
        }
    }, [dispatch, date, environment, venueList, race, venueId, moduleState]);

    const { selectedDate, selectedVenue, selectedEnvironment, selectedRace } =
        moduleState;

    if (!moduleId) {
        return null;
    }

    return (
        <section className="sky-timing-controls">
            {selectedDate && selectedEnvironment && venueList ? (
                <div className="timing-controls-row">
                    <RaceSelector moduleId={moduleId} />
                    {selectedVenue && selectedRace && (
                        <>
                            <EnvironmentWarning
                                truckId={truckId}
                                moduleId={moduleId}
                                date={selectedDate}
                                venue={selectedVenue}
                                environment={selectedEnvironment}
                            />
                            <section className="three-column-container">
                                <LeftColumn
                                    moduleId={moduleId}
                                    date={selectedDate}
                                    venue={selectedVenue}
                                    environment={selectedEnvironment}
                                    race={selectedRace}
                                />
                                <section className="column">
                                    <RunningClock
                                        date={selectedDate}
                                        venue={selectedVenue}
                                        environment={selectedEnvironment}
                                        race={selectedRace}
                                    />
                                    <RaceControls
                                        venkmanName={venkmanName}
                                        moduleId={moduleId}
                                        selectedDate={selectedDate}
                                        selectedVenue={selectedVenue}
                                        selectedEnvironment={
                                            selectedEnvironment
                                        }
                                        selectedRace={selectedRace}
                                    />
                                </section>

                                <section className="column">
                                    {streamUrl && (
                                        <div className="dash-player-container">
                                            <DashPlayer
                                                src={streamUrl}
                                                showUrl
                                            />
                                        </div>
                                    )}
                                    <div className="track-map-container">
                                        <TrackMap
                                            moduleId={moduleId}
                                            selectedVenue={selectedVenue}
                                            selectedEnvironment={
                                                selectedEnvironment
                                            }
                                            selectedRace={selectedRace}
                                        />
                                    </div>
                                </section>
                            </section>
                        </>
                    )}
                </div>
            ) : (
                <MeetingSelector venkmanName={venkmanName} />
            )}
        </section>
    );
}

function RunningClock(props: {
    date: string;
    environment: string;
    venue: string;
    race: number;
}) {
    const race = useAppSelector(
        (state) =>
            state.tripleSModuleState.venues?.[props.venue]?.[props.environment]
                ?.races[props.race],
    );

    return (
        <div className="clock">
            {race?.clock && (
                <>
                    Clock: {formatTime(race.clock.time)} (
                    {race.clock.isTraceClock ? "T" : "J"})
                </>
            )}
        </div>
    );
}

function EnvironmentWarning(props: {
    truckId: string | undefined;
    moduleId: string;
    date: string;
    environment: string;
    venue: string;
}) {
    const truck = useAppSelector((state) =>
        getTruckById(state, props.truckId ?? ""),
    );
    const components = truck?.modules[props.moduleId]?.components;

    if (!components) {
        return null;
    }

    const componentInOtherStream = Object.values(components)
        .flatMap((cd) => Object.values(cd))
        .filter((cd) =>
            isMatch(cd.componentContext, {
                date: props.date,
                venueId: props.venue,
            }),
        )
        .find(
            (cd) =>
                !isMatch(cd.componentContext, {
                    environment: props.environment,
                }),
        );

    if (!componentInOtherStream) {
        return null;
    }

    const badStream = componentInOtherStream.componentContext
        .environment as string;

    return (
        <div className="environment-warning">
            Components already enabled for stream&nbsp;<b>{badStream}</b>
            &nbsp;at this venue. Are you sure you want to be here?
        </div>
    );
}

export default withTruck(TimingPage);
