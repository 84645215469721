import React, {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import cn from "classnames";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

import { Event, RaceWithNumber } from "modules/triples/redux/redux";
import { IRouteToRaceParams, routeFactory } from "../../Routes";
import MultiRaceComponentButton from "modules/triples/components/MultiRaceComponentButton/MultiRaceComponentButton";
import { getComponentsForModule } from "modules/triples/components/RaceControls/RaceControls";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectedRaceChanged as dispatchSelectedRaceChanged } from "modules/triples/redux/Module.Redux";
import RaceCountdownTimer from "modules/triples/components/SelectionHeader/RaceCountdownTimer";
import RaceTypeIcon from "modules/atc/components/RaceSelection/RaceTypeIcon";
import FormButton from "components/Forms/FormButton";

import "./RaceSelector.styles.less";

interface EventInformation {
    venueId: string;
    venueName: string;
    venueType: string;
    date: string;
    race: RaceWithNumber;
}

interface RaceSelectorProps {
    moduleId: string;
}

const getRaceStatus = (minutesToRace: number): string => {
    // Cater for old data where the status wasn't included as part of the race details.
    return minutesToRace >= 0 ? "Normal" : "Paying";
};

export default function RaceSelector(props: RaceSelectorProps) {
    const {
        selectedDate,
        selectedEnvironment,
        selectedVenue,
        selectedRace,
        venues,
    } = useAppSelector((state) => state.tripleSModuleState);

    const dispatch = useAppDispatch();

    const currentRaceRef = useRef<HTMLDivElement>(null);

    const params = useParams<IRouteToRaceParams>();
    const filteredVenues = useMemo(
        () => routeFactory.parseVenueList(params.venueList),
        [params],
    );

    const racesOnDate =
        (selectedDate &&
            selectedEnvironment &&
            (
                Object.entries(venues)
                    .flatMap(([venueId, environments]) =>
                        filteredVenues.findIndex((v) => v === venueId) != -1
                            ? environments[selectedEnvironment]
                            : undefined,
                    )
                    .filter((ev) => ev !== undefined) as Event[]
            ).flatMap((ev) =>
                Object.entries(ev.races).map(
                    ([n, r]) =>
                        ({
                            venueId: ev.venueId,
                            venueName: ev.venueName,
                            venueType: ev.venueType,
                            date: ev.date,
                            race: { ...r, number: parseInt(n) },
                        } as EventInformation),
                ),
            )) ||
        [];

    const navigate = useNavigate();

    const handleRaceSelected = useCallback(
        (venueId: string, race: RaceWithNumber) => {
            const pathname = routeFactory.hasRace({
                ...params,
                venue: venueId,
                race: race.number.toString(),
            });
            if (pathname) {
                // TODO: For some reason removing this means it doesn't get
                // properly sent in the TimingPage effect. We should investigate
                // this properly instead of leaving this hack here
                dispatch(
                    dispatchSelectedRaceChanged(
                        params.date!,
                        venueId,
                        params.environment!,
                        race.number,
                    ),
                );
                navigate(pathname, { replace: true });
            }
        },
        [dispatch, params, navigate],
    );

    const handleChangeVenues = useCallback(() => {
        const pathname = routeFactory.hasEnvironment(params);
        if (pathname) {
            navigate(pathname, { replace: true });
        }
    }, [params, navigate]);

    const { moduleId } = props;

    const meetRaces = sortRaces(racesOnDate);

    const currentRace = meetRaces.findIndex((ev) => {
        const raceTime = moment.utc(ev.race.scheduledTime).local();
        const secondsToRace = raceTime.diff(new Date(), "seconds");
        return secondsToRace >= 0;
    });

    const handleGoToCurrentRace = () => {
        if (currentRaceRef.current) {
            currentRaceRef.current.scrollIntoView();
            if (currentRace != -1) {
                const meet = meetRaces[currentRace];
                if (meet) {
                    handleRaceSelected(meet.venueId, meet.race);
                }
            }
        }
    };

    useEffect(() => {
        // Scroll to the current race on first component load
        if (currentRaceRef.current) {
            handleGoToCurrentRace();
        }
        // We only want this function to run once on component mount. eslint
        // doesn't seem to like the empty dep array and wants us to put in
        // handleGoToCurrentRace.
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="sky-meeting-selector">
            <div className="col">
                <div
                    className="back"
                    style={{ cursor: "pointer" }}
                    onClick={handleChangeVenues}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} size="2x" />
                </div>
            </div>
            <div className="col">
                <div className="multi-enable">
                    <MultiRaceComponentButton
                        title="Enable all races"
                        componentType={getComponentsForModule(moduleId).auto}
                        componentContext={{
                            date: selectedDate,
                            environment: selectedEnvironment,
                        }}
                        venuesAndRaces={meetRaces.map((ev) => ({
                            venueId: ev.venueId,
                            race: ev.race.number,
                        }))}
                    />
                </div>
            </div>
            <div className="col">
                <div className="options-col">
                    {meetRaces.map((ev, i) => {
                        return (
                            <RaceButton
                                key={`${ev.venueId}-${ev.race.number}`}
                                event={ev}
                                isSelected={
                                    selectedVenue === ev.venueId &&
                                    selectedRace === ev.race.number
                                }
                                ref={
                                    currentRace === i
                                        ? currentRaceRef
                                        : undefined
                                }
                                onSelected={handleRaceSelected}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="col">
                <FormButton
                    title="Go To Current Race"
                    onClick={handleGoToCurrentRace}
                    className="current-race-button"
                />
            </div>
        </section>
    );
}

function sortRaces(races: EventInformation[]) {
    return races.sort(
        (a, b) =>
            new Date(a.race.scheduledTime).valueOf() -
            new Date(b.race.scheduledTime).valueOf(),
    );
}

type RaceButtonProps = {
    event: EventInformation;

    isSelected: boolean;
    onSelected: (venueId: string, race: RaceWithNumber) => void;
};

const RaceButton = forwardRef(function RaceButton(
    props: RaceButtonProps,
    ref: React.ForwardedRef<HTMLDivElement>,
) {
    const classNames = cn("selectable option race-option", {
        selected: props.isSelected,
    });

    const {
        event: { venueId, venueName, venueType, race },
        onSelected,
    } = props;

    const handleSelected = useCallback(
        () => onSelected(venueId, race),
        [venueId, race, onSelected],
    );

    const raceTime = moment.utc(race.scheduledTime).local();
    const minutesToRace = raceTime.diff(new Date(), "minutes");

    const raceStatus = getRaceStatus(minutesToRace);
    const detailsClassNames = cn("details", `status-${raceStatus}`);

    return (
        <div
            key={`V${venueId}-R${race.number}`}
            className={classNames}
            onClick={handleSelected}
            ref={ref}
        >
            <div className="content race-button">
                <RaceTypeIcon raceType={venueType} />
                <div className={detailsClassNames}>
                    <p>
                        {venueName}
                        <br />R{race.number} ({raceTime.format("HH:mm")})
                    </p>
                    <p className="time-to-race">
                        <RaceCountdownTimer
                            raceStartTime={race.scheduledTime}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
});
