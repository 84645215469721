import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

export interface ITimeContext {
    now: Date;
}

const DEFAULT_CONTEXT: ITimeContext = {
    now: new Date(),
};

export function useCurrentTime() {
    const { now } = useContext(TimeContext);
    return now;
}

export const TimeContext = createContext<ITimeContext>(DEFAULT_CONTEXT);

const TimeContextProvider = (props: PropsWithChildren<unknown>) => {
    const intervalRef = useRef<number | undefined>(undefined);
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        intervalRef.current = window.setInterval(() => {
            setNow(new Date());
        }, 1000);

        return () => clearInterval(intervalRef.current);
    });

    const context = {
        now,
    };

    return (
        <TimeContext.Provider value={context}>
            {props.children}
        </TimeContext.Provider>
    );
};

export default TimeContextProvider;
