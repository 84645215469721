import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch } from "../../../state/store";

import {
    updateModuleDashboardData,
    updateModuleState,
} from "../../../components/ModulePage/ModulePage.Redux";
import {
    HorseType,
    IMeetVenuesByDate,
    IndividualTrainer,
    IRaceDetails,
    IRacingDashboardState,
    ManualPositionType,
    MeetIdentity,
    Protest,
    RaceIdentity,
    ManualSuperKey,
    ManualSuper,
    IABARaceDetails,
} from "../types";
import * as fromModuleState from "./Module.Selectors";

export interface IRootState extends IRacingDashboardState {
    subscribedMeets: MeetIdentity[];
    selectedMeet: MeetIdentity | null;
    selectedHorse: HorseType | null;

    showOnlyWinPrice: boolean;
    useFixedBackground: boolean;
    useMarketOrder: boolean;
    scrollTotes: boolean;
    scrollDividends: boolean;
    isCarnival: boolean;

    protest: Protest;

    commentatorPickTitle: string;

    trainerName: string;

    /** Maps the race number to the manually entered placings */
    manualPlacings: Record<number, ManualPositionType[]>;

    manualSupers: Record<string, ManualSuper>;
}

export type IModuleStateUpdate = IRootState & {
    races: IMeetVenuesByDate;
    horses: IRaceDetails;

    abaHorses: IABARaceDetails;
};

export const DashboardDataTypes = {
    SELECTED_RACE: "SelectedRace",
};

interface IManualPlacingEvent {
    raceNumber: number;
    manualPositions: ManualPositionType[];
}

export const selectedRaceChanged =
    (raceIdentity: RaceIdentity) => (dispatch: AppDispatch) => {
        dispatch(slice.actions.setSelectedRace());
        updateModuleDashboardData(
            DashboardDataTypes.SELECTED_RACE,
            raceIdentity,
        )(dispatch);
    };

const createIndividualTrainers = (
    combinedTrainers: string,
): IndividualTrainer[] => {
    const trainers = combinedTrainers.trim().split(/[&,]+/);
    let finalTrainers: string[] = [];

    if (trainers.length > 1) {
        const lastName = trainers[trainers.length - 1]!.split(" ")
            .slice(-1)[0]
            ?.trim();

        trainers.map((trainer) => {
            const splitTrainers = trainer.trim().split(" ");
            if (splitTrainers.length === 1) {
                // This should be just the first name
                const firstName = trainer.trim();
                finalTrainers.push(`${firstName} ${lastName ?? ""}`);
            } else {
                // This should be a first and last name
                finalTrainers.push(trainer.trim());
            }
        });
    } else {
        finalTrainers = [combinedTrainers.trim()];
    }
    return finalTrainers.map((trainer) => ({
        name: trainer,
        isSelected: false,
    }));
};

export function buildManualSuperKey(key: ManualSuperKey) {
    const { race, horse, type } = key;
    return `${race.date}_${race.state}_${race.venue}_${race.raceNumber}_${
        horse ?? 0
    }_${type}`;
}

const initialState: IRootState = {
    subscribedMeets: [],
    selectedMeet: null,
    selectedHorse: null,

    meets: {},
    selectedRace: null,
    selectedABARace: null,
    dividends: null,
    lastError: null,
    isSubscribed: false,

    protest: {
        accuserHorseNumber: null,
        accuseeHorseNumber: null,
        reason: "",
    },

    commentatorPickTitle: "Corey's Pick of the Yard",
    trainerName: "",

    useFixedBackground: false,
    scrollTotes: false,
    scrollDividends: false,
    showOnlyWinPrice: false,
    useMarketOrder: false,
    isCarnival: false,

    manualPlacings: [],

    manualSupers: {},
};

const slice = createSlice({
    name: "atcModule",
    initialState,
    reducers: {
        setSelectedMeet: (state, action: PayloadAction<MeetIdentity>) => {
            const { subscribedMeets: prevSubscribedMeets } = state;
            const meet = action.payload;

            // Remove any meets that are no longer available
            const getExistingIdentity = fromModuleState.getMeetIdentity(state);

            const updatedSubscribedMeets = [
                meet,
                ...prevSubscribedMeets.filter((prevMeet) => {
                    if (
                        prevMeet.date === meet.date &&
                        prevMeet.state === meet.state &&
                        prevMeet.eventType === meet.eventType &&
                        prevMeet.venue === meet.venue
                    ) {
                        return false;
                    }

                    return !!getExistingIdentity(
                        prevMeet.date,
                        prevMeet.state,
                        prevMeet.eventType,
                        prevMeet.venue,
                    );
                }),
            ];

            const commentatorPickTitle = "Chantelle's Pick of the Yard";

            return {
                ...state,
                subscribedMeets: updatedSubscribedMeets,
                selectedMeet: meet,
                commentatorPickTitle,
            };
        },

        setSelectedHorse: (
            state,
            action: PayloadAction<HorseType | undefined | null>,
        ) => {
            return {
                ...state,
                selectedHorse: action.payload ?? null,
                trainerName: action.payload ? action.payload.trainer : "",
            };
        },

        toggleFixedBackground: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                useFixedBackground: action.payload,
            };
        },

        toggleMarketOrder: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                useMarketOrder: action.payload,
            };
        },

        toggleScrollTotes: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                scrollTotes: action.payload,
            };
        },

        toggleScrollDividends: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                scrollDividends: action.payload,
            };
        },

        toggleShowOnlyWinPrice: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                showOnlyWinPrice: action.payload,
            };
        },

        toggleCarnival: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isCarnival: action.payload,
            };
        },

        setProtestAccuser: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                protest: {
                    ...state.protest,
                    accuserHorseNumber: action.payload as unknown as number,
                },
            };
        },
        setProtestAccusee: (state, action: PayloadAction<string | null>) => {
            return {
                ...state,
                protest: {
                    ...state.protest,
                    accuseeHorseNumber: action.payload as unknown as number,
                },
            };
        },
        setProtestReason: (state, action: PayloadAction<string, string>) => {
            return {
                ...state,
                protest: {
                    ...state.protest,
                    reason: action.payload,
                },
            };
        },
        setCommentatorPickTitle: (
            state,
            action: PayloadAction<string, string>,
        ) => {
            return {
                ...state,
                commentatorPickTitle: action.payload,
            };
        },
        setTrainerName: (state, action: PayloadAction<string, string>) => {
            return {
                ...state,
                trainerName: action.payload,
            };
        },
        clearRaceSpecificState: (state) => {
            return {
                ...state,
                protest: initialState.protest,
            };
        },

        setManualPlacings: {
            reducer: (state, action: PayloadAction<IManualPlacingEvent>) => {
                const {
                    payload: { raceNumber, manualPositions },
                } = action;

                state.manualPlacings[raceNumber] = manualPositions;
            },
            prepare: (
                raceNumber: number,
                manualPositions: ManualPositionType[],
            ) => {
                return { payload: { raceNumber, manualPositions } };
            },
        },

        setSelectedRace: (state) => {
            state.selectedRace = null;
            state.dividends = null;
            state.manualPlacings = [];
        },

        setManualSuper: {
            reducer: (
                state,
                action: PayloadAction<{
                    key: ManualSuperKey;
                    value: ManualSuper;
                }>,
            ) => {
                const {
                    payload: { key, value },
                } = action;

                const keyString = buildManualSuperKey(key);
                state.manualSupers[keyString] = value;
            },
            prepare: (key: ManualSuperKey, value: ManualSuper) => {
                return { payload: { key, value } };
            },
        },
    },

    extraReducers: (builder) => {
        builder.addCase(updateModuleState, (state, action) => {
            // Remap properties from Harmony until we've fixed up the packets
            const {
                races: meets,
                horses: selectedRace,
                abaHorses: selectedABARace,
                ...payload
            } = action.payload as IModuleStateUpdate; // TODO this typecast is dodgy

            // Don't overwrite dividends unless it makes sense
            const { dividends: lastDividends, selectedRace: lastSelectedRace } =
                state;

            const newState = {
                ...state,
                ...payload,
            };

            // The updated state may not have all properties on there, so only overwrite these remapped ones
            // if they actually exist
            if (meets !== undefined) newState.meets = meets;
            if (selectedRace !== undefined) {
                if (selectedRace) {
                    const runners = selectedRace.runners.sort(
                        (left, right) => left.number - right.number,
                    );
                    runners.map(
                        (runner) =>
                            (runner.individualTrainers =
                                createIndividualTrainers(runner.trainer)),
                    );

                    newState.selectedRace = {
                        ...selectedRace,
                        runners,
                    };

                    if (
                        lastSelectedRace &&
                        selectedRace.raceNumber ===
                            lastSelectedRace.raceNumber &&
                        !payload.dividends
                    ) {
                        newState.dividends = lastDividends;
                    }
                } else {
                    newState.selectedRace = null;
                }
            }
            if (selectedABARace !== undefined) {
                if (selectedABARace) {
                    newState.selectedABARace = selectedABARace;
                } else {
                    newState.selectedABARace = null;
                }
            }

            return newState;
        });
    },
});

export const {
    setSelectedMeet,
    setSelectedHorse,
    toggleFixedBackground,
    toggleMarketOrder,
    toggleScrollTotes,
    toggleScrollDividends,
    toggleShowOnlyWinPrice,
    toggleCarnival,
    setProtestAccuser,
    setProtestAccusee,
    setProtestReason,
    clearRaceSpecificState,
    setCommentatorPickTitle,
    setTrainerName,
    setManualPlacings,
    setManualSuper,
} = slice.actions;

export default slice.reducer;
