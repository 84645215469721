import { useAppSelector } from "../../../../state/hooks";
import Heartbeat from "../Heartbeat";
import RaceTitle from "../RaceTitleBar/RaceTitle";
import RunnersTable from "../RunnersTable";

import "./LeftColumn.less";

type Props = {
    moduleId: string;
    date: string;
    environment: string;
    venue: string;
    race: number;
};

export default function LeftColumn(props: Props) {
    const { race, raceState, heartbeat } = useAppSelector((state) => {
        const { venues, heartbeat, raceState } = state.tripleSModuleState;

        const race =
            venues?.[props.venue]?.[props.environment]?.races[props.race];
        const raceWithNumber = race && {
            ...race,
            number: props.race,
        };
        return {
            race: raceWithNumber,
            raceState,
            heartbeat,
        };
    });

    return (
        <>
            <section className="column">
                <div className="column-header">
                    {race ? (
                        <>
                            <RaceTitle
                                race={race}
                                raceState={raceState}
                                environment={props.environment}
                                heartbeat={heartbeat}
                            />
                        </>
                    ) : (
                        <section className="meet-title-bar">
                            <div className="meet-container">
                                <div className="venue">{props.venue}</div>
                                <div>{props.date}</div>
                            </div>
                            <Heartbeat heartbeat={heartbeat} />
                        </section>
                    )}
                </div>
                <div className="left-column">
                    <div className="runners-column">
                        <RunnersTable
                            moduleId={props.moduleId}
                            selectedDate={props.date}
                            selectedEnvironment={props.environment}
                            selectedVenue={props.venue}
                            selectedRace={props.race}
                        />
                    </div>
                </div>
            </section>
        </>
    );
}
