import React, { useCallback } from "react";
import cn from "classnames";

import "./style.less";

interface IProps {
    id: string;
    name: string;
    icon: string;
    active: boolean;
    onSelected: (moduleId: string) => void;
}

function ModuleDetails(props: IProps) {
    const { id, name, icon, active, onSelected } = props;

    const classNames = cn("module", { active });
    const handleClick = useCallback(() => onSelected(id), [id, onSelected]);

    return (
        <div className={classNames} onClick={handleClick}>
            <h1>{name}</h1>
            <div className="module-icon">
                <img src={icon} alt={name} />
            </div>
        </div>
    );
}

export default React.memo(ModuleDetails);
