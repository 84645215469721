import Modal from "components/Modal";
import FormButton from "../../../components/Forms/FormButton";

interface IProps {
    show: boolean;
    onHide: () => void;
}

const ShortcutKeyDialog = ({ show, onHide }: IProps) => {
    return (
        <Modal open={show} onClose={onHide} className="shortcut-key-dialog">
            <div className="dialog-title-bar">Shortcut Keys</div>

            <div className="dialog-content">
                <table>
                    <tbody>
                        <tr>
                            <td>H + &darr;</td>
                            <td>Next Horse</td>
                        </tr>
                        <tr>
                            <td>H + &uarr;</td>
                            <td>Prev Horse</td>
                        </tr>
                        <tr>
                            <td>H + &lt;1-99&gt;</td>
                            <td>Go To Horse &lt;NUM&gt;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>R + &darr;</td>
                            <td>Next Race</td>
                        </tr>
                        <tr>
                            <td>R + &uarr;</td>
                            <td>Prev Race</td>
                        </tr>
                        <tr>
                            <td>R + &lt;1-99&gt;</td>
                            <td>Go To Race &lt;NUM&gt;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>C + DELETE</td>
                            <td>Clear All Components</td>
                        </tr>
                        <tr>
                            <td>C + ESC</td>
                            <td>Clear All Components</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="dialog-action-bar">
                <FormButton title="Close" onClick={onHide} />
            </div>
        </Modal>
    );
};

export default ShortcutKeyDialog;
