import React, { useEffect } from "react";

export default React.memo(function Environment() {
    useEffect(() => {
        if (__ENVIRONMENT_NAME__) {
            document.title = `Grub [${__ENVIRONMENT_NAME__}]`;
        } else {
            document.title = "Grub";
        }
    }, []);

    return __ENVIRONMENT_NAME__ ? <span>[{__ENVIRONMENT_NAME__}]</span> : null;
});
