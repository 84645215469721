import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "./Auth";
import connectionReducer from "components/WebSocketConnection/WebSocketConnection.redux";
import compositorReducer from "./Compositor.redux";
import availableModulesReducer from "../components/ModulePage/ModulePage.Redux";

import atcReducer from "modules/atc/redux/Module.Redux";
import atcMiddleware from "modules/atc/redux/middleware";
import tripleSReducer from "modules/triples/redux/Module.Redux";

const reducer = combineReducers({
    auth: authReducer,
    connection: connectionReducer,
    availableModules: availableModulesReducer,
    compositor: compositorReducer,
    moduleState: atcReducer,
    tripleSModuleState: tripleSReducer,
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(atcMiddleware),
});

export type IGlobalState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
