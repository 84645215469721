function getParameterByName(name: string, url?: string): string {
    if (!url) url = window.location.href;
    name = name.replace(/[[]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return "";
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const harmonyQS = getParameterByName("harmony");

function getWebSocketRoot() {
    let root = __HARMONY_ENDPOINT__;
    if (root.indexOf("http") === 0) {
        root = root.replace("http", "ws");
    } else if (root.indexOf("://") === -1) {
        root = "ws://" + root;
    }

    if (harmonyQS) {
        root = harmonyQS;
        if (root.indexOf("http") === 0) {
            root = root.replace("http", "ws");
        } else if (root.indexOf("://") === -1) {
            root = "ws://" + root;
        }
    }
    return root;
}

function getWebAPIRoot() {
    let root = __HARMONY_ENDPOINT__;
    if (root.indexOf("ws:") === 0 || root.indexOf("wss:") === 0) {
        root = root.replace("ws", "http");
    } else if (root.indexOf("://") === -1) {
        root = "http://" + root;
    }

    if (harmonyQS) {
        root = harmonyQS;
        if (root.indexOf("://") === -1) {
            root = "http://" + root;
        }
    }
    return root;
}

export { getWebSocketRoot, getWebAPIRoot };
