import { Navigate, useParams } from "react-router-dom";
import StartTruckSessionButton from "./StartTruckSessionButton/StartTruckSessionButton";
import { ModelState } from "resources/harmonyTypes";
import { unescapeRouteValue } from "core/Routing";
import { useAppSelector } from "state/hooks";
import { routeFactory } from "components/StandardLayout/Routes";

export default function CompositorPage() {
    const { compositorId, tenantId } = useParams<{
        compositorId: string;
        tenantId: string;
    }>();
    const compositor = useAppSelector((state) =>
        compositorId
            ? state.compositor[unescapeRouteValue(compositorId)]
            : undefined,
    );

    if (!tenantId) {
        return null;
    }

    if (!compositor) {
        console.warn("There is no compositor... how did I get here?");
        const pathname = routeFactory.toCompositorSelector({ tenantId });
        return <Navigate to={pathname} />;
    }
    if (compositor.state === "unavailable") {
        console.warn("Compositor unavailable");
        const pathname = routeFactory.toCompositorSelector({ tenantId });
        return <Navigate to={pathname} />;
    }

    const trucks = Object.entries(compositor.trucks).map(([id, truck]) => ({
        ...truck,
        id,
    }));

    // For now we will assume one truck instance per venkman, sounds like that may change
    if (trucks[0]?.state === ModelState.Connected) {
        const pathname = routeFactory.toModuleSelector({
            tenantId,
            truckId: trucks[0].id,
        });
        return <Navigate to={pathname} />;
    }

    return (
        <>
            <h1 key={1}>{compositor && compositor.name}</h1>,
            <StartTruckSessionButton compositor={compositor} key={2} />,
        </>
    );
}
