import { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import cn from "classnames";
import moment from "moment";

import {
    selectedRaceChanged,
    clearRaceSpecificState,
} from "../../redux/Module.Redux";
import { IMeetVenuesByDate, IRace } from "../../types";
import { IRouteToRaceParams, routeFactory } from "../../Routes";
import "./styles.less";
import RaceCountdownTimer from "./RaceCountdownTimer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
    faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch } from "state/hooks";
import MultiRaceComponentButton from "modules/triples/components/MultiRaceComponentButton/MultiRaceComponentButton";

const getRaceStatus = (race: IRace, minutesToRace: number): string => {
    if (race.raceStatus) return race.raceStatus;

    // Cater for old data where the status wasn't included as part of the race details.
    return minutesToRace >= 0 ? "Normal" : "Paying";
};

interface IProps {
    meets: IMeetVenuesByDate;
    multiEnableConfig?: {
        components: string[];
        visualContext?: Record<string, any>;
    };
}

export default function RaceSelector(props: IProps) {
    const { meets } = props;
    const params = useParams<IRouteToRaceParams>();
    const { date, state, venue, race, eventType } = params;

    const racesByVenue = date !== undefined ? meets?.[date] : undefined;
    const statesByVenue =
        venue !== undefined ? racesByVenue?.[venue] : undefined;
    const racesForVenue =
        state !== undefined && eventType !== undefined
            ? statesByVenue?.[state]?.[eventType]
            : undefined;
    const selectedRace =
        race !== undefined && race !== "images"
            ? parseInt(race, 10)
            : undefined;

    const hasRaces = racesForVenue && racesForVenue.length;

    const navigate = useNavigate();
    const handleChangeLocation = useCallback(() => {
        const pathname = routeFactory.hasMeetingVenue(params);
        if (pathname) {
            navigate(pathname, { replace: true });
        }
    }, [params, navigate]);

    return (
        <section className="meeting-selector">
            <div className="row">
                <div
                    className="back"
                    style={{ cursor: "pointer" }}
                    onClick={handleChangeLocation}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} size="2x" />
                </div>
                <div
                    key="location"
                    className="row-name option highlight location"
                >
                    <div className="content">
                        <div className="icon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                        </div>
                        <div className="details">
                            <p>
                                {venue} [{state}, {eventType}]
                            </p>
                            <p>{date}</p>
                        </div>
                    </div>
                </div>
                {props.multiEnableConfig && (
                    <div className="multi-enable">
                        <MultiRaceComponentButton
                            title="Enable all races"
                            componentType={props.multiEnableConfig.components}
                            componentContext={{
                                date,
                                state,
                                venue,
                                eventType,
                            }}
                            visualContext={
                                props.multiEnableConfig.visualContext
                            }
                            races={
                                racesForVenue?.map((r) => r.raceNumber) ?? []
                            }
                            raceKey="raceNumber"
                        />
                    </div>
                )}
                <div className="options">
                    {hasRaces && (
                        <>
                            {racesForVenue?.map((race) => (
                                <RaceButton
                                    key={race.raceNumber}
                                    race={race}
                                    selected={race.raceNumber === selectedRace}
                                />
                            ))}
                            <SingleImageButton selected={race === "images"} />
                        </>
                    )}
                    {!hasRaces && (
                        <div className="fallback">No races found.</div>
                    )}
                </div>
            </div>
        </section>
    );
}

function RaceButton(props: { race: IRace; selected: boolean }) {
    const classNames = cn("selectable option", {
        selected: props.selected,
    });
    const { race } = props;

    const raceTime = moment(race.raceTime);
    const minutesToRace = raceTime.diff(new Date(), "minutes");

    const raceStatus = getRaceStatus(race, minutesToRace);
    const detailsClassNames = cn("details", `status-${raceStatus}`);

    const dispatch = useAppDispatch();
    const params = useParams<IRouteToRaceParams>();
    const navigate = useNavigate();
    const handleRaceSelected = useCallback(() => {
        const pathname = routeFactory.hasRace({
            ...params,
            race: race.raceNumber.toString(),
        });

        if (pathname) {
            navigate(pathname, { replace: true });
        }

        const { date, state, eventType, venue } = params;
        if (date && state && eventType && venue) {
            dispatch(
                selectedRaceChanged({
                    date,
                    state,
                    venue,
                    eventType,
                    raceNumber: race.raceNumber,
                }),
            );
        }

        dispatch(clearRaceSpecificState());
    }, [params, dispatch, navigate, race.raceNumber]);

    return (
        <div
            key={`R${race.raceNumber}`}
            className={classNames}
            onClick={handleRaceSelected}
        >
            <div className="content center">
                <div className={detailsClassNames}>
                    <p>
                        R{race.raceNumber} - {raceTime.format("HH:mm")}
                    </p>
                    <p className="time-to-race">
                        <RaceCountdownTimer
                            raceStartTime={race.raceTime}
                            raceStatus={race.raceStatus}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
}

function SingleImageButton(props: { selected: boolean }) {
    const classNames = cn("selectable option", {
        selected: props.selected,
    });

    const dispatch = useAppDispatch();
    const params = useParams<IRouteToRaceParams>();
    const navigate = useNavigate();
    const handleImagesSelected = useCallback(() => {
        const pathname = routeFactory.hasRace({
            ...params,
            race: "images",
        });

        if (pathname) {
            navigate(pathname, { replace: true });
        }

        dispatch(clearRaceSpecificState());
    }, [params, dispatch, navigate]);

    return (
        <div className={classNames} onClick={handleImagesSelected}>
            <div className="content center">IMAGES</div>
        </div>
    );
}
