import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import useEffectAsync from "core/hooks/useEffectAsync";
import { useState } from "react";
import Modal from "components/Modal";
import { useTruck } from "components/withTruck";
import { Components, IFullFrameImage } from "../../types";
import ComponentButton from "../ComponentButton/ComponentButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import FullFrameImageUploader from "../AssetTypes/FullFrameImage/FullFrameImageUploader";

import "./styles.less";

export default function SingleImagePage() {
    const truck = useTruck();
    const moduleId = truck.moduleId ?? "";

    const [imageList, setImageList] = useState<IFullFrameImage[]>([]);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const reloadImageList = async () => {
        if (!moduleId) return;

        const images = await harmony.get<IFullFrameImage[]>(
            moduleId,
            "LoadFullFrameImageList",
        );

        images.sort((left, right) =>
            left.name
                .toLocaleLowerCase()
                .localeCompare(right.name.toLocaleLowerCase()),
        );
        setImageList(images);
    };

    useEffectAsync(async () => {
        await reloadImageList();
    }, [moduleId]);

    if (!moduleId) return null;

    return (
        <div className="single-image-page">
            <button className="button" onClick={() => setUploadModalOpen(true)}>
                UPLOAD NEW IMAGE
            </button>
            <Modal
                open={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
                className="sponsor-logo-dialog"
            >
                <FullFrameImageUploader
                    onFinish={(_) => {
                        setUploadModalOpen(false);
                        void (async () => reloadImageList())();
                    }}
                    moduleId={moduleId}
                />
            </Modal>
            <div className="image-list">
                {imageList.map((im) => (
                    <div className="image-container" key={im.id}>
                        <div className="image-delete">
                            <FontAwesomeIcon
                                icon={faWindowClose}
                                onClick={() => {
                                    harmony
                                        .post(
                                            moduleId,
                                            "RemoveFullFrameImage",
                                            {
                                                id: im.id,
                                            },
                                        )
                                        .then(reloadImageList)
                                        .catch((e) =>
                                            console.error(
                                                "Failed to remove sponsor",
                                                e,
                                            ),
                                        );
                                }}
                            />
                        </div>
                        <img src={im.src} />
                        <div className="image-name">{im.name}</div>
                        <div className="single-image-button">
                            <ComponentButton
                                title="FF Single Image"
                                componentType={Components.ff_singleImage}
                                componentContext={{
                                    id: im.id,
                                }}
                                hideOthers
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
