import { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";

import withTruck, { WithTruckProps } from "components/withTruck";
import SelectionBar from "components/SelectionBar";
import { Event, EventType } from "../../redux/redux";
import { IRouteToRaceParams, routeFactory } from "../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { IGlobalState } from "state/store";
import RaceTypeIcon from "modules/atc/components/RaceSelection/RaceTypeIcon";

import cn from "classnames";
import "./MeetingSelector.less";
import ComponentButton from "modules/atc/components/ComponentButton/ComponentButton";
import { Components } from "../RaceControls/RaceControls";

interface IProps {
    venkmanName: string;
    dates: string[];
    venues: { [venueId: string]: { [environment: string]: Event } };

    selectedDate: string | null;
    selectedVenue: string | null;
    selectedEnvironment: string | null;
}

interface IModuleProps {
    numModules: number;
}

type Venue = {
    id: string;
    name: string;
    eventType: EventType;
};

const mapStateToProps = (state: IGlobalState): IModuleProps => ({
    numModules: state.availableModules.length,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type MeetingSelectorProps = IModuleProps &
    IProps &
    WithTruckProps &
    PropsFromRedux;

function MeetingSelector(props: MeetingSelectorProps) {
    const params = useParams<IRouteToRaceParams>();
    const navigate = useNavigate();

    const { numModules, venkmanName } = props;

    const handleDateSelected = useCallback(
        (date: string) => {
            const pathname = routeFactory.hasMeetingDate({ ...params, date });
            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [params, navigate],
    );

    const handleVenueSelected = useCallback(
        (venue: Venue) => {
            const pathname = routeFactory.hasMeetingVenue({
                ...params,
                venue: venue.id,
            });
            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [params, navigate],
    );

    const handleEnvironmentSelected = useCallback(
        (environment: string) => {
            const pathname = routeFactory.hasEnvironment({
                ...params,
                environment,
            });
            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [params, navigate],
    );

    const changeModule = useCallback(() => {
        const pathname = routeFactory.toModuleSelector(params);

        if (pathname) {
            navigate(pathname, { replace: true });
        }
    }, [params, navigate]);

    const { selectedDate, selectedVenue, selectedEnvironment } = props;

    const dates = orderDates(props.dates);
    const venues = selectedDate
        ? orderVenues(
              Object.entries(props.venues).map(([id, venue]) => ({
                  id,
                  name: Object.values(venue)[0]?.venueName ?? "",
                  eventType: Object.values(venue)[0]?.venueType ?? "?",
              })),
          )
        : undefined;

    const venue =
        selectedDate && selectedVenue ? props.venues[selectedVenue] : undefined;
    const environments = venue && orderEnvironments(Object.keys(venue));

    return (
        <>
            <section className="meeting-selector">
                {numModules > 1 && (
                    <div className="row">
                        <div className="back" onClick={changeModule}>
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                size="2x"
                            />
                        </div>
                    </div>
                )}
                <SelectionBar
                    title="Date"
                    options={orderDates(dates)}
                    format={renderDate}
                    optionKey={(o) => o}
                    selectedOption={selectedDate ?? undefined}
                    onOptionSelected={handleDateSelected}
                    fallback="No meeting dates!"
                />

                <SelectionBar
                    title="Venue"
                    options={venues}
                    format={renderVenue}
                    optionKey={(o) => o.id}
                    selectedOption={selectedVenue ?? undefined}
                    onOptionSelected={handleVenueSelected}
                    fallback={"Please select the meeting date"}
                />

                <SelectionBar
                    title="TraCe Stream"
                    options={environments}
                    optionKey={(o) => o}
                    format={renderEnvironment}
                    selectedOption={selectedEnvironment ?? undefined}
                    onOptionSelected={handleEnvironmentSelected}
                    fallback={"Please select the venue"}
                />
            </section>
            <section className="graphics-test-button">
                <ComponentButton
                    title="Graphics Test"
                    componentType={Components.Watermark}
                    componentContext={{}}
                    visualContext={{
                        graphicsTest: true,
                        venkmanName,
                    }}
                />
            </section>
        </>
    );
}

function orderDates(dates: string[]) {
    return dates.slice().sort((a, b) => {
        return Date.parse(a) - Date.parse(b);
    });
}

function orderVenues(venues: Venue[]) {
    return venues.sort((a, b) => a.name.localeCompare(b.name));
}

const knownEnvironments: { [env: string]: number | undefined } = {
    Auto: 0,
    Main: 1,
    Backup: 2,
    Dev: 3,
};

// Always have Main < Backup < Dev. Then sort by alphabetical
function orderEnvironments(environments: string[]) {
    return environments.sort((a, b) => {
        const envA = knownEnvironments[a];
        const envB = knownEnvironments[b];

        if (envA === undefined && envB === undefined) {
            return a.localeCompare(b);
        } else if (envA === undefined) {
            return 1;
        } else if (envB === undefined) {
            return -1;
        } else {
            return envA - envB;
        }
    });
}

function renderDate(date: string) {
    const today = moment().startOf("d").format("YYYY-MM-DD");
    return (
        <div>
            {date === today && <FontAwesomeIcon icon={faCalendarAlt} />}
            {date}
        </div>
    );
}

function renderVenue(venue: Venue) {
    return (
        <div className={cn("meeting-selector-venue")}>
            <RaceTypeIcon raceType={venue.eventType} />
            {venue.name}
        </div>
    );
}

function renderEnvironment(env: string) {
    return <div>{env}</div>;
}

export default connector(withTruck(MeetingSelector));
