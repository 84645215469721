import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import memoize from "memoize-one";
import { useTruck } from "components/withTruck";
import { Key } from "ts-keycode-enum";
import {
    hideComponent,
    hideModuleDashboard,
    updateModuleDashboardData,
    showComponent,
    showModuleDashboard,
} from "components/ModulePage/ModulePage.Redux";
import { KeyBindings } from "components/KeyBindings/KeyBindings";
import {
    HorseType,
    RaceIdentity,
    MeetIdentity,
    IMeetVenuesByDate,
} from "../types";
import WaitingForData from "./WaitingForData";
import ShortcutKeyDialog from "./ShortcutKeyDialog";
import { IRouteToRaceParams, routeFactory } from "../Routes";
import MeetingSelector from "./RaceSelection/MeetingSelector";
import RaceSelector from "./RaceSelection/RaceSelector";
import {
    setSelectedMeet,
    setSelectedHorse,
    DashboardDataTypes,
} from "../redux/Module.Redux";

import "./styles.less";
import PreRaceControls from "./PreRace/PreRaceControls";
import PostRaceControls from "./PostRace/PostRaceControls";
import KillButton from "./KillButton";
import ShortcutKeys from "./ShortcutKeys";
import AtcHomestraightControls from "./AtcHomestraight/AtcHomestraightControls";
import ABAPricesControls from "./ABAPrices/ABAPricesControls";
import { useAppDispatch, useAppSelector } from "state/hooks";
import AtcWinningOwnerControls from "./AtcWinningOwner/AtcWinningOwnerControls";
import RedcliffeInternalScreens, {
    redcliffeInternalScreensComponents,
} from "./RedcliffeInternalScreens/RedcliffeInternalScreens";
import SingleImagePage from "./SingleImagePage";

const buildMeetIdentity = memoize(
    (
        date: string | undefined,
        state: string | undefined,
        eventType: string | undefined,
        venue: string | undefined,
    ): MeetIdentity | null => {
        return date && state && eventType && venue
            ? ({ date, state, eventType, venue } as MeetIdentity)
            : null;
    },
);

const buildRaceIdentity = memoize(
    (
        meetIdentity: MeetIdentity | null,
        raceNumber: number,
    ): RaceIdentity | null => {
        return meetIdentity && raceNumber
            ? { ...meetIdentity, raceNumber }
            : null;
    },
);

export default function RacingControls() {
    const truck = useTruck();

    const moduleState = useAppSelector((state) => state.moduleState);
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const params = useParams<IRouteToRaceParams>();
    const { date, state, eventType, venue, race } = params;
    const meetIdentity = buildMeetIdentity(date, state, eventType, venue);

    const raceIdentity =
        race && race !== "images"
            ? buildRaceIdentity(meetIdentity, parseInt(race, 10))
            : null;

    const [showHelp, setShowHelp] = useState(false);
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.shiftKey && event.code == "/") {
                setShowHelp(true);
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (truck.moduleId) {
            dispatch(showModuleDashboard(truck.moduleId));

            return () => {
                if (truck.moduleId) {
                    dispatch(hideModuleDashboard(truck.moduleId));
                }
            };
        }
    }, [dispatch, truck.moduleId]);

    useEffect(() => {
        // TODO only run once?
        if (meetIdentity) {
            // TODO dispatch
            dispatch(setSelectedMeet(meetIdentity));
        }
    }, [dispatch, meetIdentity]);

    const { meets } = moduleState;
    const lastMeets = useRef<IMeetVenuesByDate>();
    useEffect(() => {
        if (lastMeets.current === meets) {
            return;
        }

        lastMeets.current = meets;

        // Caters for a meet that was selected when the component was first loaded, but the meets weren't yet available.
        if (raceIdentity) {
            // TODO dispatch
            dispatch(
                updateModuleDashboardData(
                    DashboardDataTypes.SELECTED_RACE,
                    raceIdentity,
                ),
            );
        }
    }, [dispatch, meets, raceIdentity]);

    const setRace = useCallback(
        (raceNumber: number): void => {
            if (raceNumber.toString() === race) return;

            const pathname = routeFactory.hasRace({
                ...params,
                race: raceNumber.toString(),
            });
            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [navigate, params, race],
    );

    const handleRaceShortcut = useCallback(
        (keys: Key[]) => {
            if (
                !raceIdentity?.state ||
                !raceIdentity.date ||
                !raceIdentity.venue
            ) {
                return null;
            }

            const races =
                moduleState.meets[raceIdentity.date]?.[raceIdentity.venue]?.[
                    raceIdentity.state
                ]?.[raceIdentity.eventType] ?? [];
            const selectedRace = raceIdentity.raceNumber || -1;

            const key = keys[0];
            switch (key) {
                case Key.UpArrow:
                case Key.PlusSign:
                case Key.Add:
                    setRace(selectedRace <= 1 ? 1 : selectedRace - 1);
                    break;
                case Key.DownArrow:
                case Key.Subtract:
                    setRace(
                        selectedRace <= 0
                            ? 1
                            : Math.min(selectedRace + 1, races.length),
                    );
                    break;
                case Key.PageDown:
                    setRace(races.length);
                    break;
                case Key.PageUp:
                    setRace(1);
                    break;
                default: {
                    let val = 0;
                    for (let k of keys) {
                        val = val * 10;

                        if (k >= Key.Numpad0 && k <= Key.Numpad9) {
                            k = k - (Key.Numpad0 - Key.Zero);
                        } else if (!(k >= Key.Zero && k <= Key.Nine)) {
                            return;
                        }
                        val += k - Key.Zero;
                    }

                    if (val === 0 || val > races.length) {
                        return;
                    }
                    setRace(val);
                    break;
                }
            }
        },
        [
            moduleState.meets,
            raceIdentity?.date,
            raceIdentity?.eventType,
            raceIdentity?.raceNumber,
            raceIdentity?.state,
            raceIdentity?.venue,
            setRace,
        ],
    );

    const handleComponentShortcut = useCallback(
        (keys: Key[]) => {
            if (
                keys[0] !== undefined &&
                [Key.Escape, Key.Delete].includes(keys[0])
            ) {
                if (truck.truckId) {
                    dispatch(hideComponent(truck.truckId));
                }
            }
        },
        [dispatch, truck.truckId],
    );

    const handleHorseShortcut = useCallback(
        (keys: Key[]) => {
            const horses = moduleState.selectedRace?.runners;
            if (!horses) {
                return;
            }
            let selectedHorseIndex = -1;

            if (moduleState.selectedHorse) {
                selectedHorseIndex = horses.findIndex(
                    (h) => h.number === moduleState.selectedHorse?.number,
                );
            }

            let selectedHorse: HorseType | undefined = undefined;

            switch (keys[0]) {
                case Key.UpArrow:
                case Key.PlusSign:
                case Key.Add:
                    selectedHorse =
                        horses[
                            selectedHorseIndex <= 0 ? 0 : selectedHorseIndex - 1
                        ];
                    break;
                case Key.DownArrow:
                case Key.Subtract:
                    selectedHorse =
                        horses[
                            selectedHorseIndex < 0
                                ? 0
                                : Math.min(
                                      selectedHorseIndex + 1,
                                      horses.length - 1,
                                  )
                        ];
                    break;
                case Key.PageDown:
                    selectedHorse = horses[horses.length - 1];
                    break;
                case Key.PageUp:
                    selectedHorse = horses[0];
                    break;
                default: {
                    let val = 0;
                    for (let k of keys) {
                        val = val * 10;

                        if (k >= Key.Numpad0 && k <= Key.Numpad9) {
                            k = k - (Key.Numpad0 - Key.Zero);
                        } else if (!(k >= Key.Zero && k <= Key.Nine)) {
                            return;
                        }
                        val += k - Key.Zero;
                    }
                    if (val === 0 || val > horses.length) {
                        return;
                    }
                    selectedHorse =
                        horses[Math.min(val - 1, horses.length - 1)];
                    break;
                }
            }

            if (selectedHorse) {
                dispatch(setSelectedHorse(selectedHorse));
            }
        },
        [
            dispatch,
            moduleState.selectedHorse,
            moduleState.selectedRace?.runners,
        ],
    );

    if (truck.truck?.state === 3) {
        //Disconnected
        console.log("Truck Disconnected"); // TODO: Do something.. disable controls?
    }

    if (!(moduleState && moduleState.meets)) {
        return null;
    }

    return (
        <section className="racing-controls">
            <ShortcutKeyDialog
                show={showHelp}
                onHide={() => setShowHelp(false)}
            />

            <KeyBindings
                primary={Key.C}
                label="Components"
                raised={handleComponentShortcut}
            />
            {raceIdentity && (
                <KeyBindings
                    primary={Key.H}
                    label="Horse"
                    raised={handleHorseShortcut}
                />
            )}
            {meetIdentity && (
                <KeyBindings
                    primary={Key.R}
                    label="Race"
                    raised={handleRaceShortcut}
                />
            )}

            {!meetIdentity && <MeetingSelector {...moduleState} />}

            {truck?.moduleId === "RedcliffeInternalScreens" ? (
                <>
                    {meetIdentity && (
                        <RaceSelector
                            {...moduleState}
                            multiEnableConfig={{
                                components: redcliffeInternalScreensComponents,
                                visualContext: { autoScroll: true },
                            }}
                        />
                    )}
                </>
            ) : (
                <>{meetIdentity && <RaceSelector {...moduleState} />}</>
            )}

            {race === "images" ? (
                <SingleImagePage />
            ) : (
                <WaitingForData {...raceIdentity} moduleState={moduleState} />
            )}

            {raceIdentity &&
                truck.truckId &&
                truck?.moduleId === "RacingAustralia" &&
                moduleState.selectedRace && (
                    <ShortcutKeys
                        truckId={truck.truckId}
                        moduleId={truck.moduleId}
                        raceIdentity={raceIdentity}
                        selectedRace={moduleState.selectedRace}
                        selectedHorse={moduleState.selectedHorse ?? undefined}
                        activeComponents={truck.activeComponents}
                        setSelectedHorse={(h) => dispatch(setSelectedHorse(h))}
                        showComponent={(...args) =>
                            dispatch(showComponent(...args))
                        }
                        hideComponent={(...args) =>
                            dispatch(hideComponent(...args))
                        }
                    >
                        <section className="two-column-container">
                            <PreRaceControls raceIdentity={raceIdentity} />
                            <PostRaceControls raceIdentity={raceIdentity} />
                        </section>

                        <KillButton />
                    </ShortcutKeys>
                )}
            {raceIdentity &&
                truck.truckId &&
                truck?.moduleId === "AtcHomestraight" &&
                moduleState.selectedRace && (
                    <AtcHomestraightControls raceIdentity={raceIdentity} />
                )}
            {raceIdentity &&
                truck.truckId &&
                truck?.moduleId === "ATCBigScreen" &&
                moduleState.selectedABARace?.runners?.length && (
                    <ABAPricesControls raceIdentity={raceIdentity} />
                )}
            {raceIdentity &&
                truck.truckId &&
                truck?.moduleId === "AtcWinningOwner" &&
                moduleState.selectedRace && (
                    <AtcWinningOwnerControls raceIdentity={raceIdentity} />
                )}
            {raceIdentity &&
                truck.truckId &&
                truck?.moduleId === "RedcliffeInternalScreens" &&
                moduleState.selectedRace && (
                    <RedcliffeInternalScreens raceIdentity={raceIdentity} />
                )}
        </section>
    );
}
