import { Link, Route, Routes } from "react-router-dom";
import UserDetails from "./Users/UserDetails";
import UsersPage from "./Users/UsersPage";
import TenantsPage from "./Tenants/TenantsPage";
import TenantDetails from "./Tenants/TenantDetails";
import ChangePassword from "./Users/ChangePassword";
import repository from "./repository";
import { routeFactory as standardLayoutRouteFactory } from "components/StandardLayout/Routes";
import urlJoin from "url-join";
import FormButton from "components/Forms/FormButton";

export default function AdminRoutes() {
    return (
        <>
            <PageLinks />
            <Routes>
                <Route path="users/*">
                    <Route path="add" element={<UserDetails />} />
                    <Route
                        path=":userId/password"
                        element={<ChangePassword />}
                    />
                    <Route path=":userId" element={<UserDetails />} />
                    <Route path="" element={<UsersPage />} />
                </Route>
                <Route path="tenants/*">
                    <Route
                        path=":managedTenantId"
                        element={<TenantDetails />}
                    />
                    <Route path="" element={<TenantsPage />} />
                </Route>
                <Route path="" element={<TenantsPage />} />
            </Routes>
            <ClearTruckStateButton />
        </>
    );
}

export const routeFactory = {
    toAdminPage: (routeParams: { tenantId: string }): string =>
        standardLayoutRouteFactory.toAdminPage(routeParams),

    toTenantsPage: (routeParams: {
        tenantId: string;
        managedTenantId: string;
    }): string =>
        urlJoin(
            routeFactory.toAdminPage({ tenantId: routeParams.tenantId }),
            "tenants",
            routeParams.managedTenantId,
        ),

    toAddUser: (routeParams: { tenantId: string }): string =>
        urlJoin(
            routeFactory.toAdminPage({ tenantId: routeParams.tenantId }),
            "users/add",
        ),

    toUserChangePassword: (routeParams: {
        tenantId: string;
        userId: string;
    }): string =>
        urlJoin(
            routeFactory.toAdminPage({ tenantId: routeParams.tenantId }),
            "users",
            routeParams.userId,
            "password",
        ),
};

function PageLinks() {
    return (
        <div style={{ display: "flex" }}>
            <Link className="pure-menu-link" to="tenants">
                Tenants
            </Link>
            <Link className="pure-menu-link" to="users">
                Users
            </Link>
        </div>
    );
}

function ClearTruckStateButton() {
    return (
        <div>
            <br />
            <br />
            <br />
            <FormButton
                title="Clear cached truck state"
                onClick={async () => {
                    await repository.auditing.clearTruckState();
                }}
            />
        </div>
    );
}
