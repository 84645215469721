import { useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import withTruck, { WithTruckProps } from "../../withTruck/index";

import { useAppDispatch, useAppSelector } from "state/hooks";
import { onLogout } from "state/Auth";
import DropDownMenu from "../../Pure/DropDownMenu";
import {
    hideComponent,
    stopTruckSession,
} from "../../ModulePage/ModulePage.Redux";
import { routeFactory } from "../Routes";
import { getTruckById } from "state/selectors";

function Parent() {
    const name = useAppSelector(
        (state) => state.auth.displayName || state.auth.username,
    );
    return <div className="pure-menu-link user-menu">{name}</div>;
}

function Children(props: { truckId: string | undefined }) {
    const { truckId } = props;

    const params = useParams<{ tenantId: string }>();

    const { compositorCount, isAdmin, tenantId } = useAppSelector((state) => {
        const {
            auth: { isAdmin, currentTenant: tenantId },
            compositor,
        } = state;

        return {
            isAdmin,
            compositorCount: Object.keys(compositor).length,
            tenantId,
        };
    });

    const truck = useAppSelector((state) =>
        getTruckById(state, props.truckId ?? ""),
    );
    const hasTimingModules =
        Object.keys(truck?.modules ?? {}).findIndex(
            (m) =>
                m === "TripleS" ||
                m === "SkySTC" ||
                m === "SkyOne" ||
                m === "SkyHarness" ||
                m === "HRV" ||
                m === "QldHorseRacing" ||
                m === "QldHarnessRacing",
        ) != -1;

    const homePath = routeFactory.toHome();
    const compositorSelectorPath = routeFactory.toCompositorSelector({
        tenantId: params.tenantId ?? tenantId!,
    });
    const assetManagementPath = routeFactory.toAssetManagement({
        tenantId: params.tenantId ?? tenantId!,
    });
    const timingSponsorManagementPath = props.truckId
        ? routeFactory.toTimingSponsorManagement({
              tenantId: params.tenantId ?? tenantId!,
              truckId: props.truckId,
          })
        : ""; // If no truck id, this path isn't used
    const adminPath = routeFactory.toAdminPage({
        tenantId: params.tenantId ?? tenantId!,
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleKillTruck = useCallback(() => {
        if (truckId) {
            dispatch(stopTruckSession(truckId));
            navigate(compositorSelectorPath, { replace: true });
        }
    }, [dispatch, truckId, navigate, compositorSelectorPath]);

    const handleTruckHideAllComponents = useCallback(() => {
        if (truckId) {
            dispatch(hideComponent(truckId));
        }
    }, [dispatch, truckId]);

    const handleLogout = useCallback(() => {
        void dispatch(onLogout());
        navigate(homePath);
    }, [dispatch, navigate, homePath]);

    const handleOpenDocs = useCallback(() => {
        window.open("https://docs.vidlay.lightningvisuals.com")?.focus();
    }, []);

    return (
        <ul className="pure-menu-children">
            <li className="pure-menu-item">
                <Link className="pure-menu-link" to={compositorSelectorPath}>
                    Compositors ({compositorCount})
                </Link>
            </li>
            <li className="pure-menu-item">
                <Link className="pure-menu-link" to={assetManagementPath}>
                    Image management
                </Link>
            </li>
            {isAdmin && (
                <li className="pure-menu-item">
                    <Link className="pure-menu-link" to={adminPath}>
                        Admin
                    </Link>
                </li>
            )}
            <li className="pure-menu-item">
                <span className="pure-menu-link" onClick={handleOpenDocs}>
                    Help
                </span>
            </li>
            {props.truckId && (
                <>
                    <hr />
                    {hasTimingModules && (
                        <li className="pure-menu-item">
                            <Link
                                className="pure-menu-link"
                                to={timingSponsorManagementPath}
                            >
                                Sponsor management
                            </Link>
                        </li>
                    )}
                    <li className="pure-menu-item">
                        <div
                            className="pure-menu-link"
                            onClick={handleTruckHideAllComponents}
                        >
                            Hide Truck
                        </div>
                    </li>
                    <li className="pure-menu-item">
                        <div
                            className="pure-menu-link"
                            onClick={handleKillTruck}
                        >
                            Kill Truck
                        </div>
                    </li>
                    <hr />
                </>
            )}

            <li className="pure-menu-item">
                <span className="pure-menu-link" onClick={handleLogout}>
                    Logout
                </span>
            </li>
        </ul>
    );
}

function UserMenu(props: WithTruckProps) {
    return (
        <div className="pure-menu-horizontal text-right">
            <ul className="pure-menu-list text-left">
                <li>
                    <DropDownMenu
                        parent={() => <Parent />}
                        render={() => <Children truckId={props.truckId} />}
                        showOnHover={true}
                        hideOnBlur={true}
                    />
                </li>
            </ul>
        </div>
    );
}

export default withTruck(UserMenu);
