import Heartbeat from "../Heartbeat";
import { EventHeartbeat } from "../../redux/redux";
import { RaceWithNumber, RaceState } from "modules/triples/redux/redux";
import RaceDetails from "./RaceDetails";

import "./styles.less";

interface RaceTitleProps {
    environment: string;
    race: RaceWithNumber;
    raceState: RaceState;
    heartbeat: EventHeartbeat | undefined;
}

const RaceTitle = ({
    environment,
    race,
    raceState,
    heartbeat,
}: RaceTitleProps) => {
    if (!race) {
        return null;
    }

    return (
        <section className="race-title-bar">
            <RaceDetails
                environment={environment}
                race={race}
                raceState={raceState}
            />
            <Heartbeat heartbeat={heartbeat} />
        </section>
    );
};

export default RaceTitle;
