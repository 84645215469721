import React from "react";
import { connect } from "react-redux";
import { Components, RaceIdentity } from "../../types";
import { IGlobalState } from "state/store";
import { toggleCarnival } from "../../redux/Module.Redux";
import { useParams } from "react-router-dom";
import { useAppSelector } from "state/hooks";

import ComponentButton from "../ComponentButton/ComponentButton";
import RunningNumbers from "../PostRace/RunningNumbers/RunningNumbers";
import ResultsTable from "../HorseTables/ResultsTable";

interface IOwnProps {
    raceIdentity: RaceIdentity;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const VenueWinningOwnersButton = (props: {
    isCarnival: boolean;
    onToggleCarnival: (isCarnvial: boolean) => void;
    raceIdentity: RaceIdentity;
}) => {
    const visualContext = {
        isCarnival: props.isCarnival,
    };

    const options = [
        {
            title: "Carnival",
            isSelected: props.isCarnival,
            onSelected: props.onToggleCarnival,
        },
    ];

    return (
        <ComponentButton
            title="Venue Winners"
            componentType={Components.ff_venueWinningOwner}
            componentContext={{
                date: props.raceIdentity.date,
                venue: props.raceIdentity.venue,
                eventType: props.raceIdentity.eventType,
                state: props.raceIdentity.state,
            }}
            visualContext={visualContext}
            options={options}
            hideOthers
        />
    );
};

const GraphicsTestButton = () => {
    const compositors = useAppSelector((state) => state.compositor);
    const truckId = useParams<{ truckId: string }>().truckId;

    let venkmanName = "";
    if (truckId) {
        const compositor = Object.values(compositors).find((c) =>
            Object.keys(c.trucks).includes(truckId),
        );
        venkmanName = compositor?.name ?? "";
    }

    return (
        <ComponentButton
            title="Graphics Test Pattern"
            componentType={Components.lf_watermark}
            componentContext={{}}
            visualContext={{
                graphicsTest: true,
                venkmanName,
            }}
        />
    );
};

class PreRaceControls extends React.PureComponent<
    IOwnProps & StateProps & DispatchProps
> {
    public render() {
        const { race } = this.props;
        if (!race) {
            return null;
        }

        return (
            <section className="two-column-container">
                <section className="column">
                    <div className="column-header">
                        <RunningNumbers
                            raceIdentity={this.props.raceIdentity}
                        />
                    </div>

                    <div className="post-race-controls">
                        <div className="results-column">
                            <ResultsTable />
                        </div>

                        <div className="button-column">
                            <VenueWinningOwnersButton
                                isCarnival={this.props.isCarnival}
                                raceIdentity={this.props.raceIdentity}
                                onToggleCarnival={this.props.onToggleCarnival}
                            />
                            <br />
                            <GraphicsTestButton />
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps) => {
    const { selectedRace, isCarnival } = state.moduleState;

    return {
        ...ownProps,
        race: selectedRace,
        isCarnival: isCarnival,
    };
};

const mapDispatchToProps = {
    onToggleCarnival: toggleCarnival,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreRaceControls);
