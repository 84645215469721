import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import repository, { TenantModel } from "../repository";
import FormButton from "../../Forms/FormButton";
import BusySpinner from "../../BusySpinner";
import { useAppSelector } from "state/hooks";
import { routeFactory } from "../AdminRoutes";

interface UserModel {
    isAdmin?: boolean;
    id?: string;
    username?: string;
    displayName?: string;
    tenant?: string;
}

export default function UserDetails() {
    const { userId, tenantId: tenantIdFromParams } = useParams<{
        userId: string;
        tenantId: string;
    }>();

    const [originalModel, setOriginalModel] = useState<UserModel>({});
    const [tenants, setTenants] = useState<TenantModel[]>();

    const [username, setUsername] = useState("");
    const [tenant, setTenant] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [isBusy, setIsBusy] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const navigate = useNavigate();
    const tenantIdFromState = useAppSelector(
        (state) => state.auth.currentTenant,
    );

    useEffect(() => {
        void repository.tenants.getAll().then((tenants) => setTenants(tenants));

        if (!userId) {
            return;
        }

        void repository.users.get(userId).then((user) => {
            setOriginalModel({ ...user });
            setUsername(user.username);
            setTenant(user.tenant);
        });
    }, [userId]);

    const handleSave = useCallback(() => {
        if (!username) {
            setErrorMessage("Please enter a username");
            return;
        }

        if (!newPassword) {
            setErrorMessage("Please enter a password");
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage("The passwords must match");
            return;
        }

        const tenantsPath = routeFactory.toTenantsPage({
            tenantId: tenantIdFromParams ?? tenantIdFromState!,
            managedTenantId: tenant,
        });

        setErrorMessage(undefined);
        setIsBusy(true);

        void (async () => {
            try {
                await repository.users.create({
                    username,
                    tenant,
                    isAdmin: false,
                    password: newPassword,
                });

                navigate(tenantsPath);
            } catch (err) {
                if (err instanceof Error) {
                    setErrorMessage(err.toString());
                    setIsBusy(false);
                }
            }
        })();
    }, [
        confirmPassword,
        navigate,
        newPassword,
        tenant,
        username,
        tenantIdFromParams,
        tenantIdFromState,
    ]);

    if (!originalModel) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <div className="pure-form pure-form-stacked">
                <label htmlFor="username" style={{ marginTop: "1rem" }}>
                    Username
                </label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    disabled={!!originalModel?.username}
                    style={{
                        textTransform: "none",
                        width: "400px",
                        backgroundColor: "transparent",
                    }}
                    autoFocus={true}
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                />

                <label htmlFor="username" style={{ marginTop: "1rem" }}>
                    Tenant
                </label>
                <div>
                    <select
                        disabled={!!originalModel?.tenant}
                        style={{ background: "#323342", width: "400px" }}
                        value={tenant}
                        onChange={(event) => setTenant(event.target.value)}
                    >
                        {tenants?.map((t) => (
                            <option value={t.id} key={t.id}>
                                {t.name}
                            </option>
                        ))}
                    </select>
                </div>

                <label htmlFor="newpassword" style={{ marginTop: "1rem" }}>
                    New Password
                </label>
                <input
                    type="password"
                    id="newpassword"
                    name="newpassword"
                    style={{ textTransform: "none", width: "400px" }}
                    autoFocus={true}
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                />

                <label htmlFor="confirm" style={{ marginTop: "1rem" }}>
                    Confirm Password
                </label>
                <input
                    type="password"
                    id="confirm"
                    name="confirm"
                    style={{ textTransform: "none", width: "400px" }}
                    autoFocus={true}
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                />

                <BusySpinner isBusy={isBusy} />
                {errorMessage}
                <FormButton
                    title="Save"
                    onClick={handleSave}
                    disabled={isBusy}
                />
            </div>
        </div>
    );
}
