import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BusySpinner from "components/BusySpinner";
import FormButton from "components/Forms/FormButton";
import { useState, useCallback } from "react";
import Modal from ".";

interface ConfirmationModalProps {
    onConfirm: () => Promise<void>;
    modalText: string;
    modalTitle: string;
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
    const { onConfirm } = props;

    const [showModal, setShowModal] = useState(false);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [isBusy, setIsBusy] = useState(false);

    const onPressYes = useCallback(() => {
        setIsBusy(true);
        onConfirm()
            .then(() => {
                setShowModal(false);
                setIsBusy(false);
            })
            .catch((e) => {
                setSaveError(e.toString());
                setIsBusy(false);
            });
    }, [onConfirm]);

    const show = useCallback(() => setShowModal(true), []);
    const hide = useCallback(() => setShowModal(false), []);

    return (
        <>
            <FontAwesomeIcon
                icon={faTrashAlt}
                className="tenant-icon"
                onClick={show}
            />
            <Modal
                open={showModal}
                className="sponsor-logo-dialog"
                onClose={hide}
            >
                <BusySpinner isBusy={isBusy} />

                {saveError && (
                    <section className="error-banner">{saveError}</section>
                )}
                <div className="dialog-title-bar">{props.modalTitle}</div>

                <div className="dialog-content">{props.modalText}</div>

                <div className="dialog-action-bar">
                    <FormButton title="No" onClick={hide} />
                    <FormButton title="Yes" onClick={onPressYes} />
                </div>
            </Modal>
        </>
    );
}
