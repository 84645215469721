import { get, post, del } from "../../core/ajax";
import { IConnectionDetails } from "./Tenants/TenantDetails";

interface UserModel {
    isAdmin: boolean;
    id: string;
    username: string;
    displayName: string;
    tenant: string; // TODO: This should be tenants: string[]
}

interface TenantModel {
    id: string;
    name: string;
    modules: string[];
    venkmanLicences: VenkmanLicence[];
}

interface VenkmanLicence {
    value: string;
    hash: string;
}

export { UserModel, TenantModel, VenkmanLicence };

export default {
    users: {
        async getAll(): Promise<UserModel[]> {
            return get("/admin/users/all").then(
                (s) => s.json() as Promise<UserModel[]>,
            );
        },
        async get(userId: string): Promise<UserModel> {
            return get(`/admin/users/${userId}`).then(
                (s) => s.json() as Promise<UserModel>,
            );
        },
        async byTenant(tenantId: string): Promise<UserModel[]> {
            return get(`/admin/users/by-tenant/${tenantId}`).then(
                (s) => s.json() as Promise<UserModel[]>,
            );
        },
        async savePassword(
            userId: string,
            newPassword: string,
        ): Promise<{ success: boolean; message?: string }> {
            return post(`/admin/users/${userId}/password`, {
                new: newPassword,
            }).then(
                (s) =>
                    s.json() as Promise<{ success: boolean; message?: string }>,
            );
        },
        async create(user: {
            username: string;
            password: string;
            tenant: string;
            isAdmin: boolean;
        }): Promise<UserModel> {
            return post(`/admin/users`, user).then(
                (s) => s.json() as Promise<UserModel>,
            );
        },
        async delete(userId: string): Promise<boolean> {
            return del(`/admin/users/${userId}`).then((s) => s.ok);
        },
    },
    tenants: {
        async getAll(): Promise<TenantModel[]> {
            return get("/admin/tenants/all").then(
                (s) => s.json() as Promise<TenantModel[]>,
            );
        },
        async get(tenantId: string): Promise<TenantModel> {
            return get(`/admin/tenants/${tenantId}`).then(
                (s) => s.json() as Promise<TenantModel>,
            );
        },
        async removeCompositor(
            tenantId: string,
            compositorKey: string,
        ): Promise<boolean> {
            return del(
                `/admin/tenants/${tenantId}/compositor/${compositorKey}`,
            ).then((s) => s.ok);
        },
    },
    auditing: {
        async connections(): Promise<IConnectionDetails> {
            return get(`/admin/audit/connections`).then(
                (s) => s.json() as Promise<IConnectionDetails>,
            );
        },
        async clearTruckState(): Promise<boolean> {
            return del("/admin/audit/trucks/clearall").then((s) => s.ok);
        },
    },
};
