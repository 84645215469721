import Clock from "./Clock";
import UserMenu from "./UserMenu";

import "./Header.less";

import ConnectedCompositor from "./ConnectedCompositor";

export default function Index() {
    return (
        <header>
            <ConnectedCompositor />
            <Clock />
            <UserMenu />
        </header>
    );
}
