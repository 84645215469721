import { useState } from "react";

import ImageDropZone from "components/ImageDropZone";
import AssetUploadForm from "components/AssetManagement/AssetUploadForm";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";

interface Props {
    onFinish: (silkId: string) => void;
}

export default function SilkUploader(props: Props) {
    const [silkId, setSilkId] = useState<string>("");
    const [image, setImage] = useState<File | null>(null);

    async function handleUpload() {
        if (!(silkId && image)) {
            throw "You must enter a silk ID, and upload the silk image.";
        }

        await harmony.post("TripleS", `Silk?silkId=${silkId}`, image);

        props.onFinish(silkId);
    }

    return (
        <AssetUploadForm onSubmit={handleUpload}>
            <label htmlFor="id">Silk ID</label>
            <input
                name="id"
                type="text"
                value={silkId}
                onChange={(event) => setSilkId(event.target.value)}
            />

            <ImageDropZone
                title="Silk"
                height={200}
                image={image ?? undefined}
                onImageUploaded={(image) => setImage(image)}
            />
        </AssetUploadForm>
    );
}
