import { useCallback, useState } from "react";
import cn from "classnames";
import FormButton from "components/Forms/FormButton";
import Sortable from "components/Sortable";

type Props = {
    images: string[];
    onReorder: (images: string[]) => void;
    onReorderSave: () => void;
    onDelete: (image: string) => void;
};

export default function ExistingImageList(props: Props) {
    const [selectedImage, setSelectedImage] = useState<string>();

    const { images, onReorder } = props;

    const handleReorder = useCallback(
        (oldIndex: number, newIndex: number) => {
            console.log(oldIndex, newIndex);
            const newImages = [...images];
            const [removed] = newImages.splice(oldIndex, 1);
            if (removed !== undefined) {
                newImages.splice(newIndex, 0, removed);
            }

            onReorder(newImages);
        },
        [onReorder, images],
    );

    return (
        <section className="venue-images-existing">
            <div className="dialog-title-bar">Manage existing images</div>

            <div className="reorder">
                <Sortable onReorder={handleReorder}>
                    {props.images.map((image) => (
                        <div
                            key={image}
                            className={cn("image-row", {
                                selected: image === selectedImage,
                            })}
                            onClick={() => setSelectedImage(image)}
                        >
                            <img src={image} alt="Venue Image" />
                        </div>
                    ))}
                </Sortable>
            </div>
            <div className="dialog-action-bar">
                <FormButton title="Save" onClick={handleSave} />

                <FormButton
                    title="Delete"
                    className="danger"
                    confirmationToken={selectedImage}
                    disabled={!selectedImage}
                    onClick={handleDelete}
                />
            </div>
        </section>
    );

    ////////////////////

    function handleSave() {
        props.onReorderSave();
    }

    function handleDelete() {
        if (selectedImage) {
            props.onDelete(selectedImage);
        }
    }
}
