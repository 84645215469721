import SkySaddle1 from "./Sky/1.svg";
import SkySaddle2 from "./Sky/2.svg";
import SkySaddle3 from "./Sky/3.svg";
import SkySaddle4 from "./Sky/4.svg";
import SkySaddle5 from "./Sky/5.svg";
import SkySaddle6 from "./Sky/6.svg";
import SkySaddle7 from "./Sky/7.svg";
import SkySaddle8 from "./Sky/8.svg";
import SkySaddle9 from "./Sky/9.svg";
import SkySaddle10 from "./Sky/10.svg";
import SkySaddle11 from "./Sky/11.svg";
import SkySaddle12 from "./Sky/12.svg";
import SkySaddle13 from "./Sky/13.svg";

import HRVSaddle1 from "./HRV/1.svg";
import HRVSaddle2 from "./HRV/2.svg";
import HRVSaddle3 from "./HRV/3.svg";
import HRVSaddle4 from "./HRV/4.svg";
import HRVSaddle5 from "./HRV/5.svg";
import HRVSaddle6 from "./HRV/6.svg";
import HRVSaddle7 from "./HRV/7.svg";
import HRVSaddle8 from "./HRV/8.svg";
import HRVSaddle9 from "./HRV/9.svg";
import HRVSaddle10 from "./HRV/10.svg";
import HRVSaddle11 from "./HRV/11.svg";
import HRVSaddle12 from "./HRV/12.svg";
import HRVSaddle13 from "./HRV/13.svg";

const SquareCloths = {
    1: SkySaddle1,
    2: SkySaddle2,
    3: SkySaddle3,
    4: SkySaddle4,
    5: SkySaddle5,
    6: SkySaddle6,
    7: SkySaddle7,
    8: SkySaddle8,
    9: SkySaddle9,
    10: SkySaddle10,
    11: SkySaddle11,
    12: SkySaddle12,
    13: SkySaddle13,
};

const CircleCloths = {
    1: HRVSaddle1,
    2: HRVSaddle2,
    3: HRVSaddle3,
    4: HRVSaddle4,
    5: HRVSaddle5,
    6: HRVSaddle6,
    7: HRVSaddle7,
    8: HRVSaddle8,
    9: HRVSaddle9,
    10: HRVSaddle10,
    11: HRVSaddle11,
    12: HRVSaddle12,
    13: HRVSaddle13,
};

export default {
    SkySTC: SquareCloths,
    SkyOne: SquareCloths,
    HRV: CircleCloths,
    QldHarnessRacing: CircleCloths,
    SkyHarness: SquareCloths,
} as Record<string, Record<number, string>>;
