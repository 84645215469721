import { useEffect, useState } from "react";
import cn from "classnames";
import moment from "moment";
import { useCurrentTime } from "components/Time/TimeContext";

import { EventHeartbeat } from "../../redux/redux";

import "./Heartbeat.styles.less";
const PULSE_TIME_MILLIS = 1600;

interface IHeartbeatCircleProps {
    lastSeen?: string;
    errorTime?: number;
    warningTime?: number;
    tooltipTitle?: string;
}

function HeartbeatCircle(props: IHeartbeatCircleProps) {
    const [pulse, setPulse] = useState(false);
    const [className, setClassName] = useState("fatal");
    const now = useCurrentTime();

    const mome = moment.utc(props.lastSeen).subtract(1, "seconds");
    const tooltip = props.lastSeen
        ? `${props.tooltipTitle ?? ""}: ${mome
              .local()
              .format("LTS")} (${mome.from(moment(now))})`
        : "No heartbeat data";

    useEffect(() => {
        if (!props.lastSeen) {
            setClassName("fatal");
            return;
        }

        const mome = moment.utc(props.lastSeen);
        const millisSinceLastSeen = Date.now() - mome.unix() * 1000;

        let currentClass = "normal";
        let warningTimeout: ReturnType<typeof setTimeout> | null = null;
        let errorTimeout: ReturnType<typeof setTimeout> | null = null;

        if (props.errorTime && millisSinceLastSeen - props.errorTime >= 0) {
            currentClass = "error";
        } else if (
            props.warningTime &&
            millisSinceLastSeen - props.warningTime >= 0
        ) {
            currentClass = "warning";
            if (props.errorTime) {
                errorTimeout = setTimeout(() => {
                    setClassName("error");
                }, props.errorTime - millisSinceLastSeen);
            }
        } else {
            currentClass = "normal";
            if (props.warningTime) {
                warningTimeout = setTimeout(() => {
                    setClassName("warning");
                }, props.warningTime - millisSinceLastSeen);
            }
            if (props.errorTime) {
                errorTimeout = setTimeout(() => {
                    setClassName("error");
                }, props.errorTime - millisSinceLastSeen);
            }
        }
        setClassName(currentClass);

        return () => {
            if (warningTimeout) {
                clearTimeout(warningTimeout);
            }
            if (errorTimeout) {
                clearTimeout(errorTimeout);
            }
        };
    }, [props]);

    useEffect(() => {
        setPulse(true);
        const clearPulseTimeout = setTimeout(() => {
            setPulse(false);
        }, PULSE_TIME_MILLIS);

        return () => {
            if (clearPulseTimeout) {
                clearTimeout(clearPulseTimeout);
            }
        };
    }, [className]);

    const containerClassNames = cn("heartbeat-container", className);

    return (
        <div className={containerClassNames}>
            <div className="circle" /> {pulse && <div className="pulse" />}
            <div className="tooltip">{tooltip}</div>
        </div>
    );
}

interface IProps {
    heartbeat: EventHeartbeat | undefined;
}

const errorHeartbeats = (
    <div className="heartbeats">
        <HeartbeatCircle />
        <HeartbeatCircle />
    </div>
);

const Heartbeats = ({ heartbeat }: IProps): JSX.Element => {
    if (!heartbeat) {
        return errorHeartbeats;
    }

    return (
        <div className="heartbeats">
            <HeartbeatCircle
                lastSeen={heartbeat?.traceLastSeen}
                errorTime={60_000}
                warningTime={20_000}
                tooltipTitle="Trace last updated"
            />
            <HeartbeatCircle
                lastSeen={heartbeat?.clockLastSeen}
                errorTime={4_000}
                tooltipTitle="Clock last updated"
            />
        </div>
    );
};

export default Heartbeats;
