import * as harmonyUri from "../components/WebSocketConnection/harmonyUri";

import { STORED_JWT_TOKEN_KEY } from "state/Auth";

export function post(
    path: string,
    body?: Record<string, unknown>,
): Promise<Response> {
    const token = localStorage.getItem(STORED_JWT_TOKEN_KEY);

    const myInit: RequestInit = {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token ?? ""}`,
            "Content-Type": "application/json",
        },
    };

    if (body) {
        myInit.body = JSON.stringify(body);
    }

    return fetch(`${harmonyUri.getWebAPIRoot()}/grub${path}`, myInit).then(
        async (response) => {
            if (!response.ok) {
                throw await response.text();
            }
            return response;
        },
    );
}

export function del(path: string): Promise<Response> {
    const token = localStorage.getItem(STORED_JWT_TOKEN_KEY);

    const myInit: RequestInit = {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token ?? ""}`,
        },
    };

    return fetch(`${harmonyUri.getWebAPIRoot()}/grub${path}`, myInit);
}

export function get(path: string): Promise<Response> {
    const token = localStorage.getItem(STORED_JWT_TOKEN_KEY);

    const myInit: RequestInit = {
        mode: "cors",
        credentials: "include",
        headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token ?? ""}`,
        },
    };

    return fetch(`${harmonyUri.getWebAPIRoot()}/grub${path}`, myInit);
}
