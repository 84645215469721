import { useState } from "react";
import cn from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/Modal";

import { useAppSelector } from "../../../../state/hooks";
import { RunnerSilk, Horse, EventType } from "../../redux/redux";
import SilkViewer from "./SilkViewer";

import "./RunnersTable.styles.less";
import SilkSelector from "./SilkSelector";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";

import saddleCloths from "resources/saddle-cloths";
import ChicletSelector from "./ChicletSelector";

interface HorseTableInfo extends Horse {
    trainerName: string;
    jockeyName: string;
    silk: RunnerSilk;
    position: string | undefined;
    rank: number | undefined;
    runningState: string | undefined;
}

interface HorseTableRowProps extends HorseTableInfo {
    rowIndex: number;
    handleOnChangeSilk: (horseNumber: number, newSilkId: string) => void;
    handleOnRevertSilk: (horseNumber: number) => void;
    handleOnChangeChiclet: (
        horseNumber: number,
        newPrimaryColor: string,
        newSecondaryColor: string,
    ) => void;
    handleOnRevertChiclet: (horseNumber: number) => void;
    eventType: EventType;
    moduleId: string;
}

const RunnerTableRow = ({
    rowIndex,
    handleOnChangeSilk,
    handleOnRevertSilk,
    handleOnChangeChiclet,
    handleOnRevertChiclet,
    eventType,
    moduleId,
    ...horse
}: HorseTableRowProps): JSX.Element => {
    const rowClasses = cn({
        alt: rowIndex % 2 === 1,
        scratched: horse.state === "NR",
    });

    const [silkSelectorDialogOpen, setSilkSelectorDialogOpen] = useState(false);
    const [chicletSelectorDialogOpen, setChicletSelectorDialogOpen] =
        useState(false);

    const silk = horse.silk;
    const hasCustomSilk = "customId" in silk;

    const isCustomChiclet =
        "customChicletBaseColour" in horse.jockey ||
        "customChicletTextColour" in horse.jockey;
    const [primaryColor, secondaryColor] = isCustomChiclet
        ? [
              horse.jockey.customChicletBaseColour,
              horse.jockey.customChicletTextColour,
          ]
        : [horse.jockey.baseColour, horse.jockey.textColour];

    return (
        <tr className={rowClasses}>
            <td className="horse-number-cell">
                {eventType == "R" ? (
                    <>
                        <span
                            className="horse-chiclet"
                            style={{
                                backgroundColor: primaryColor,
                                color: secondaryColor,
                            }}
                            onClick={() => setChicletSelectorDialogOpen(true)}
                        >
                            {horse.number}
                        </span>
                        {isCustomChiclet && <span>&nbsp;(c)</span>}
                        <Modal
                            open={chicletSelectorDialogOpen}
                            onClose={() => {
                                setChicletSelectorDialogOpen(false);
                            }}
                            className="silk-modal"
                        >
                            <ChicletSelector
                                horseNumber={horse.number}
                                initialPrimaryColor={horse.jockey.baseColour}
                                initialSecondaryColor={horse.jockey.textColour}
                                customPrimaryColor={
                                    horse.jockey.customChicletBaseColour
                                }
                                customSecondaryColor={
                                    horse.jockey.customChicletTextColour
                                }
                                setSelectedChiclet={(
                                    newPrimaryColor,
                                    newSecondaryColor,
                                ) => {
                                    handleOnChangeChiclet(
                                        horse.number,
                                        newPrimaryColor,
                                        newSecondaryColor,
                                    );
                                    setChicletSelectorDialogOpen(false);
                                }}
                                revertChiclet={() => {
                                    handleOnRevertChiclet(horse.number);
                                    setChicletSelectorDialogOpen(false);
                                }}
                            />
                        </Modal>
                    </>
                ) : (
                    <img src={saddleCloths[moduleId]?.[horse.number]} />
                )}
            </td>
            <td>{hasCustomSilk ? silk.customId : silk.silkId}</td>
            <td className="silk-cell">
                {horse.silk && (
                    <SilkViewer
                        classNames={rowClasses}
                        id={silk.silkId}
                        url={silk.url}
                        isCustomSilk={false}
                    />
                )}
            </td>
            <td className="silk-cell">
                {hasCustomSilk && (
                    <SilkViewer
                        classNames={rowClasses}
                        id={silk.customId}
                        url={silk.customUrl}
                        isCustomSilk={true}
                    />
                )}
                <span
                    className="selector"
                    onClick={() => setSilkSelectorDialogOpen(true)}
                >
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                <Modal
                    open={silkSelectorDialogOpen}
                    onClose={() => {
                        setSilkSelectorDialogOpen(false);
                    }}
                    className="silk-modal"
                >
                    <SilkSelector
                        initialSilk={silk.silkId}
                        moduleId={moduleId}
                        selectedSilk={hasCustomSilk ? silk.customId : undefined}
                        selectedSilkUrl={
                            hasCustomSilk ? silk.customUrl : silk.url
                        }
                        setSelectedSilk={(newSilkId) => {
                            handleOnChangeSilk(horse.number, newSilkId);
                            setSilkSelectorDialogOpen(false);
                        }}
                        revertSilk={() => {
                            handleOnRevertSilk(horse.number);
                            setSilkSelectorDialogOpen(false);
                        }}
                    />
                </Modal>
            </td>
            <td>{horse.name}</td>
            <td>{horse.lane}</td>
            <td className="jockey-trainer">
                {horse.jockeyName}
                <br />
                {horse.trainerName}
            </td>
            <td>{horse.runningState}</td>
            <td>{horse.rank}</td>
            <td>{horse.position}</td>
        </tr>
    );
};

interface RunnersTableProps {
    moduleId: string;
    selectedDate: string;
    selectedEnvironment: string;
    selectedVenue: string;
    selectedRace: number;
}

export default function RunnersTable(props: RunnersTableProps) {
    const { selectedDate, selectedEnvironment, selectedVenue, selectedRace } =
        props;

    async function handleOnChangeSilk(horseNumber: number, newSilkId: string) {
        await harmony.post(props.moduleId, "ChangeCustomSilk", {
            selectedDate,
            selectedEnvironment,
            selectedVenue,
            selectedRace,
            horseNumber,
            newSilkId,
        });
    }

    async function handleOnRevertSilk(horseNumber: number) {
        await harmony.post(props.moduleId, "RevertCustomSilk", {
            selectedDate,
            selectedEnvironment,
            selectedVenue,
            selectedRace,
            horseNumber,
        });
    }

    async function handleOnChangeChiclet(
        horseNumber: number,
        newPrimaryColor: string,
        newSecondaryColor: string,
    ) {
        await harmony.post(props.moduleId, "ChangeCustomChiclet", {
            selectedDate,
            selectedEnvironment,
            selectedVenue,
            selectedRace,
            horseNumber,
            newPrimaryColor,
            newSecondaryColor,
        });
    }

    async function handleOnRevertChiclet(horseNumber: number) {
        await harmony.post(props.moduleId, "RevertCustomChiclet", {
            selectedDate,
            selectedEnvironment,
            selectedVenue,
            selectedRace,
            horseNumber,
        });
    }

    const raceDetails = useAppSelector((state) => {
        return state.tripleSModuleState.venues?.[selectedVenue]?.[
            selectedEnvironment
        ]?.races[selectedRace];
    });

    const eventType = useAppSelector((state) => {
        return state.tripleSModuleState.venues?.[selectedVenue]?.[
            selectedEnvironment
        ]?.venueType;
    });

    if (!raceDetails || !eventType) {
        return null;
    }

    const combinedTsdDataByHorse: { [horse: string]: HorseTableInfo } = {};
    Object.entries(raceDetails.horses).forEach(([number, horse]) => {
        combinedTsdDataByHorse[number] = {
            ...horse,
            jockeyName: horse.jockey.name,
            silk: horse.jockey.silk,
            trainerName: horse.trainer.name,
            position: raceDetails.positions?.[horse.number]?.position,
            rank: raceDetails.positions?.[horse.number]?.rank,
            runningState: raceDetails.positions?.[horse.number]?.state,
        };
    });

    return (
        <>
            {Object.keys(combinedTsdDataByHorse).length && (
                <table className="table triples-runners-table">
                    <thead>
                        <tr>
                            <th className="text-center">No.</th>
                            <th>Silk ID</th>
                            <th className="silk-cell">RISA Silk</th>
                            <th className="silk-cell">TSD Silk</th>
                            <th>Runner</th>
                            <th>Barrier</th>
                            <th>Jockey / Trainer</th>
                            <th>Status</th>
                            <th>Rank</th>
                            <th>Position</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(combinedTsdDataByHorse)
                            .sort((a, b) => a.number - b.number)
                            .map((horse, rowIndex) => (
                                <RunnerTableRow
                                    key={rowIndex}
                                    rowIndex={rowIndex}
                                    handleOnChangeSilk={handleOnChangeSilk}
                                    handleOnRevertSilk={handleOnRevertSilk}
                                    handleOnChangeChiclet={
                                        handleOnChangeChiclet
                                    }
                                    handleOnRevertChiclet={
                                        handleOnRevertChiclet
                                    }
                                    eventType={eventType}
                                    moduleId={props.moduleId}
                                    {...horse}
                                />
                            ))}
                    </tbody>
                </table>
            )}
        </>
    );
}
