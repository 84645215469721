import React, { useContext } from "react";
import { TimeContext } from "components/Time/TimeContext";

interface IProps {
    raceStartTime: string;
}

const zeroPad = (value: number): string =>
    value < 10 ? `0${value}` : value.toString();

const RaceCountdownTimer = ({ raceStartTime }: IProps) => {
    const { now } = useContext(TimeContext);
    const timeToRace = calculateTimeToRace();

    return <>{timeToRace}</>;

    ////////////////////

    function calculateTimeToRace() {
        const timeToRaceSeconds =
            (new Date(raceStartTime).valueOf() - now.valueOf()) / 1000;
        const sign = timeToRaceSeconds < 0 ? "-" : "";

        const absSeconds = Math.abs(timeToRaceSeconds);

        if (absSeconds > 3600) {
            const hours = Math.floor(absSeconds / 3600);
            const minutes = Math.floor(absSeconds / 60) - hours * 60;

            return minutes > 0
                ? `${sign}${hours}h ${minutes}m`
                : `${sign}${hours}h`;
        }

        if (absSeconds >= 600) {
            return `${sign}${Math.floor(absSeconds / 60)}m`;
        } else {
            const minutes = Math.floor(absSeconds / 60);
            const seconds = Math.floor(absSeconds % 60);

            return `${sign}${minutes}:${zeroPad(seconds)}`;
        }
    }
};

export default React.memo(RaceCountdownTimer);
