import cn from "classnames";
import { useDropzone } from "react-dropzone";

import calculateDropTargetStyle from "./calculateDropTargetWidthAndHeight";

interface IProps {
    height: number;
    imageRatio?: [number, number] | undefined;
    onImageUploaded: (file: File) => void;
}

export default function DropTarget({
    height,
    imageRatio,
    onImageUploaded,
}: IProps) {
    const { getRootProps, getInputProps, isDragAccept, isDragReject } =
        useDropzone({
            accept: {
                "image/jpeg": [".jpg", ".jpeg"],
                "image/png": [".png"],
                "video/webm": [".webm"],
            },
            maxFiles: 1,
            onDropAccepted: (acceptedFiles) => {
                if (acceptedFiles[0]) {
                    onImageUploaded(acceptedFiles[0]);
                }
            },
        });

    const targetClassNames = cn("target", {
        "target-active": isDragAccept,
        "target-reject": isDragReject,
    });

    const targetStyles = calculateDropTargetStyle(height, imageRatio);

    return (
        <div
            {...getRootProps({
                className: targetClassNames,
                style: targetStyles,
            })}
        >
            <input {...getInputProps()} />
            <div>{isDragAccept ? "Drop" : "Drag"} files here...</div>
            {isDragReject && <div>Unsupported file type...</div>}
        </div>
    );
}
