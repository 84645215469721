import React from "react";
import cn from "classnames";

interface IProps<TOption> {
    title: string;
    options: TOption[] | undefined;
    format: (option: TOption) => React.ReactNode;
    optionKey: (option: TOption) => string;
    fallback?: React.ReactNode;

    selectedOption: string | undefined;
    onOptionSelected: (option: TOption) => void;
}

export default function SelectionBar<TOption>(props: IProps<TOption>) {
    const { options, fallback } = props;
    const hasOptions = options?.length ?? 0 > 0;

    function handleOptionSelected(option: TOption) {
        props.onOptionSelected(option);
    }

    return (
        <div className="row">
            <div className="row-name option highlight">
                <div className="content center">{props.title}</div>
            </div>
            <div className="options">
                {options?.map((o) => (
                    <Option
                        key={props.optionKey(o)}
                        option={o}
                        format={props.format}
                        isSelected={props.selectedOption === props.optionKey(o)}
                        onSelected={handleOptionSelected}
                    />
                ))}
                {!hasOptions && <div className="fallback">{fallback}</div>}
            </div>
        </div>
    );
}

type OptionProps<TOption> = {
    option: TOption;
    format: (option: TOption) => React.ReactNode;
    isSelected: boolean;
    onSelected: (option: TOption) => void;
};

function Option<TOption>(props: OptionProps<TOption>) {
    const classNames = cn("selectable option", {
        selected: props.isSelected,
    });

    return (
        <div
            className={classNames}
            onClick={() => props.onSelected(props.option)}
        >
            <div className="content center">{props.format(props.option)}</div>
        </div>
    );
}
