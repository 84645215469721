import React from "react";
import { ComponentState } from "resources/harmonyTypes";
import withTruck, { WithTruckProps } from "../../withTruck";

import "./ConnectedTruckSubHeader.less";

class ConnectedTruckHeader extends React.Component<WithTruckProps> {
    public render(): JSX.Element {
        const { activeComponents } = this.props;

        const numVisibleComponents = activeComponents.filter(
            (component) => component.state == ComponentState.Visible,
        ).length;

        const numEnabledComponents =
            numVisibleComponents +
            activeComponents.filter(
                (component) => component.state == ComponentState.Hidden,
            ).length;

        const enabledComponentMessage =
            numEnabledComponents !== 1
                ? `${numEnabledComponents || "No"} enabled components`
                : `1 enabled component`;

        const visibleComponentMessage =
            numVisibleComponents > 0
                ? ` (${numVisibleComponents} visible)`
                : "";

        return (
            <section className="connected-truck-header">
                <div>{/* Menu goes here */}</div>
                <div className="live-components">
                    {enabledComponentMessage}
                    {visibleComponentMessage}
                </div>
                <div className="latency">
                    {this.props.truck?.latency}ms latency
                </div>
            </section>
        );
    }
}

export default withTruck(ConnectedTruckHeader);
