import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import useEffectAsync from "core/hooks/useEffectAsync";
import { ReactElement, useMemo, useState } from "react";
import { SilkImage } from "../AssetTypes/Silk/SilkAssetPage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/Modal";

import "./RunnersTable.styles.less";
import SilkUploader from "../AssetTypes/Silk/SilkUploader";

interface ISilkSelectorProps {
    initialSilk: string;
    moduleId: string;
    selectedSilk: string | undefined;
    selectedSilkUrl: string;
    setSelectedSilk: (silk: string) => void;
    revertSilk: () => void;
}

const SilkItem = (props: {
    name: string;
    icon: ReactElement;
    onSelect?: () => void;
    onRevert?: () => void;
}) => {
    return (
        <div className="asset-list-item">
            <div className="asset-icon">{props.icon}</div>
            <span className="asset-name">{props.name}</span>
            {props.onSelect && (
                <button className="select-button" onClick={props.onSelect}>
                    Select
                </button>
            )}
            {props.onRevert && (
                <button className="delete-button" onClick={props.onRevert}>
                    Revert
                </button>
            )}
        </div>
    );
};

const SilkSelector = (props: ISilkSelectorProps) => {
    const [prevInitialSilk, setPrevInitialSilk] = useState(props.initialSilk);
    const [searchText, setSearchText] = useState(props.initialSilk);
    if (prevInitialSilk != props.initialSilk) {
        setPrevInitialSilk(props.initialSilk);
        setSearchText(props.initialSilk);
    }

    const [silkList, setSilkList] = useState<SilkImage[]>([]);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const searchResults = useMemo(
        () =>
            searchText
                ? silkList.filter((silk) => silk.id.includes(searchText))
                : silkList,
        [silkList, searchText],
    );

    useEffectAsync(async () => {
        const silks = await harmony.get<SilkImage[]>(
            props.moduleId,
            "LoadCustomSilkList",
        );

        silks.sort((left, right) =>
            left.id
                .toLocaleLowerCase()
                .localeCompare(right.id.toLocaleLowerCase()),
        );
        setSilkList(silks);
    }, [props.selectedSilk]);

    const selectedSilk = props.selectedSilk;
    const selectedCustom =
        selectedSilk !== undefined &&
        silkList.find((silk) => silk.id === selectedSilk);

    return (
        <>
            <h2>Select a custom silk</h2>
            <div>
                <input
                    className="search-textbox"
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    placeholder="Enter a custom Silk ID"
                />
                <button
                    className="upload-button"
                    onClick={() => setUploadDialogOpen(true)}
                >
                    <FontAwesomeIcon icon={faPlus} /> Upload
                </button>
                <Modal
                    open={uploadDialogOpen}
                    onClose={() => setUploadDialogOpen(false)}
                    className="upload-dialog"
                >
                    <SilkUploader
                        onFinish={(silkId) => {
                            props.setSelectedSilk(silkId);
                            setUploadDialogOpen(false);
                        }}
                    />
                </Modal>
            </div>
            <br />
            <div>
                {selectedCustom ? (
                    <SilkItem
                        key={selectedSilk}
                        name={`${selectedSilk} (Current)`}
                        icon={<img src={props.selectedSilkUrl} />}
                        onRevert={props.revertSilk}
                    />
                ) : (
                    <SilkItem
                        key={props.initialSilk}
                        name={`${props.initialSilk} (Current)`}
                        icon={<img src={props.selectedSilkUrl} />}
                    />
                )}
            </div>
            <br />
            <div>
                {searchResults &&
                    searchResults.map((silk) =>
                        silk.id !== selectedSilk ? (
                            <SilkItem
                                key={silk.id}
                                name={silk.id}
                                icon={<img src={silk.url} />}
                                onSelect={() => {
                                    props.setSelectedSilk(silk.id);
                                }}
                            />
                        ) : null,
                    )}
            </div>
        </>
    );
};

export default SilkSelector;
