import { useCallback, useRef, useState } from "react";
import cn from "classnames";

interface IProps {
    className?: string;
    parent: (isShowingMenu: boolean) => React.ReactNode;
    render: (isShowingMenu: boolean) => React.ReactNode;
    showOnHover?: boolean;
    disabled?: boolean | undefined;
    hideOnBlur?: boolean;
}

export default function DropDownMenu(props: IProps) {
    const [isShowingMenu, setIsShowingMenu] = useState(false);
    const hideTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    const resetHideTimer = useCallback(() => {
        if (hideTimer.current) {
            clearTimeout(hideTimer.current);
            hideTimer.current = null;
        }
    }, []);

    const handleClick = useCallback(() => {
        if (props.disabled) {
            return;
        }

        setIsShowingMenu((isShowingMenu) => !isShowingMenu);
        resetHideTimer();
    }, [props.disabled, resetHideTimer]);

    const handleMouseEnter = useCallback(() => {
        if (props.showOnHover) {
            setIsShowingMenu(true);
            resetHideTimer();
        }
    }, [props.showOnHover, resetHideTimer]);

    const handleMouseLeave = useCallback(() => {
        if (!isShowingMenu || !props.hideOnBlur) {
            return;
        }

        hideTimer.current = setTimeout(() => {
            setIsShowingMenu(false);
            hideTimer.current = null;
            setIsShowingMenu(false);
        }, 500);
    }, [isShowingMenu, props.hideOnBlur]);

    const containerClassNames = cn(
        "pure-menu-item pure-menu-has-children",
        props.className,
        { "pure-menu-active": isShowingMenu },
    );

    return (
        <div
            className={containerClassNames}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={containerClassNames} onClick={handleClick}>
                {props.parent(isShowingMenu)}
            </div>

            {props.render(isShowingMenu)}
        </div>
    );
}
