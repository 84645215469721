import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// base styles need to imported before our components to ensure correct CSS
// cascade order
import "./resources/styles/index.less";

import App from "./App";
import store from "./state/store";

const el = document.getElementById("app");
if (el) {
    const root = createRoot(el);
    root.render(
        <StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </StrictMode>,
    );
}
