import { useState } from "react";

import useEffectAsync from "core/hooks/useEffectAsync";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import AssetList from "components/AssetManagement/AssetList";
import AssetListItem from "components/AssetManagement/AssetListItem";

import SilkUploader from "./SilkUploader";

export interface SilkImage {
    id: string;
    url: string;
}

export default function SilkAssetPage(props: { moduleId: string }) {
    const [silks, setSilks] = useState<SilkImage[]>([]);

    async function updateSilks() {
        const silks = await harmony.get<SilkImage[]>(
            props.moduleId,
            "LoadCustomSilkList",
        );
        // TODO does this order make sense?
        silks.sort((left, right) =>
            left.id
                .toLocaleLowerCase()
                .localeCompare(right.id.toLocaleLowerCase()),
        );
        setSilks(silks);
    }

    useEffectAsync(updateSilks);

    async function handleDelete(id: string) {
        await harmony.post(props.moduleId, "RemoveSilk", { id });
        setSilks(silks.filter((silk) => silk.id != id));
    }

    const assets = silks.map((silk) => {
        const img = <img src={silk.url} />;
        return (
            <AssetListItem
                key={silk.id}
                name={silk.id}
                icon={img}
                onDelete={() => handleDelete(silk.id)}
            />
        );
    });

    return (
        <AssetList uploadForm={SilkUploader} onUpload={updateSilks}>
            {assets}
        </AssetList>
    );
}
