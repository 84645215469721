import React, { useCallback } from "react";
import cn from "classnames";
import moment from "moment";

import { useNavigate, useParams } from "react-router-dom";
import SelectionBar from "components/SelectionBar";
import { IMeetVenuesByDate, MeetIdentity, RaceType } from "../../types";
import { preload } from "components/ModulePage/ModulePage.Redux";
import { IRouteToRaceParams, routeFactory } from "../../Routes";
import { setSelectedMeet } from "../../redux/Module.Redux";
import { useAppDispatch, useAppSelector } from "state/hooks";

import "./styles.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useTruck } from "components/withTruck";
import RaceTypeIcon from "./RaceTypeIcon";

interface IProps {
    meets: IMeetVenuesByDate;
    subscribedMeets: MeetIdentity[];
}

interface IVenue {
    code: string;
    raceType: RaceType;
}

export default function MeetingSelector(props: IProps) {
    const today = moment().startOf("d").format("YYYY-MM-DD");
    const numModules = useAppSelector((state) => state.availableModules.length);
    const { moduleId } = useTruck();
    const { meets } = props;

    const params = useParams<IRouteToRaceParams>();
    const { date, state, venue, eventType } = params;

    const raceDates = (meets && Object.keys(meets)) || null;
    const racesByVenue = (meets && date && meets[date]) || {};
    const racesByState = (racesByVenue && venue && racesByVenue[venue]) || {};

    let states = (date && venue && Object.keys(racesByState)) || [];
    if (date && venue && moduleId == "ATCBigScreen") {
        // ABAPrices only wants the jurisdiction the venue is in
        const racesByRaceType = Object.values(racesByState)?.[0];
        if (racesByRaceType) {
            const location = Object.values(racesByRaceType)?.[0]?.[0]?.location;
            if (location) states = [location];
        }
    }

    const venues = Object.keys(racesByVenue)
        .flatMap((tabCode) => {
            const raceTypesForState = racesByVenue[tabCode];
            if (!raceTypesForState) {
                return [];
            }

            // Assume we can get the raceType from the first Jurisdiction of that venue
            const racesForRaceType = Object.values(raceTypesForState)[0];
            if (!racesForRaceType) {
                return [];
            }

            return Object.keys(racesForRaceType).map((raceType) => ({
                code: tabCode,
                raceType: raceType as RaceType,
            }));
        })
        .filter((v: IVenue | null): v is IVenue => v !== null);

    const selectedVenue = venues.find(
        (v) => v.code === venue && v.raceType === eventType,
    );

    const renderDate = useCallback(
        (date: string): React.ReactNode => {
            const isSubscribedForDate = props.subscribedMeets.find(
                (meet) => meet.date === date,
            );
            const classNames = cn({ subscribed: isSubscribedForDate });

            return (
                <div className={classNames}>
                    {date === today && <FontAwesomeIcon icon={faCalendarAlt} />}
                    {date}
                </div>
            );
        },
        [props.subscribedMeets, today],
    );

    const renderState = useCallback(
        (state: string): React.ReactNode => {
            if (!selectedVenue) {
                return;
            }

            const isSubscribedForState = props.subscribedMeets.find(
                (meet) =>
                    meet.state === state &&
                    meet.venue === selectedVenue.code &&
                    meet.eventType == selectedVenue.raceType,
            );
            const classNames = cn({ subscribed: isSubscribedForState });

            return <div className={classNames}>{state}</div>;
        },
        [props.subscribedMeets, selectedVenue],
    );

    const renderVenue = useCallback(
        (venue: IVenue): React.ReactNode => {
            const isSubscribedForState = props.subscribedMeets.find(
                (meet) =>
                    meet.venue === venue.code &&
                    meet.eventType == venue.raceType,
            );
            const classNames = cn({ subscribed: isSubscribedForState });

            return (
                <div className={classNames}>
                    <RaceTypeIcon raceType={venue.raceType} />
                    {venue.code}
                </div>
            );
        },
        [props.subscribedMeets],
    );

    const navigate = useNavigate();
    const changeModule = useCallback(() => {
        const pathname = routeFactory.toModuleSelector(params);

        if (pathname) {
            navigate(pathname, { replace: true });
        }
    }, [params, navigate]);

    const updateVenueRouteParameter = useCallback(
        (value: IVenue): void => {
            const { code, raceType } = value;
            const pathname = routeFactory.hasMeetingVenue({
                ...params,
                eventType: raceType,
                venue: code,
            });

            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [navigate, params],
    );

    const updateRouteParameter = useCallback(
        (
                propertyName: "date" | "state",
                generateRoute: (
                    routeParams: IRouteToRaceParams,
                ) => string | undefined,
            ) =>
            (value: string): void => {
                const pathname = generateRoute({
                    ...params,
                    [propertyName]: value,
                });

                if (pathname) {
                    navigate(pathname, { replace: true });
                }
            },
        [navigate, params],
    );

    const dispatch = useAppDispatch();

    const handleStateSelected = useCallback(
        (state: string): void => {
            const { truckId, moduleId, date, venue, eventType } = params;

            if (truckId && moduleId && date && venue && eventType) {
                const meet: MeetIdentity = {
                    date,
                    state,
                    venue,
                    eventType,
                };
                dispatch(preload(truckId, moduleId, meet));
                dispatch(setSelectedMeet(meet));
            }
        },
        [dispatch, params],
    );

    const updateStateRouteParameter = useCallback(
        (value: string): void => {
            updateRouteParameter("state", routeFactory.hasMeetingState)(value);
            handleStateSelected(value);
        },
        [updateRouteParameter, handleStateSelected],
    );

    return (
        <section className="meeting-selector">
            {numModules > 1 && (
                <div className="row">
                    <div className="back" onClick={changeModule}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} size="2x" />
                    </div>
                </div>
            )}

            <SelectionBar
                title="Date"
                options={raceDates}
                format={renderDate}
                optionKey={(o) => o}
                selectedOption={date}
                onOptionSelected={updateRouteParameter(
                    "date",
                    routeFactory.hasMeetingDate,
                )}
            />

            <SelectionBar
                title="Venue"
                options={venues}
                format={renderVenue}
                optionKey={(o) => `${o.code}-${o.raceType}`}
                selectedOption={`${selectedVenue?.code ?? ""}-${
                    selectedVenue?.raceType ?? ""
                }`}
                onOptionSelected={updateVenueRouteParameter}
                fallback="Please select the meeting date"
            />

            <SelectionBar
                title="TAB Jurisdiction"
                options={states}
                format={renderState}
                optionKey={(o) => o}
                selectedOption={state}
                onOptionSelected={updateStateRouteParameter}
                fallback="Please select the meeting date and venue"
            />
        </section>
    );
}
