import React from "react";
import { connect } from "react-redux";
import memoize from "memoize-one";
import cn from "classnames";
import isEqual from "lodash/fp/isEqual";

import { IGlobalState } from "state/store";
import withTruck, {
    ActiveComponent,
    WithTruckProps,
} from "components/withTruck";
import {
    Components,
    HorseType,
    IPlacing,
    ManualPositionType,
    PlaceType,
    RaceIdentity,
} from "../../../types";

import { setManualPlacings } from "../../../redux/Module.Redux";
import { getPlacingsForSelectedRace } from "../../../redux/Module.Selectors";

import PlacingBox from "./PlacingBox";
import PlacingError from "./PlacingError";

import "./RunningNumbers.less";
import { showComponent } from "../../../../../components/ModulePage/ModulePage.Redux";
import { Dispatch } from "redux";

const RUNNING_NUMBER_COUNT = 4;

interface IOwnProps {
    raceIdentity: RaceIdentity;
}

type IStateProps = IOwnProps &
    WithTruckProps & {
        selectedHorse: HorseType | undefined;
        runningNumbers: ManualPositionType[];
        activeRunningNumbers: ManualPositionType[];
        isPastThePost: boolean;
        placingErrors: PlacingError[];
        dividendsStatus: string | undefined;
        dividendsPlaces: PlaceType[] | undefined;
        scratches: number[] | undefined;
        allRunners: number[] | undefined;
    };

interface IPropsFromDispatch {
    onChangeManualPlacings: (
        raceNumber: number,
        manualPositions: ManualPositionType[],
    ) => void;
    onUpdateRunningNumbers: (manualPositions: ManualPositionType[]) => void;
}

type IProps = IOwnProps & IStateProps & IPropsFromDispatch;

interface IState {
    editIndex: number | undefined;
}

class RunningNumbers extends React.PureComponent<IProps, IState> {
    public state: IState = {
        editIndex: undefined,
    };

    public render() {
        const {
            selectedHorse,
            runningNumbers,
            placingErrors,
            activeRunningNumbers,
            isPastThePost,
            dividendsStatus,
        } = this.props;
        const { editIndex } = this.state;
        const canUpdateRunningNumbers =
            !!activeRunningNumbers && !isPastThePost;

        const placingNumbers = new Map<ManualPositionType, number>();
        if (this.props.dividendsPlaces) {
            this.props.dividendsPlaces.forEach((place) => {
                placingNumbers.set(place.horseNumber, place.place - 1);
            });
        }

        return (
            <>
                {runningNumbers.map(
                    (
                        runningNumber: ManualPositionType,
                        index: number,
                    ): JSX.Element => (
                        <PlacingBox
                            key={index}
                            placingIndex={index}
                            placingNumber={placingNumbers.get(runningNumber)}
                            runningNumber={runningNumber}
                            selectedHorse={selectedHorse}
                            error={placingErrors[index]}
                            edit={index === editIndex}
                            dividendsStatus={
                                dividendsStatus as "interim" | "final"
                            }
                            onBeginEdit={this.handlePlacingBeginEdit}
                            onEndEdit={this.handlePlacingEndEdit}
                            onChange={this.handlePlacingsChanged}
                        />
                    ),
                )}

                <button
                    className={cn(
                        "pure-button pure-component-button update-running-numbers",
                        { visible: canUpdateRunningNumbers },
                    )}
                    onClick={this.handleUpdateRunningNumbers}
                >
                    Update
                </button>
            </>
        );
    }

    public componentDidMount() {
        const { runningNumbers, activeRunningNumbers } = this.props;
        if (!activeRunningNumbers) return;

        let areRunningNumbersOutOfSync = false;
        const runningNumbersSynced = runningNumbers.map(
            (runningNumber, index) => {
                const activeRunningNumber = activeRunningNumbers[index];

                if (!runningNumber && activeRunningNumber) {
                    areRunningNumbersOutOfSync = true;
                    return activeRunningNumber;
                }

                return runningNumber;
            },
        );

        if (areRunningNumbersOutOfSync) {
            this.props.onChangeManualPlacings(
                this.props.raceIdentity.raceNumber,
                runningNumbersSynced,
            );
        }
    }

    public componentDidUpdate(lastProps: IProps) {
        const { activeRunningNumbers } = this.props;
        const { activeRunningNumbers: lastActiveRunningNumbers } = lastProps;

        if (activeRunningNumbers && !lastActiveRunningNumbers) {
            this.setState({ editIndex: 0 });
        }
    }

    private handlePlacingsChanged = (
        placingIndex: number,
        runnerNumber: ManualPositionType,
    ) => {
        if (
            // This horse is scratched...
            this.props.scratches?.find(
                (scratched) => scratched === runnerNumber,
            ) &&
            // ...but allow the number if it's the start of a larger number (e.g. 1 has scratched but there's a 10 that isn't).
            !this.props.allRunners?.find((runner) =>
                runner.toString().startsWith(runnerNumber.toString()),
            )
        ) {
            return false;
        }

        const manualPositions = [...this.props.runningNumbers];
        manualPositions[placingIndex] = runnerNumber;

        this.props.onChangeManualPlacings(
            this.props.raceIdentity.raceNumber,
            manualPositions,
        );
        return true;
    };

    private handlePlacingBeginEdit = (placingIndex: number): void => {
        this.setState({ editIndex: placingIndex });
    };

    private handlePlacingEndEdit = (
        placingIndex: number,
        moveToNextPlacing: boolean,
    ): void => {
        this.setState((prevState: IState): IState | undefined => {
            const { editIndex: lastEditIndex } = prevState;
            if (lastEditIndex !== placingIndex) return undefined;

            const editIndex = lastEditIndex + 1;
            return editIndex < 4 && moveToNextPlacing
                ? { editIndex }
                : { editIndex: undefined };
        });
    };

    private handleUpdateRunningNumbers = () => {
        this.setState({ editIndex: 0 });
        this.props.onUpdateRunningNumbers(this.props.runningNumbers);
    };
}

////////////////////

const getPlacingErrors = memoize(
    (
        runningNumbers: ManualPositionType[],
        runners: HorseType[],
    ): PlacingError[] => {
        const placedRunners = new Set<number>();

        return runningNumbers.map((runningNumber) => {
            if (typeof runningNumber !== "number") return PlacingError.none;
            if (placedRunners.has(runningNumber)) return PlacingError.duplicate;

            const runner = runners.find((r) => r.number === runningNumber);
            if (!runner) return PlacingError.invalid;
            if (runner.isScratched) return PlacingError.scratched;

            placedRunners.add(runningNumber);
            return PlacingError.none;
        });
    },
);

const getRunningNumbers = memoize(
    (placings: IPlacing[]): ManualPositionType[] => {
        const runningNumbers = placings.map((placing) => {
            if (placing.runner) return placing.runner.number;
            if (placing.photo) return "P";
            return "";
        });

        while (runningNumbers.length < RUNNING_NUMBER_COUNT) {
            runningNumbers.push("");
        }

        return runningNumbers;
    },
);

const getComponent = (
    raceIdentity: RaceIdentity,
    componentToFind: Components,
    activeComponents: ActiveComponent[],
): ActiveComponent | undefined => {
    const equalsRaceIdentity = isEqual(raceIdentity);

    return activeComponents.find((component) => {
        if (component.componentId !== componentToFind) {
            return;
        }

        const { componentContext } = component;
        return equalsRaceIdentity(componentContext);
    });
};

const mapStateToProps = (
    state: IGlobalState,
    props: IOwnProps & WithTruckProps,
): IStateProps => {
    const rnComponent = getComponent(
        props.raceIdentity,
        Components.lf_runningNumbers,
        props.activeComponents,
    );
    let activeRunningNumbers = rnComponent?.visualContext
        ?.manualPositions as ManualPositionType[];
    let isPastThePost = false;

    if (!activeRunningNumbers) {
        const ptpComponent = getComponent(
            props.raceIdentity,
            Components.lf_pastThePost,
            props.activeComponents,
        );
        activeRunningNumbers = ptpComponent?.visualContext
            ?.manualPositions as ManualPositionType[];
        isPastThePost = !!activeRunningNumbers;
    }

    const placings = getPlacingsForSelectedRace(state);
    const runningNumbers = getRunningNumbers(placings);

    const placingErrors = runningNumbers
        ? getPlacingErrors(
              runningNumbers,
              state.moduleState.selectedRace?.runners ?? [],
          )
        : [];

    const { dividends, selectedRace } = state.moduleState;
    const dividendsStatus =
        dividends && dividends.places
            ? dividends.isInterim
                ? "interim"
                : "final"
            : null;

    const scratches =
        selectedRace &&
        selectedRace.runners
            .filter((runner) => runner.isScratched)
            .map((runner) => runner.number);

    const allRunners =
        selectedRace &&
        selectedRace.runners
            .filter((runner) => !runner.isScratched)
            .map((runner) => runner.number);

    return {
        ...props,
        selectedHorse: state.moduleState.selectedHorse ?? undefined,
        runningNumbers,
        activeRunningNumbers,
        isPastThePost,
        placingErrors,
        dividendsStatus: dividendsStatus ?? undefined,
        dividendsPlaces: dividends?.places,
        scratches: scratches ?? undefined,
        allRunners: allRunners ?? undefined,
    };
};

const buildPropsFromDispatch: (
    dispatch: Dispatch,
    truckId: string | undefined,
    moduleTypeId: string | undefined,
    raceIdentity: RaceIdentity,
    componentInstanceId: string | undefined,
) => IPropsFromDispatch = memoize(
    (
        dispatch,
        truckId: string | undefined,
        moduleTypeId: string | undefined,
        raceIdentity: RaceIdentity,
        componentInstanceId: string | undefined,
    ): IPropsFromDispatch => {
        return {
            onChangeManualPlacings: (
                raceNumber: number,
                manualPositions: ManualPositionType[],
            ) => dispatch(setManualPlacings(raceNumber, manualPositions)),

            onUpdateRunningNumbers: (manualPositions: ManualPositionType[]) =>
                truckId &&
                moduleTypeId &&
                showComponent(
                    truckId,
                    moduleTypeId,
                    Components.lf_runningNumbers,
                    { ...raceIdentity },
                    { manualPositions },
                    componentInstanceId,
                )(dispatch),
        };
    },
);

const mapDispatchToProps = (
    dispatch: Dispatch,
    props: IOwnProps & WithTruckProps,
): IPropsFromDispatch => {
    const { truckId, moduleId, raceIdentity, activeComponents } = props;

    const component = getComponent(
        raceIdentity,
        Components.lf_runningNumbers,
        activeComponents,
    );
    return buildPropsFromDispatch(
        dispatch,
        truckId,
        moduleId,
        raceIdentity,
        component?.componentInstanceId,
    );
};

export default withTruck(
    connect(mapStateToProps, mapDispatchToProps)(RunningNumbers),
);
