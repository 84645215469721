import { useCallback, useEffect, useState } from "react";
import FormButton from "../../Forms/FormButton";
import repository, { UserModel } from "../repository";
import { useParams, useNavigate } from "react-router-dom";
import BusySpinner from "../../BusySpinner";
import { useAppSelector } from "state/hooks";
import { routeFactory } from "../AdminRoutes";

export default function ChangePassword() {
    const { tenantId: currentUserTenantId, userId } = useParams<{
        tenantId: string;
        userId: string;
    }>();
    const [user, setUser] = useState<UserModel | null>(null);

    useEffect(() => {
        if (!userId) {
            return;
        }

        void repository.users.get(userId).then((user) => setUser(user));
    }, [userId]);

    const [isBusy, setIsBusy] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [errorMsg, setErrorMsg] = useState<string>();

    const navigate = useNavigate();
    const tenantIdFromState = useAppSelector(
        (state) => state.auth.currentTenant,
    );

    const handleSave = useCallback(() => {
        if (!newPassword) {
            setErrorMsg("Please enter a new password");
            return;
        }

        if (newPassword !== newPasswordConfirm) {
            setErrorMsg("The confirmation password does not match");
            return;
        }

        // We shouldn't ever end up here, since we don't render the component
        // at all if there's no user
        if (!user) {
            return;
        }

        const tenantsPath = routeFactory.toTenantsPage({
            tenantId: currentUserTenantId ?? tenantIdFromState!,
            managedTenantId: user.tenant,
        });

        setIsBusy(true);
        void repository.users
            .savePassword(user.id, newPassword)
            .then((result) => {
                if (!result.success) {
                    setErrorMsg(result.message);
                    setIsBusy(false);
                } else {
                    navigate(tenantsPath);
                }
            });
    }, [
        newPassword,
        newPasswordConfirm,
        user,
        navigate,
        currentUserTenantId,
        tenantIdFromState,
    ]);

    if (!user) {
        return null;
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <div className="pure-form pure-form-stacked">
                <h2>Change Password - {user.username}</h2>
                <BusySpinner isBusy={isBusy} />
                <label htmlFor="newpassword" style={{ marginTop: "1rem" }}>
                    New Password
                </label>
                <input
                    type="password"
                    id="newpassword"
                    name="newpassword"
                    style={{ textTransform: "none", width: "400px" }}
                    autoFocus={true}
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                />
                <label htmlFor="confirm" style={{ marginTop: "1rem" }}>
                    Confirm Password
                </label>
                <input
                    type="password"
                    id="confirm"
                    name="confirm"
                    style={{ textTransform: "none", width: "400px" }}
                    autoFocus={true}
                    value={newPasswordConfirm}
                    onChange={(event) =>
                        setNewPasswordConfirm(event.target.value)
                    }
                />
                <div style={{ color: "#f66" }}>{errorMsg}</div>
                chan
                <FormButton
                    title="Save"
                    onClick={() => handleSave()}
                    disabled={isBusy}
                />
            </div>
        </div>
    );
}
