import { useState } from "react";

interface IChicletSelectorProps {
    horseNumber: number;
    initialPrimaryColor: string;
    initialSecondaryColor: string;
    customPrimaryColor?: string | undefined;
    customSecondaryColor?: string | undefined;
    setSelectedChiclet: (
        newPrimaryColor: string,
        newSecondaryColor: string,
    ) => void;
    revertChiclet: () => void;
}

const DEFAULT_PRIMARY = "#000000";
const DEFAULT_SECONDARY = "#ffffff";

export default function ChicletSelector(props: IChicletSelectorProps) {
    const [hasCustomChiclet, setHasCustomChiclet] = useState(
        !!props.customPrimaryColor || !!props.customSecondaryColor,
    );
    const [currentCustomPrimaryColor, setCurrentCustomPrimaryColor] = useState(
        props.customPrimaryColor ?? DEFAULT_PRIMARY,
    );
    const [currentCustomSecondaryColor, setCurrentCustomSecondaryColor] =
        useState(props.customSecondaryColor ?? DEFAULT_SECONDARY);

    return (
        <>
            <h2>Select custom chiclet colours</h2>
            <div>
                <div className="asset-list-item">
                    <div className="asset-icon">
                        <span
                            className="horse-chiclet"
                            style={{
                                backgroundColor: props.initialPrimaryColor,
                                color: props.initialSecondaryColor,
                            }}
                        >
                            {props.horseNumber}
                        </span>
                    </div>
                    <span className="asset-name">Initial chiclet</span>
                </div>
                <div className="asset-list-item">
                    {hasCustomChiclet && (
                        <>
                            <div className="asset-icon">
                                <span
                                    className="horse-chiclet"
                                    style={{
                                        backgroundColor:
                                            currentCustomPrimaryColor,
                                        color: currentCustomSecondaryColor,
                                    }}
                                >
                                    {props.horseNumber}
                                </span>
                            </div>
                            <span className="asset-name">Custom chiclet</span>
                            {!!props.customPrimaryColor && (
                                <button
                                    className="delete-button"
                                    onClick={() => {
                                        setCurrentCustomPrimaryColor(
                                            DEFAULT_PRIMARY,
                                        );
                                        setCurrentCustomSecondaryColor(
                                            DEFAULT_SECONDARY,
                                        );
                                        setHasCustomChiclet(false);
                                        props.revertChiclet();
                                    }}
                                >
                                    Revert
                                </button>
                            )}
                        </>
                    )}
                </div>
                <label
                    className="chiclet-color-selector-label"
                    htmlFor="primary"
                >
                    Background colour:
                </label>
                <input
                    type="color"
                    id="primary"
                    value={currentCustomPrimaryColor}
                    onChange={(e) => {
                        setHasCustomChiclet(true);
                        setCurrentCustomPrimaryColor(e.target.value);
                    }}
                />
                <br />
                <label
                    className="chiclet-color-selector-label"
                    htmlFor="secondary"
                >
                    Foreground colour:
                </label>
                <input
                    type="color"
                    id="secondary"
                    value={currentCustomSecondaryColor}
                    onChange={(e) => {
                        setHasCustomChiclet(true);
                        setCurrentCustomSecondaryColor(e.target.value);
                    }}
                />
                <br />
                <br />
                <button
                    className="select-button"
                    onClick={() => {
                        if (
                            currentCustomPrimaryColor == DEFAULT_PRIMARY &&
                            currentCustomSecondaryColor == DEFAULT_SECONDARY
                        ) {
                            return;
                        }
                        props.setSelectedChiclet(
                            currentCustomPrimaryColor,
                            currentCustomSecondaryColor,
                        );
                    }}
                >
                    Save
                </button>
            </div>
        </>
    );
}
