export enum ModelState {
    Unknown = 0,
    Connecting = 1,
    Connected = 2,
    Disconnected = 3,
}

export enum ComponentState {
    Showing = "Showing",
    Visible = "Visible",
    Hiding = "Hiding",
    Hidden = "Hidden",
}

interface ComponentData {
    state: ComponentState;
    // moduleContext: any;
    componentContext: { [name: string]: unknown };
    visualContext: { [name: string]: unknown }; //From Truck
}
/// NOTE: These models are mirrored in Grub. Make sure any changes here are reflected there and vice versa

interface ModuleData {
    moduleId: string;
    enabled: boolean;
    added: Date;

    components: {
        [componentId: string]: { [componentKey: string]: ComponentData };
    };
}

interface TruckDetails {
    state: ModelState;
    latency?: number;
    modules: {
        [moduleId: string]: ModuleData;
    };
}

interface CompositorTruckDetails extends TruckDetails {
    compositorId: string;
}

interface CompositorDetails {
    compositorKey: string;
    name: string;
    description: string;
    canReset?: boolean;
    streamUrlDash: string;
    state: "available" | "unavailable" | "in-use";
    venkman: {
        id: string;
        description: string;
        name: string;
    };
    trucks: { [sessionKey: string]: TruckDetails };
}

interface CompositorMessageType {
    [compositorKey: string]: CompositorDetails;
}

export { ModuleData as ModuleDetails, ComponentData };
export {
    CompositorDetails,
    CompositorMessageType,
    TruckDetails,
    CompositorTruckDetails,
};
