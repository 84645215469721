import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "state/hooks";
import { routeFactory } from "../AdminRoutes";
import repository, { TenantModel } from "../repository";

export default function TenantsPage() {
    const [tenants, setTenants] = useState<TenantModel[]>();

    useEffect(() => {
        void repository.tenants.getAll().then((tenants) => {
            setTenants(tenants);
        });
    }, []);

    if (!tenants) {
        return null;
    }

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <td>Tenant</td>
                        <td>Modules</td>
                        <td>Venkmann</td>
                    </tr>
                </thead>
                <tbody>
                    {tenants.map((u) => (
                        <TableRow key={u.id} tenant={u} />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function TableRow(props: { tenant: TenantModel }) {
    const navigate = useNavigate();
    const { tenant } = props;

    const tenantIdFromParams = useParams<{ tenantId: string }>().tenantId;
    const tenantIdFromState = useAppSelector(
        (state) => state.auth.currentTenant,
    );
    const tenantsPath = routeFactory.toTenantsPage({
        tenantId: tenantIdFromParams ?? tenantIdFromState!,
        managedTenantId: tenant.id,
    });

    return (
        <tr key={tenant.id} onClick={() => navigate(tenantsPath)}>
            <td>{tenant.name}</td>
            <td>{tenant.modules.join(", ")}</td>
            <td>
                {tenant.venkmanLicences.length} licence
                {tenant.venkmanLicences.length === 1 ? "" : "s"}
            </td>
        </tr>
    );
}
