import { Route, Routes } from "react-router-dom";
import urlJoin from "url-join";
import {
    StandardLayoutRouteParams,
    routeFactory as parentRoutes,
} from "components/StandardLayout/Routes";
import TimingPage from "./components/TimingPage";

export type IRouteToRaceParams = StandardLayoutRouteParams & {
    date?: string;
    state?: string;
    environment?: string;
    venue?: string;
    race?: string;
};

export default function TripleSRoutes() {
    // TODO come up with a better way of handling these
    return (
        <Routes>
            <Route path="" element={<TimingPage />} />
            <Route path=":date" element={<TimingPage />} />
            <Route path=":date/:venue" element={<TimingPage />} />
            <Route path=":date/:venue/:environment" element={<TimingPage />} />
            <Route
                path=":date/:venue/:environment/:race"
                element={<TimingPage />}
            />
        </Routes>
    );
}

export const routeFactory = {
    toModuleSelector: (routeParams: IRouteToRaceParams): string | undefined => {
        const { tenantId, truckId } = routeParams;
        if (tenantId && truckId) {
            return parentRoutes.toModuleSelector({ tenantId, truckId });
        }
    },

    hasMeetingDate: (routeParams: IRouteToRaceParams): string | undefined => {
        const { date, tenantId, truckId, moduleId } = routeParams;
        if (date && tenantId && truckId && moduleId) {
            const parent = parentRoutes.toModule({
                tenantId,
                truckId,
                moduleId,
            });
            return urlJoin(parent, date);
        }
    },
    hasMeetingVenue: (routeParams: IRouteToRaceParams): string | undefined => {
        const dateUrl = routeFactory.hasMeetingDate(routeParams);
        return (
            dateUrl && routeParams.venue && urlJoin(dateUrl, routeParams.venue)
        );
    },
    hasEnvironment: (routeParams: IRouteToRaceParams): string | undefined => {
        const venueUrl = routeFactory.hasMeetingVenue(routeParams);
        return (
            venueUrl &&
            routeParams.environment &&
            urlJoin(venueUrl, routeParams.environment)
        );
    },
    hasRace: (routeParams: IRouteToRaceParams): string | undefined => {
        const environmentUrl = routeFactory.hasEnvironment(routeParams);
        return (
            environmentUrl &&
            routeParams.race &&
            urlJoin(environmentUrl, routeParams.race)
        );
    },
};
