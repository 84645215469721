import { createContext, useContext, useEffect, useMemo, useRef } from "react";

import HarmonyClient, { HarmonyMessageHandlers } from "./websocket";

type HarmonyConfig = {
    url: string;
    tenant?: string | undefined;
    token?: string | undefined;
    handlers: HarmonyMessageHandlers;
};

export const HarmonyContext = createContext<HarmonyClient | null>(null);

export function useHarmonyClient(config: HarmonyConfig) {
    const client = useRef<HarmonyClient | null>(null);

    const handlersRef = useRef<HarmonyMessageHandlers>(config.handlers);
    const handlers = useMemo<HarmonyMessageHandlers>(
        () => ({
            onStartConnecting: () => {
                handlersRef.current.onStartConnecting();
            },
            onConnected: () => {
                handlersRef.current.onConnected();
            },
            onDisconnected: () => {
                handlersRef.current.onDisconnected();
            },
            updateCompositors: (compositors) => {
                handlersRef.current.updateCompositors(compositors);
            },
            updateAvailableModules: (modules) => {
                handlersRef.current.updateAvailableModules(modules);
            },
            updateModuleDashboardState: (state) => {
                handlersRef.current.updateModuleDashboardState(state);
            },
        }),
        [],
    );

    useEffect(() => {
        if (client.current) {
            client.current.disconnect();
            client.current = null;
        }

        client.current = new HarmonyClient(
            handlers,
            config.url,
            config.tenant,
            config.token,
        );

        return () => {
            if (client.current) {
                client.current.disconnect();
                client.current = null;
            }
        };
    }, [handlers, config.tenant, config.url, config.token]);

    return client.current;
}

export function useHarmony() {
    const harmony = useContext(HarmonyContext);
    if (harmony == null) {
        throw new Error("Harmony connection not found");
    }
    return harmony;
}
