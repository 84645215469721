import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import repository, {
    TenantModel,
    UserModel,
    VenkmanLicence,
} from "../repository";
import FormButton from "../../Forms/FormButton";
import "./TenantDetails.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlug, faKey } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { useAppSelector } from "state/hooks";
import { routeFactory } from "../AdminRoutes";

export interface IGrubConnectionDetails {
    username: string;
    sessionId: string;
    tenantId: string;
    remoteAddress: string;
}

export interface ICompositorDetails {
    compositorKey: string;
    name: string;
    description: string;
    canReset?: boolean;
}

export interface IVenkmanDetails {
    name: string;
    sessionId: string;
    tenantId: string;
    remoteAddress: string;
    licenceHash: string;
    compositors: ICompositorDetails[];
}

export interface ITruckDetails {
    compositorKey: string;
    state: string;
    remoteAddress: string;
}

export interface IConnectionDetails {
    grub: IGrubConnectionDetails[];
    venkman: IVenkmanDetails[];
    trucks: ITruckDetails[];
}

export default function TenantDetails() {
    const { tenantId, managedTenantId } = useParams<{
        tenantId: string;
        managedTenantId: string;
    }>();

    const [tenant, setTenant] = useState<TenantModel>();
    const [users, setUsers] = useState<UserModel[]>();
    const [connections, setConnections] = useState<IConnectionDetails>();

    const navigate = useNavigate();

    const tenantIdFromState = useAppSelector(
        (state) => state.auth.currentTenant,
    );
    const addUserPath = routeFactory.toAddUser({
        tenantId: tenantId ?? tenantIdFromState!,
    });

    useEffect(() => {
        if (!managedTenantId) {
            return;
        }

        void repository.tenants
            .get(managedTenantId)
            .then((tenant) => setTenant(tenant));
        void repository.users
            .byTenant(managedTenantId)
            .then((users) => setUsers(users));
        void repository.auditing
            .connections()
            .then((connections) => setConnections(connections));
    }, [managedTenantId]);

    if (!tenant || !users || !connections) {
        return null;
    }

    return (
        <div>
            <div>Name: {tenant.name}</div>
            <div>Modules Available: {tenant.modules.join(", ")}</div>
            <br />
            <div>Venkman Licences</div>
            {
                <Licences
                    licences={tenant.venkmanLicences}
                    venkmans={connections.venkman}
                    trucks={connections.trucks}
                />
            }
            <br />
            <div>Users</div>
            {<Users users={users} grubs={connections.grub} />}
            <FormButton title="Add New" onClick={() => navigate(addUserPath)} />
        </div>
    );
}

function Licences(props: {
    licences: VenkmanLicence[];
    venkmans: IVenkmanDetails[];
    trucks: ITruckDetails[];
}) {
    return (
        <table className="table">
            <thead>
                <tr>
                    <td>Licence</td>
                    <td>Active Connections</td>
                </tr>
            </thead>
            <tbody>
                {props.licences.map((venkman) => {
                    const connections = props.venkmans.filter(
                        (g) => g.licenceHash === venkman.hash,
                    );
                    const nRows = connections?.length;
                    return connections?.map((connection, i) => {
                        return (
                            <tr key={`${venkman.hash}-${i}`}>
                                {i == 0 && (
                                    <td rowSpan={nRows}>{venkman.value}</td>
                                )}
                                <td key={`${venkman.hash}-${i}`}>
                                    <Connection
                                        connection={connection}
                                        trucks={props.trucks}
                                    />
                                </td>
                            </tr>
                        );
                    });
                })}
            </tbody>
        </table>
    );
}

function Connection(props: {
    connection: IVenkmanDetails;
    trucks: ITruckDetails[];
}) {
    const { connection } = props;
    return (
        <div key={connection.sessionId} className="connection">
            <div key={connection.remoteAddress}>
                {connection.name} ({connection.remoteAddress})
            </div>
            {connection.compositors.length == 0 ? (
                <div key={"compositor-none"}>
                    &nbsp;⤷&nbsp;<b>No compositors</b>
                </div>
            ) : (
                connection.compositors.map((compositor) => (
                    <div key={compositor.compositorKey}>
                        &nbsp;⤷&nbsp;
                        {compositor.compositorKey}&nbsp;&nbsp;
                        <ConfirmationModal
                            modalTitle={"Remove Compositor"}
                            modalText={`Are you sure you want to remove
                                            compositor "${compositor.compositorKey}" for tenant "${connection.tenantId}"?
                                            All connected Trucks and Venkmen with this compositor will stop working!`}
                            onConfirm={async () => {
                                await repository.tenants.removeCompositor(
                                    connection.tenantId,
                                    compositor.compositorKey,
                                );
                            }}
                        />
                        {props.trucks
                            .filter(
                                (t) =>
                                    t.compositorKey == compositor.compositorKey,
                            )
                            .map((truck, i) => (
                                <div key={`${truck.compositorKey}-truck${i}`}>
                                    &nbsp;&nbsp;⤷&nbsp; Truck {truck.state} (
                                    {truck.remoteAddress})
                                </div>
                            ))}
                    </div>
                ))
            )}
        </div>
    );
}

function Users(props: { users: UserModel[]; grubs: IGrubConnectionDetails[] }) {
    const navigate = useNavigate();
    const tenantIdFromParams = useParams<{ tenantId: string }>().tenantId;
    const tenantIdFromState = useAppSelector(
        (state) => state.auth.currentTenant,
    );

    return (
        <table className="table">
            <thead>
                <tr>
                    <td>Username</td>
                    <td>Active Connections</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {props.users.map((p) => {
                    const connections = props.grubs.filter(
                        (g) => g.username === p.username,
                    );
                    return (
                        <tr key={p.username}>
                            <td>{p.username}</td>
                            <td>
                                {connections?.map((p) => (
                                    <div key={p.remoteAddress}>
                                        {p.remoteAddress}
                                        <FontAwesomeIcon
                                            icon={faPlug}
                                            className="tenant-icon"
                                        />
                                    </div>
                                ))}
                            </td>
                            <td style={{ display: "flex" }}>
                                <FontAwesomeIcon
                                    icon={faKey}
                                    className="tenant-icon"
                                    onClick={() =>
                                        navigate(
                                            routeFactory.toUserChangePassword({
                                                tenantId:
                                                    tenantIdFromParams ??
                                                    tenantIdFromState!,
                                                userId: p.id,
                                            }),
                                        )
                                    }
                                />
                                <ConfirmationModal
                                    modalTitle={"Delete User"}
                                    modalText={`Are you sure you want to delete user ${p.username}?`}
                                    onConfirm={async () => {
                                        await repository.users.delete(p.id);
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
