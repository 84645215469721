import { ComponentType, useCallback, useState } from "react";
import cn from "classnames";

import "./AssetManagementPage.less";

import { useAppDispatch, useAppSelector } from "state/hooks";
import SponsorLogoAssetPage from "modules/atc/components/AssetTypes/SponsorLogo/SponsorLogoAssetPage";
import SilkAssetPage from "modules/triples/components/AssetTypes/Silk/SilkAssetPage";
import SponsorAssetPage from "modules/triples/components/AssetTypes/Sponsor/SponsorAssetPage";
import TenantDropDownMenu from "components/Compositors/TenantDropDownMenu";
import { useNavigate, useParams } from "react-router-dom";
import { routeFactory } from "components/StandardLayout/Routes";
import { onSwitchTenant } from "state/Auth";

interface AssetType {
    name: string;
    component: ComponentType;
}

interface ModuleAssets {
    [moduleName: string]: AssetType[];
}

const assetTypes: ModuleAssets = {
    RacingAustralia: [
        {
            name: "Sponsor",
            component: () => (
                <SponsorLogoAssetPage moduleId="RacingAustralia" />
            ),
        },
    ],
    TripleS: [
        {
            name: "Silk",
            component: () => <SilkAssetPage moduleId="TripleS" />,
        },
        {
            name: "Sponsor",
            component: () => <SponsorAssetPage moduleId="TripleS" />,
        },
    ],
    SkySTC: [
        {
            name: "Silk",
            component: () => <SilkAssetPage moduleId="SkySTC" />,
        },
        {
            name: "Sponsor",
            component: () => <SponsorAssetPage moduleId="SkySTC" />,
        },
    ],
    SkyOne: [
        {
            name: "Silk",
            component: () => <SilkAssetPage moduleId="SkyOne" />,
        },
        {
            name: "Sponsor",
            component: () => <SponsorAssetPage moduleId="SkyOne" />,
        },
    ],
    HRV: [
        {
            name: "Silk",
            component: () => <SilkAssetPage moduleId="HRV" />,
        },
        {
            name: "Sponsor",
            component: () => <SponsorAssetPage moduleId="HRV" />,
        },
    ],
    QldHarnessRacing: [
        {
            name: "Silk",
            component: () => <SilkAssetPage moduleId="QldHarnessRacing" />,
        },
        {
            name: "Sponsor",
            component: () => <SponsorAssetPage moduleId="QldHarnessRacing" />,
        },
    ],
    QldHorseRacing: [
        {
            name: "Silk",
            component: () => <SilkAssetPage moduleId="QldHorseRacing" />,
        },
        {
            name: "Sponsor",
            component: () => <SponsorAssetPage moduleId="QldHorseRacing" />,
        },
    ],
    MRC: [
        {
            name: "Silk",
            component: () => <SilkAssetPage moduleId="MRC" />,
        },
    ],
};

export default function AssetManagementPage() {
    const [activeAssetType, setActiveAssetType] = useState<AssetType | null>(
        null,
    );

    const { activeModules, tenantIds } = useAppSelector((state) => {
        const activeModules = [...state.availableModules].sort((a, b) =>
            a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
        );

        const tenantIds = state.auth.tenants ?? [];

        return { activeModules, tenantIds };
    });

    const buttons = activeModules.map((module) => {
        const moduleAssetTypes = assetTypes[module];
        if (!moduleAssetTypes) return [];
        return (
            <div className="module" key={module}>
                <span className="title" key={`${module}.title`}>
                    {module}
                </span>
                {moduleAssetTypes.map((assetType, index) => {
                    const className = cn({
                        active: activeAssetType === assetType,
                    });
                    return (
                        <a
                            className={className}
                            key={`${module}.${index}`}
                            onClick={() => setActiveAssetType(assetType)}
                        >
                            {assetType.name}
                        </a>
                    );
                })}
            </div>
        );
    });

    const params = useParams<{ tenantId: string }>();
    const tenantId = params.tenantId;

    const AssetPage = activeAssetType?.component;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleSwitchTenant = useCallback(
        (t: string) => {
            const pathname = routeFactory.toAssetManagement({
                tenantId: t,
            });
            navigate(pathname, { replace: true });
            void dispatch(onSwitchTenant(t));
            setActiveAssetType(null);
        },
        [dispatch, navigate],
    );

    return (
        <>
            <h1>Image management</h1>
            <div className="asset-management-page">
                <div>
                    Tenant:
                    <TenantDropDownMenu
                        currentTenant={tenantId}
                        tenants={tenantIds}
                        onTenantSwitch={handleSwitchTenant}
                    />
                </div>
                <div className="asset-type-column">{buttons}</div>
                <div className="asset-manager">
                    {AssetPage ? (
                        <AssetPage />
                    ) : (
                        <span>Select an asset type</span>
                    )}
                </div>
            </div>
        </>
    );
}
