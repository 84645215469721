import React from "react";
import { connect } from "react-redux";
import { hideComponent } from "../../../components/ModulePage/ModulePage.Redux";
import withTruck, { WithTruckProps } from "../../../components/withTruck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dispatch } from "redux";

interface IKillButtonProps {
    hideComponent: React.MouseEventHandler<HTMLButtonElement>;
}

const KillButton = ({ hideComponent }: IKillButtonProps): JSX.Element => {
    return (
        <button className="pure-button kill-button" onClick={hideComponent}>
            <FontAwesomeIcon icon={faTrash} size="2x" />
        </button>
    );
};

const mapDispatchToProps = (
    dispatch: Dispatch,
    props: WithTruckProps,
): IKillButtonProps => {
    const { truckId } = props;

    return {
        hideComponent: () => truckId && hideComponent(truckId)(dispatch),
    } as IKillButtonProps;
};

export default withTruck(connect(null, mapDispatchToProps)(KillButton));
