import hrv from "./hrv.png";
import sky from "./sky.png";
import mrc from "./mrc.png";
import racing_qld from "./racing_qld.png";
import atc from "./atc.png";
import magic_millions from "./MM_logo.png";

export default {
    HRV: hrv,
    Sky: sky,
    MRC: mrc,
    RQLD: racing_qld,
    ATC: atc,
    MagicMillions: magic_millions,
};
