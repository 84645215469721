import FormButton from "components/Forms/FormButton";
import { routeFactory } from "components/StandardLayout/Routes";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.less";

export default function PageNotFound() {
    const navigate = useNavigate();

    const handleRedirect = useCallback(() => {
        const homePath = routeFactory.toHome();
        navigate(homePath);
    }, [navigate]);

    return (
        <div className="page-not-found">
            <div>
                Page {window.location.href} was not found! Redirect to home?
            </div>
            <br />
            <FormButton title="Home" onClick={handleRedirect} />
        </div>
    );
}
