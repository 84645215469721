import React from "react";
import moment from "moment";
import cn from "classnames";
import isEqual from "lodash/fp/isEqual";

import { Heartbeat } from "../../types";

const PULSE_TIME_MILLIS = 1600;

interface IPriceHeartbeatComponentStatus {
    description: string[];
    classNames: string;
}

const NO_HEARTBEAT: IPriceHeartbeatComponentStatus = {
    classNames: "",
    description: ["No heartbeat data"],
};

const getStalePriceThresholdSeconds = (timeToStartSec: number): number => {
    const timeToStartMin = timeToStartSec / 60;

    if (timeToStartMin <= 1) return 4;
    if (timeToStartMin <= 5) return 20;
    if (timeToStartMin <= 10) return 60;
    if (timeToStartMin <= 30) return 120;
    if (timeToStartMin <= 60) return 240;

    return 660;
};

const calculateAcceptanceClassNames = (
    heartbeat: Heartbeat,
): IPriceHeartbeatComponentStatus => {
    if (!heartbeat) return NO_HEARTBEAT;

    const classNames = cn({
        normal: heartbeat.acceptanceLevel === "Final Fields",
        warning:
            heartbeat.acceptanceLevel &&
            heartbeat.acceptanceLevel !== "Final Fields",
        error: !heartbeat.acceptanceLevel,
    });

    const description = heartbeat.acceptanceLevel
        ? `RISA level: ${heartbeat.acceptanceLevel}`
        : "RISA data missing";

    return { classNames, description: [description] };
};

const calculatePollingClassNames = (
    heartbeat: Heartbeat,
): IPriceHeartbeatComponentStatus => {
    if (!heartbeat) return NO_HEARTBEAT;

    const now = new Date().valueOf();
    const nextUpdateAt =
        (heartbeat.nextPriceUpdateAt &&
            new Date(heartbeat.nextPriceUpdateAt).valueOf()) ||
        0;
    const hasCompleted = heartbeat.pricesPollStatus === "Complete";
    const isOverdue = !hasCompleted && nextUpdateAt <= now - 10000;
    const isLongOverdue = isOverdue && (now - nextUpdateAt) / 1000 > 120;

    const classNames = cn({
        normal:
            hasCompleted ||
            (heartbeat.pricesPollStatus === "Polling" && !isOverdue),
        warning:
            heartbeat.pricesPollStatus === "Expired" ||
            (isOverdue && !isLongOverdue),
        error: heartbeat.pricesPollStatus === "Cancelled",
        fatal: !heartbeat.pricesPollStatus || isLongOverdue,
    });

    const description = isLongOverdue
        ? "Polling has stalled."
        : isOverdue
        ? "Polling: Update is overdue."
        : `Polling status: ${heartbeat.pricesPollStatus}`;

    return {
        classNames,
        description: [description],
    };
};

const calculatePriceClassNames = (
    heartbeat: Heartbeat,
): IPriceHeartbeatComponentStatus => {
    if (!heartbeat) return NO_HEARTBEAT;

    const now = new Date().valueOf();
    const startTime =
        (heartbeat.startTime && new Date(heartbeat.startTime).valueOf()) || 0;
    const generatedAt =
        (heartbeat.pricesGeneratedAt &&
            new Date(heartbeat.pricesGeneratedAt).valueOf()) ||
        0;
    const priceAgeSec = Math.ceil((now - generatedAt) / 1000);

    const description = [
        `Prices correct at: ${moment(heartbeat.pricesGeneratedAt).format(
            "hh:mm:ss A",
        )}`,
    ];

    const nextPriceUpdateAt = moment(heartbeat.nextPriceUpdateAt);
    if (nextPriceUpdateAt.isValid()) {
        description.push(
            `Next update due:   ${nextPriceUpdateAt.format("hh:mm:ss A")}`,
        );
    }

    if (heartbeat.status === "Paying") {
        const dividendsWaiting = !heartbeat.hasDividends;
        const dividendsOverdue = dividendsWaiting && priceAgeSec > 60;

        const classNames = cn({
            normal: heartbeat.hasDividends,
            warning: dividendsWaiting && !dividendsOverdue,
            error: dividendsOverdue,
        });

        return { classNames, description };
    }

    if (heartbeat.status === "Interim") {
        const isFresh = priceAgeSec <= 10;
        const isStale = priceAgeSec <= 60 && !isFresh;
        const isOverdue = priceAgeSec > 60;

        const classNames = cn({
            normal: isFresh,
            warning: isStale,
            error: isOverdue,
        });

        return { classNames, description };
    }

    if (heartbeat.status === "Closed" && startTime && startTime < now) {
        const classNames = cn({
            normal: priceAgeSec <= 90,
            warning: priceAgeSec > 90 && priceAgeSec <= 180,
            error: priceAgeSec > 180,
        });

        return { classNames, description };
    }

    const timeToStartSec = Math.ceil((startTime - now) / 1000);
    const threshold = getStalePriceThresholdSeconds(timeToStartSec);

    const classNames = cn({
        normal: priceAgeSec <= threshold,
        warning: priceAgeSec > threshold && priceAgeSec <= threshold * 2,
        error: priceAgeSec > threshold * 2,
    });

    return { classNames, description };
};

const calculateStatuses = (heartbeat: Heartbeat) => {
    return {
        acceptanceStatus: calculateAcceptanceClassNames(heartbeat),
        pollingStatus: calculatePollingClassNames(heartbeat),
        priceStatus: calculatePriceClassNames(heartbeat),
    };
};

class HeartbeatCircle extends React.PureComponent<
    { status: IPriceHeartbeatComponentStatus },
    { lastStatus: IPriceHeartbeatComponentStatus; pulse: boolean }
> {
    private clearPulseTimeout: number | null = null;
    public state = {
        lastStatus: NO_HEARTBEAT,
        pulse: false,
    };

    public render() {
        const { description, classNames } = this.props.status || NO_HEARTBEAT;
        const { pulse } = this.state;

        const containerClassNames = cn("heartbeat-container", classNames);

        return (
            <div className={containerClassNames}>
                {pulse && <div className="pulse" />}
                <div className="circle" />
                <div className="tooltip">
                    {description.map((line, i) => (
                        <p key={i}>{line}</p>
                    ))}
                </div>
            </div>
        );
    }

    public componentDidUpdate(
        prevProps: Readonly<{ status: IPriceHeartbeatComponentStatus }>,
    ): void {
        if (this.state.pulse) {
            return;
        }

        const { status } = this.props;
        const { status: prevStatus } = prevProps;

        const pulse =
            status.classNames !== prevStatus.classNames ||
            !isEqual(status.description, prevStatus.description);
        if (pulse) {
            this.setState({
                lastStatus: this.props.status,
                pulse: true,
            });

            this.clearPulseTimeout = window.setTimeout(() => {
                this.clearPulseTimeout = null;
                this.setState({ pulse: false });
            }, PULSE_TIME_MILLIS);
        }
    }

    public componentWillUnmount(): void {
        if (this.clearPulseTimeout) {
            clearTimeout(this.clearPulseTimeout);
        }
    }
}

interface IProps {
    heartbeat: Heartbeat;
}

const Heartbeats = ({ heartbeat }: IProps): JSX.Element => {
    const status = calculateStatuses(heartbeat);

    return (
        <div className="heartbeats">
            <HeartbeatCircle status={status.acceptanceStatus} />
            <HeartbeatCircle status={status.pollingStatus} />
            <HeartbeatCircle status={status.priceStatus} />
        </div>
    );
};

export default Heartbeats;
