import dashjs from "dashjs";
import { useEffect, useRef, useState } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./DashPlayer.css";

export type DashPlayerProps = {
    src: string;
    showUrl?: boolean;
};

export default function DashPlayer(props: DashPlayerProps) {
    const playerRef = useRef(null);
    const [latency, setLatency] = useState<number | undefined>();

    useEffect(() => {
        if (playerRef.current == null) return;

        const player = dashjs.MediaPlayer().create();
        player.initialize(playerRef.current, props.src, true);
        player.play();
        const interval = setInterval(() => {
            setLatency(player.getCurrentLiveLatency());
        }, 500);

        return () => {
            clearInterval(interval);
            player.destroy();
        };
    }, [props.src]);

    if (!props.src) return null;

    return (
        <>
            {props.showUrl && (
                <div className="dash-player-url">
                    Stream Url: {props.src}&nbsp;&nbsp;
                    <FontAwesomeIcon
                        icon={faCopy}
                        onClick={async (_) => {
                            await window.navigator.clipboard.writeText(
                                props.src,
                            );
                        }}
                        className="dash-player-url-copy"
                    />
                </div>
            )}
            <div>Latency: {latency} seconds</div>
            <video ref={playerRef} className="dash-player" muted />
        </>
    );
}
