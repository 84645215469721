import { ISponsor } from "../../../types";

type Props = {
    moduleId: string;
    sponsor: ISponsor;
};

export default function SponsorIcon(props: Props) {
    const { sponsor, moduleId } = props;
    switch (sponsor.type) {
        case "image":
            return (
                <img
                    style={iconStyleFromModuleId(moduleId)}
                    src={sponsor.url}
                />
            );
        case "video":
            return (
                <video
                    src={sponsor.url}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                />
            );
    }
}

export function iconStyleFromModuleId(moduleId: string): React.CSSProperties {
    switch (moduleId) {
        case "QldHorseRacing":
        case "QldHarnessRacing":
            return {
                borderRadius: "50%",
            };
        case "HRV":
            return {
                width: "100%",
                // overhang is 80tan(20deg) = 29.118 so aspect ratio is 80:(80 + 29.118) = 80:109.118
                height: "calc(80px * 80/109.118)",
                // the rise is tan(20deg) = 0.36397
                clipPath: "polygon(36.397% 0%, 100% 0%, 63.603% 100%, 0% 100%)",
                objectFit: "cover",
                backgroundColor: "#004ea8ee",
            };
        default:
            return {};
    }
}
