import { ReactNode, FormEvent, useState } from "react";

import "./AssetUploadForm.styles.less";

interface Props {
    onSubmit: (event: FormEvent) => Promise<void>;
    children: ReactNode;
    title?: string;
}

export default function AssetUploadForm(props: Props) {
    const title = props.title ?? "Upload";

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        setErrorMessage(null);
        setIsUploading(true);

        try {
            await props.onSubmit(event);
        } catch (error) {
            if (error !== null && typeof error === "object") {
                setErrorMessage(error.toString());
            }
        }

        setIsUploading(false);
    }

    return (
        <form className="asset-upload-form" onSubmit={handleSubmit}>
            <h2>{title}</h2>

            {props.children}

            {isUploading && <span>Uploading...</span>}
            {errorMessage && <span>{errorMessage}</span>}
            <button disabled={isUploading} type="submit">
                {title}
            </button>
        </form>
    );
}
