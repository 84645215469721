import React from "react";
import moment from "moment";
import { IRacingDashboardState } from "../types";

interface IProps {
    date?: string;
    raceNumber?: number;
    moduleState: IRacingDashboardState;
}

const WaitingForData = (props: IProps) => {
    const {
        date,
        raceNumber,
        moduleState: { selectedRace, selectedABARace, isSubscribed },
    } = props;

    if (
        !raceNumber ||
        (selectedRace && selectedRace.runners.length) ||
        (selectedABARace &&
            selectedABARace?.raceNumber == raceNumber &&
            selectedABARace?.runners?.length)
    )
        return null;

    const today = moment().startOf("d");
    const isPastDate = !date || today.isAfter(date);

    const message = isPastDate
        ? "No data available."
        : isSubscribed
        ? "Waiting for data..."
        : "Subscribing to TAB";

    return (
        <div className="waiting-for-data">
            <div className="banner">{message}</div>
        </div>
    );
};

export default React.memo(WaitingForData);
