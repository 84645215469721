import DropDownMenu from "components/Pure/DropDownMenu";
import { useEffect, useState } from "react";
import { onSwitchTenant } from "state/Auth";
import { useAppDispatch } from "state/hooks";

export default function TenantDropDownMenu(props: {
    currentTenant: string | undefined;
    tenants: string[] | undefined;
    onTenantSwitch: (tenant: string) => void;
}) {
    return (
        <div className="pure-menu-horizontal text-right">
            <ul className="pure-menu-list text-left">
                <li>
                    <DropDownMenu
                        parent={() => (
                            <TenantDropDownParent
                                currentTenant={props.currentTenant}
                            />
                        )}
                        render={() => (
                            <TenantDropDownChildrenChildren
                                currentTenant={props.currentTenant}
                                tenants={props.tenants}
                                onTenantSwitch={props.onTenantSwitch}
                            />
                        )}
                        showOnHover
                        hideOnBlur
                    />
                </li>
            </ul>
        </div>
    );
}

function TenantDropDownParent(props: { currentTenant: string | undefined }) {
    const [currentTenant, setCurrentTenant] = useState<string>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setCurrentTenant(props.currentTenant);
        dispatch(onSwitchTenant(props.currentTenant!));
    }, [props.currentTenant, dispatch]);

    return (
        <div className="pure-menu-link tenant-selection-menu">
            {currentTenant}
        </div>
    );
}

function TenantDropDownChildrenChildren(props: {
    currentTenant: string | undefined;
    tenants: string[] | undefined;
    onTenantSwitch: (tenant: string) => void;
}) {
    const { currentTenant, tenants } = props;

    return (
        <ul className="pure-menu-children">
            {tenants
                ?.filter((t) => t !== currentTenant)
                .map((t) => (
                    <li className="pure-menu-item" key={`tenant-selector-${t}`}>
                        <span
                            className="pure-menu-link"
                            onClick={() => props.onTenantSwitch(t)}
                        >
                            {t}
                        </span>
                    </li>
                ))}
        </ul>
    );
}
