import { connect } from "react-redux";

import { IGlobalState } from "../../../../state/store";
import ComponentButton from "../ComponentButton/ComponentButton";

import { IABARaceDetails, IABARunner, RaceIdentity } from "modules/atc/types";
import { Components as RAComponents } from "modules/atc/types";
import HorsePriceTableCell from "../HorseTables/HorsePriceTableCell";
import moment from "moment";
import cn from "classnames";
import "../AtcHomestraight/AtcHomestraightControls.styles.less";
import "../HorseTables/styles.less";
import "../RaceTitleBar/styles.less";

interface RaceControlsProps {
    raceIdentity: RaceIdentity;
}

interface PropsFromState {
    selectedRace: IABARaceDetails | null;
}

enum Components {
    JockeyList = "ABA_HorseList",
    Transition = "ff_transition",
}

function RaceControls(props: RaceControlsProps & PropsFromState) {
    if (!props.raceIdentity.raceNumber || !props.selectedRace) {
        return null;
    }

    const { selectedRace: race } = props;

    const Components1549_572 = [Components.JockeyList, Components.Transition];
    const Components2564_148 = [RAComponents.raceTitle];

    const renderButtons = () => {
        return (
            <div className="button-column">
                <ComponentButton
                    title="ABA Price List"
                    componentType={Components.JockeyList}
                    componentContext={props.raceIdentity}
                    hideOthers={Components1549_572}
                />
                <ComponentButton
                    title="Correct Weights Transition"
                    componentType={Components.Transition}
                    componentContext={props.raceIdentity}
                    hideOthers={Components1549_572}
                />

                <br />
                <hr />
                <br />

                <ComponentButton
                    title="Race Title"
                    componentType={RAComponents.raceTitle}
                    componentContext={props.raceIdentity}
                    hideOthers={Components2564_148}
                />
            </div>
        );
    };

    const renderRaceDetails = () => {
        const raceStatusClassNames = race.raceStatus
            ? race.raceStatus.toLowerCase()
            : "";
        return (
            <section className="race-title-bar">
                <div className="race-card">
                    <h4>R{race.raceNumber}</h4>
                    <h6>{moment(race.raceTime).format("LT")}</h6>
                </div>

                <div className="race-details">
                    <h4>{race.raceName}</h4>
                    <h5>
                        <span className={raceStatusClassNames}>
                            {race.raceStatus}
                        </span>
                    </h5>
                </div>
            </section>
        );
    };

    const RenderTableRow = (props: { horse: IABARunner; rowIndex: number }) => {
        const { horse, rowIndex } = props;
        const rowClasses = cn({
            alt: rowIndex % 2 === 1,
            scratched: horse.isScratched,
        });

        const flucs: (number | undefined)[] = [...horse.flucs]
            .reverse()
            .slice(0, 5)
            .reverse();
        if (flucs.length < 5) {
            for (let i = 0; i < 5 - horse.flucs.length; i++)
                flucs.push(undefined);
        }

        return (
            <tr className={rowClasses}>
                <td className="horse-number-cell">{horse.number}</td>

                <td className="silk-cell">
                    <img src={horse.silk} />
                </td>
                <td>{horse.name}</td>
                {flucs.map((p, i) => (
                    <HorsePriceTableCell key={i} price={p} name={horse.name} />
                ))}
                <HorsePriceTableCell
                    price={horse.officialPrice}
                    name={horse.name}
                />
                <HorsePriceTableCell price={horse.tabWin} name={horse.name} />
                <HorsePriceTableCell price={horse.tabPlace} name={horse.name} />
            </tr>
        );
    };

    const HorseList = (props: { horses: IABARunner[] }) => {
        return (
            <table className="table runners-table">
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th className="silk-cell">Silk</th>
                        <th>Runner</th>
                        <th colSpan={5}>Flucs</th>
                        <th className="price-cell">Official Price</th>
                        <th className="price-cell">Win</th>
                        <th className="price-cell">Place</th>
                    </tr>
                </thead>
                <tbody>
                    {props.horses.map((horse, i) => (
                        <RenderTableRow key={i} rowIndex={i} horse={horse} />
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <section className="two-column-container">
            <section className="column">
                <div className="column-header">{renderRaceDetails()}</div>

                <div className="pre-race-controls">
                    <div className="runners-column">
                        <div className="runners-table">
                            <HorseList horses={race.runners} />
                        </div>
                    </div>

                    <div className="button-column">{renderButtons()}</div>
                </div>
            </section>
        </section>
    );
}

const mapStateToProps = (state: IGlobalState, ownProps: RaceControlsProps) => {
    const { selectedABARace: selectedRace } = state.moduleState;

    return {
        ...ownProps,
        selectedRace,
    };
};

export default connect(mapStateToProps, {})(RaceControls);
