import React from "react";
import { connect } from "react-redux";
import { Components, RaceIdentity } from "../../types";
import { IGlobalState } from "state/store";
import { useParams } from "react-router-dom";
import { useAppSelector } from "state/hooks";

import ComponentButton from "../ComponentButton/ComponentButton";
import RunningNumbers from "../PostRace/RunningNumbers/RunningNumbers";
import ResultsTable from "../HorseTables/ResultsTable";
import ExoticsTable from "../PostRace/ExoticsTable";
import RunnersTable from "../HorseTables/RunnersTable";
import RaceTitle from "../RaceTitleBar/RaceTitle";

interface IOwnProps {
    raceIdentity: RaceIdentity;
}

type StateProps = ReturnType<typeof mapStateToProps>;

export const redcliffeInternalScreensComponents = [
    Components.ff_fixedBackground,
    Components.ff_Totes,
    Components.ff_dividends,
];

const TotesAndDividends = (props: { raceIdentity: RaceIdentity }) => {
    return (
        <ComponentButton
            title="Totes + Dividends"
            componentType={redcliffeInternalScreensComponents}
            componentContext={props.raceIdentity}
            visualContext={{
                autoScroll: true,
            }}
        />
    );
};

const GraphicsTestButton = () => {
    const compositors = useAppSelector((state) => state.compositor);
    const truckId = useParams<{ truckId: string }>().truckId;

    let venkmanName = "";
    if (truckId) {
        const compositor = Object.values(compositors).find((c) =>
            Object.keys(c.trucks).includes(truckId),
        );
        venkmanName = compositor?.name ?? "";
    }

    return (
        <ComponentButton
            title="Graphics Test Pattern"
            componentType={Components.lf_watermark}
            componentContext={{}}
            visualContext={{
                graphicsTest: true,
                venkmanName,
            }}
        />
    );
};

class PreRaceControls extends React.PureComponent<IOwnProps & StateProps> {
    public render() {
        const { race } = this.props;
        if (!race) {
            return null;
        }

        return (
            <section className="two-column-container">
                <section className="column">
                    <div className="column-header">
                        <RaceTitle
                            race={race}
                            raceIdentity={this.props.raceIdentity}
                        />
                    </div>

                    <div className="pre-race-controls">
                        <div className="runners-column">
                            <div className="runners-table">
                                <RunnersTable
                                    horses={race.runners}
                                    selectedHorseNumber={undefined}
                                    // We don't need a horse selection callback here
                                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                                    onHorseSelected={(_horse) => {}}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="column">
                    <div className="column-header">
                        <RunningNumbers
                            raceIdentity={this.props.raceIdentity}
                        />
                    </div>

                    <div className="post-race-controls">
                        <div className="results-column">
                            <ResultsTable />
                            <ExoticsTable />
                        </div>

                        <div className="button-column">
                            <TotesAndDividends
                                raceIdentity={this.props.raceIdentity}
                            />
                            <br />
                            <GraphicsTestButton />
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps) => {
    const { selectedRace } = state.moduleState;

    return {
        ...ownProps,
        race: selectedRace,
    };
};

export default connect(mapStateToProps, {})(PreRaceControls);
