import { useCallback } from "react";
import cn from "classnames";

import "./Checkbox.less";
import TickIcon from "./Icons/TickIcon";

interface ICheckboxProps {
    title: string;
    checked: boolean;
    disabled?: boolean;
    onChange: (checked: boolean) => void;
}

export default function Checkbox(props: ICheckboxProps) {
    const { title, checked, disabled, onChange } = props;

    const handleCheckboxClick = useCallback(() => {
        if (disabled) return;

        onChange(!checked);
    }, [disabled, checked, onChange]);

    const containerClassNames = cn("checkbox-container", {
        checked,
        "checkbox-disabled": disabled,
    });

    return (
        <div className={containerClassNames} onClick={handleCheckboxClick}>
            {/*<div className={classNames} />*/}
            {checked && <TickIcon />}
            {/* !checked && <CrossIcon /> */}
            {!checked && <div className="checkbox-unchecked" />}
            <label>{title}</label>
        </div>
    );
}
