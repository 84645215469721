import { ReactElement } from "react";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    name: string;
    icon: ReactElement;
    onEdit?: () => void;
    onDelete?: () => void;
}

export default function AssetListItem(props: Props) {
    return (
        <div className="asset-list-item">
            {props.onEdit && (
                <div className="edit-button">
                    <FontAwesomeIcon icon={faEdit} onClick={props.onEdit} />
                </div>
            )}
            <div className="asset-icon">{props.icon}</div>
            <span className="asset-name">{props.name}</span>
            {props.onDelete && (
                <button className="delete-button" onClick={props.onDelete}>
                    Delete
                </button>
            )}
        </div>
    );
}
