import { useState } from "react";

import useEffectAsync from "core/hooks/useEffectAsync";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import AssetList from "components/AssetManagement/AssetList";
import AssetListItem from "components/AssetManagement/AssetListItem";

import SponsorUploader from "./SponsorUploader";
import SponsorIcon from "./SponsorIcon";
import { ISponsor } from "modules/triples/types";

export default function SponsorAssetPage(props: { moduleId: string }) {
    const [sponsors, setSponsors] = useState<ISponsor[]>([]);

    async function updateSponsors() {
        const sponsors = await harmony.get<ISponsor[]>(
            props.moduleId,
            "LoadSponsorList",
        );
        sponsors.sort((left, right) =>
            left.name
                .toLocaleLowerCase()
                .localeCompare(right.name.toLocaleLowerCase()),
        );
        setSponsors(sponsors);
    }

    useEffectAsync(updateSponsors);

    async function handleDelete(id: number) {
        await harmony.post(props.moduleId, "RemoveSponsor", { id });
        setSponsors(sponsors.filter((sponsor) => sponsor.id != id));
    }

    const assets = sponsors.map((sponsor) => {
        return (
            <AssetListItem
                key={sponsor.id}
                name={sponsor.name}
                icon={
                    <SponsorIcon moduleId={props.moduleId} sponsor={sponsor} />
                }
                onDelete={() => handleDelete(sponsor.id)}
            />
        );
    });

    return (
        <AssetList
            renderUploadForm={(p) => (
                <SponsorUploader moduleId={props.moduleId} {...p} />
            )}
            onUpload={updateSponsors}
        >
            {assets}
        </AssetList>
    );
}
