export interface IndividualTrainer {
    name: string;
    isSelected: boolean;
}

export interface HorseType {
    number: number;
    name: string;
    trainer: string;
    jockey: string;
    barrierNumber: string;
    form: string;
    skyRating: string;
    winAmount?: string;
    placeAmount?: string;
    claimAmount?: number;
    isScratched: boolean;
    handicapWeight: string;
    silk: string;
    individualTrainers: IndividualTrainer[];
}

export interface IRace {
    location: string;
    raceNumber: number;
    raceName: string;
    distanceMetres: string;
    raceTime: string;
    raceType: RaceType;
    raceStatus: string;
}

export interface IRaceDetails {
    raceClassConditions: string;
    distanceMetres: number;
    raceNumber: number;
    raceName: string;
    raceStartTime: string;
    raceStatus: string;
    logo: string;
    heartbeat: Heartbeat;
    numberOfPlaces: number;
    runners: HorseType[];
    editable: boolean;
}

export interface Heartbeat {
    raceNumber: number;
    startTime: string; // ISO Date
    status: string;
    hasDividends: boolean;
    acceptanceLevel?: string;
    pricesGeneratedAt: string; // ISO Date
    nextPriceUpdateAt: string; // ISO Date
    pricesPollStatus: string;
}

export type RaceType = "G" | "H" | "R" | "?";

export interface IMeetVenuesByDate {
    [date: string]: IStatesByVenue;
}

export interface IStatesByVenue {
    [venue: string]: IRaceTypesByState;
}

export interface IRaceTypesByState {
    [state: string]: IRacesByRaceType;
}

export interface IRacesByRaceType {
    [raceType: string]: IRace[];
}

export interface IRacingDashboardState {
    meets: IMeetVenuesByDate;
    selectedRace: IRaceDetails | null;
    selectedABARace: IABARaceDetails | null;
    subscribedMeets: MeetIdentity[];

    dividends: DividendType | null;
    lastError: string | null;
    isSubscribed: boolean;
}

export type ManualPositionType = number | "P" | "";

export interface WageringProduct {
    name: string;
    selections: (string | number)[];
    amount: number;
    jackpotCarriedOver: number;
}

export interface IPlacing {
    placeNumber: number;
    runner: HorseType | undefined;
    winAmount: string;
    placeAmount: string;
    photo: boolean;
}

export interface PlaceType {
    place: number;
    horseNumber: number;
    jockeyName: string;
    trainerName: string;
    winAmount: string;
    placeAmount: string;
}

export interface DividendType {
    isInterim: boolean;
    raceNumber: number;
    raceName: string;
    raceClassConditions: string;
    distanceMeters: number;
    isParimutuelPlaceAllowed: boolean;
    numberOfPlaces: number;

    places: PlaceType[];
    dividends: WageringProduct[];
}

export interface MeetIdentity {
    date: string;
    state: string;
    venue: string;
    eventType: string;
}

export interface RaceIdentity extends MeetIdentity {
    raceNumber: number;
}

export interface IRenderComponentContext extends MeetIdentity {
    raceNumber?: number;
    horseNumber?: number | undefined;
    type?: string;
}

export interface IFullFrameImage {
    id: string;
    name: string;
    src: string;
}

export interface ISponsorLogo {
    id: string;
    name: string;
    small?: string;
    large?: string;
    keywords?: string[];
}

export interface IABARaceDetails {
    venueName: string;
    raceType: RaceType;
    raceNumber: number;
    jurisdiction: string;
    raceName: string;
    raceStatus: string;
    raceTime: string;
    runners: IABARunner[];
}

export interface IABARunner {
    number: number;
    name: string;
    silk: string;
    flucs: number[];
    officialPrice: number;
    tabWin: number;
    tabPlace: number;
    isScratched: boolean;
}

export enum Components {
    ff_Totes = "ff_totes",
    ff_dividends = "ff_dividends",
    ff_fixedBackground = "ff_fixedBackground",
    lf_pastThePost = "lf_pastThePost",
    ff_sponsorCycle = "ff_sponsorCycle",
    ff_raceSponsor = "ff_raceSponsor",
    ff_winningOwner = "ff_winningOwner",
    ff_venueWinningOwner = "ff_venueWinningOwner",
    raceTitle = "lf_raceTitle",
    lf_lateScratching = "lf_lateScratching",
    lf_protest = "lf_protest",
    lf_photoAllPlacings = "lf_photoAllPlacings",
    lf_runningNumbers = "lf_runningNumbers",
    lf_silkAndName = "lf_silkAndName",
    lf_topHorses = "lf_topHorses",
    lf_yardPage = "lf_yardPage",
    lf_commentatorPick = "lf_commentatorPick",
    manualSuper = "lf_super",
    lf_runningTime = "lf_runningTime",
    lf_jockeyPositions = "lf_jockeyPositions",
    lf_watermark = "lf_watermark",
    ff_singleImage = "ff_singleImage",
}

export enum FixedBackgroundType {
    /**
     * The fixed background should always be shown before the component is shown, if the user has elected to
     * show a fixed background.
     */
    required = "Required",

    /**
     * The component should be shown regardless of if the fixed background is showing. Once the component is
     * shown, hide the fixed background if it is currently active.
     */
    optional = "Optional",

    /**
     * The fixed background should be hidden before the component is shown.
     */
    never = "Never",
}

export interface RaceTitleData {
    venueName: string;
    raceName: string;
    raceNumber: number;
    distance: number;
}

export interface YardPageData {
    silkImagePath: string;

    horseName: string;
    horseNumber: number;
    gate: number;

    // Page 1
    jockey: string;
    trainer: string;
    breeding: string;
    sire: string;
    dame: string;

    // Page 2
    careerStats: string;
    confidenceRating: string;
    confidenceStats: string;
    weight: string;
    rating: number;

    winAmount: number;
    placeAmount: number;

    page: number;
}

export interface Protest {
    accuserHorseNumber: number | null;
    accuseeHorseNumber: number | null;
    reason: string;
}

export interface PageInfo {
    pageCount: number;
    currentPage: number;
}

export enum ManualSuperType {
    Manual = "manual",
    Jockey = "jockey",
    Trainer = "trainer",
    Horse = "horse",
}

export type ManualSuperKey = {
    race: RaceIdentity;
    horse?: number | undefined;
    type: ManualSuperType;
};

export type ManualSuper = {
    topLine: string;
    bottomLine: string;
};
