import { useEffect, useState } from "react";
import UsersList from "./UsersList";
import repository, { UserModel } from "../repository";

export default function UsersPage() {
    const [users, setUsers] = useState<UserModel[]>([]);

    useEffect(() => {
        void repository.users.getAll().then((users) => {
            setUsers(users);
        });
    }, []);

    return (
        <div>
            <UsersList users={users} />
        </div>
    );
}
