import React, { useCallback, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "state/hooks";
import { onLogin } from "../../state/Auth";
import { Key } from "ts-keycode-enum";

import "./style.less";
import FormButton from "../Forms/FormButton";

export default function LoginPage() {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleUsernameChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value);
        },
        [],
    );
    const handlePasswordChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        },
        [],
    );

    const handleLogin = useCallback(() => {
        if (username && password) {
            dispatch(onLogin(username, password));
        }
    }, [dispatch, password, username]);

    const handleTextFieldKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.keyCode === Key.Enter) {
                handleLogin();
                event.preventDefault();
            }
        },
        [handleLogin],
    );

    const loginMessage = useAppSelector((state) => state.auth.message);

    const from = (useLocation().state as any)?.from.pathname ?? "/";
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    if (isLoggedIn) {
        return <Navigate to={from} />;
    }

    return (
        <>
            <header className="login-header">
                Welcome to Vidlay
                {__ENVIRONMENT_NAME__ && ` (${__ENVIRONMENT_NAME__})`}!
            </header>

            <div className="login-form-container">
                <div className="pure-form pure-form-stacked login-form">
                    <div className="text-center uppercase">Please sign in</div>

                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        autoFocus={true}
                        onChange={handleUsernameChanged}
                        onKeyDown={handleTextFieldKeyDown}
                    />

                    <label htmlFor="Password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handlePasswordChanged}
                        onKeyDown={handleTextFieldKeyDown}
                    />

                    <div className="login-button">
                        <FormButton
                            title="Login"
                            onClick={handleLogin}
                            disabled={!(username && password)}
                        />
                    </div>

                    <div className="loginMessage">{loginMessage}</div>
                </div>
            </div>
        </>
    );
}
