import { RaceIdentity } from "../../types";
import ResultsTable from "../HorseTables/ResultsTable";
import RunningNumbers from "./RunningNumbers/RunningNumbers";
import ExoticsTable from "./ExoticsTable";
import PostRaceButtons from "./PostRaceButtons";

interface IOwnProps {
    raceIdentity: RaceIdentity;
}

export default function PostRaceControls(props: IOwnProps) {
    const { raceIdentity } = props;

    return (
        <section className="column">
            <div className="column-header">
                <RunningNumbers raceIdentity={raceIdentity} />
            </div>

            <div className="post-race-controls">
                <div className="results-column">
                    <ResultsTable />
                    <ExoticsTable />
                </div>

                <div className="button-column">
                    <PostRaceButtons raceIdentity={raceIdentity} />
                </div>
            </div>
        </section>
    );
}
