import { useState } from "react";

import ImageDropZone from "components/ImageDropZone";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import AssetUploadForm from "components/AssetManagement/AssetUploadForm";
import { ISponsorUploadResponse } from "modules/triples/types";

import { iconStyleFromModuleId } from "./SponsorIcon";

const DROP_TARGET_HEIGHT_PX = 80;

export enum ImageType {
    Image = "image",
    Video = "video",
}

interface Props {
    moduleId: string;
    onFinish: (sponsorId: number) => void;
}

export default function SponsorLogoUploader(props: Props) {
    const [sponsorName, setSponsorName] = useState<string>("");
    const [sponsorImage, setSponsorImage] = useState<File | null>(null);

    async function handleUpload() {
        if (!(sponsorName && sponsorImage)) {
            throw "You must enter a name for this sponsor, and upload sponsor.";
        }

        // Harmony creates an id for the sponsor when the data gets posted
        const { id } = await harmony.post<ISponsorUploadResponse>(
            props.moduleId,
            "NewSponsor",
            {
                sponsorName,
            },
        );

        if (id == -1 || id == undefined) {
            throw "Could not allocate ID. Please try again.";
        }

        await harmony
            .post(
                props.moduleId,
                `UpdateSponsorImage?sponsorId=${id}`,
                sponsorImage,
            )
            .then(() => {
                props.onFinish(id);
            })
            .catch(async (_err: unknown) => {
                await harmony.post(props.moduleId, "RemoveSponsor", {
                    id,
                });
                throw "Could not upload image. Please wait and try again.";
            });
    }

    return (
        <AssetUploadForm onSubmit={handleUpload}>
            <label htmlFor="name">Name</label>
            <input
                name="name"
                type="text"
                value={sponsorName}
                onChange={(event) => setSponsorName(event.target.value)}
            />

            <ImageDropZone
                title="Sponsor"
                height={DROP_TARGET_HEIGHT_PX}
                image={sponsorImage ?? undefined}
                imageRatio={ratioFromModuleId(props.moduleId)}
                extraImageStyles={iconStyleFromModuleId(props.moduleId)}
                onImageUploaded={(image) => setSponsorImage(image)}
            />
        </AssetUploadForm>
    );
}

function ratioFromModuleId(moduleId: string): [number, number] {
    switch (moduleId) {
        case "SkySTC":
        case "SkyOne":
        case "TripleS":
            return [5, 1];
        case "QldHorseRacing":
        case "QldHarnessRacing":
        case "HRV":
            return [1, 1];
        default:
            console.warn("unknown module id", moduleId);
            return [1, 1];
    }
}
