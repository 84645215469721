import { default as withTruck, WithTruckProps } from "../withTruck";
import ConnectedTruckSubHeader from "../StandardLayout/ConnectedTruckSubHeader";
import Reconnecting from "./Reconnecting";

//TODO: 23/10/2018 - gerrod - We could make these dynamic imports
import AtcModule from "modules/atc";
import TripleSModule from "modules/triples";
import SkyModule from "modules/sky";
import { routeFactory } from "components/StandardLayout/Routes";
import { useEffect } from "react";
import { onSwitchTenant } from "state/Auth";
import { useAppSelector, useAppDispatch } from "state/hooks";
import { useParams } from "react-router-dom";

function ModulePage(props: WithTruckProps) {
    const { compositor, module } = props;

    const params = useParams<{ tenantId: string }>();
    const tenantId = params.tenantId;
    const currentTenantId = useAppSelector((state) => state.auth.currentTenant);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (tenantId && tenantId !== currentTenantId) {
            dispatch(onSwitchTenant(tenantId));
        }
    }, [tenantId, currentTenantId, dispatch]);

    const fallbackPath = routeFactory.toHome();
    if (!compositor) {
        return <Reconnecting fallbackRoute={fallbackPath} />;
    }

    const isAtc =
        module &&
        (module.moduleId === "RacingAustralia" ||
            module.moduleId === "AtcHomestraight" ||
            module.moduleId === "ATCBigScreen" ||
            module.moduleId === "AtcWinningOwner" ||
            module.moduleId === "RedcliffeInternalScreens");
    const isTripleS =
        module &&
        (module.moduleId === "TripleS" ||
            module.moduleId === "BreezeUp" ||
            module.moduleId === "HRV" ||
            module.moduleId === "QldHorseRacing" ||
            module.moduleId === "QldHarnessRacing" ||
            module.moduleId === "MRC");
    const isSky =
        module &&
        (module.moduleId === "SkySTC" ||
            module.moduleId === "SkyOne" ||
            module.moduleId === "SkyHarness");

    return (
        <>
            <ConnectedTruckSubHeader key="connected-truck-header" />
            {isAtc && <AtcModule />}
            {isTripleS && <TripleSModule />}
            {isSky && <SkyModule />}
        </>
    );
}

export default withTruck(ModulePage);
