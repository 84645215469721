import { Key } from "ts-keycode-enum";
import React from "react";

export class KeyBindings extends React.Component<{
    primary: Key;
    label: string;
    raised(buttons: number[]): void;
}> {
    render() {
        return null;
    }

    componentDidMount() {
        window.addEventListener("keyup", this.onkeyup, true);
        window.addEventListener("keydown", this.onkeydown, true);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.onkeydown, true);
        window.removeEventListener("keyup", this.onkeyup, true);
    }

    buttons: number[] = [];

    enabled = false;
    onkeydown = (evt: KeyboardEvent) => {
        //dont capture if in a form
        if (
            document.activeElement &&
            ["INPUT", "SELECT"].includes(document.activeElement.nodeName)
        ) {
            return;
        }

        if (evt.keyCode === this.props.primary) {
            this.enabled = true;
            this.buttons.length = 0;
        } else {
            this.buttons.push(evt.keyCode);
        }
    };

    delayTimeout: number | undefined = undefined;

    immediateKeys = [
        Key.LeftArrow,
        Key.RightArrow,
        Key.UpArrow,
        Key.DownArrow,
        Key.PageDown,
        Key.PageUp,
        Key.Add,
        Key.Subtract,
    ];

    onkeyup = (evt: KeyboardEvent) => {
        if (!this.enabled) {
            return;
        }
        clearTimeout(this.delayTimeout);

        if (evt.keyCode === this.props.primary) {
            this.enabled = false;
            if (this.buttons.length > 0) {
                this.notifyButtons(this.buttons);
            }
            return;
        }

        if (this.immediateKeys.includes(evt.keyCode)) {
            this.notifyButtons([evt.keyCode]);
            return;
        }

        this.delayTimeout = setTimeout(this.notifyButtons, 300);
    };

    notifyButtons = (buttons: number[]) => {
        buttons = buttons || this.buttons;
        if (buttons.length > 0 && this.props.raised) {
            this.props.raised(buttons);
        }
        this.buttons.length = 0;
    };
}
