import moment from "moment";
import { useState, ChangeEvent } from "react";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import { IRaceDetails, RaceIdentity } from "../../types";
import { useTruck } from "components/withTruck";

const RaceDetails = ({
    race,
    raceIdentity,
}: {
    race: IRaceDetails;
    raceIdentity: RaceIdentity;
}) => {
    const moduleId = useTruck().moduleId ?? "";

    const raceStatusClassNames = race.raceStatus
        ? race.raceStatus.toLowerCase()
        : "";

    const [raceNameEdit, setRaceNameEdit] = useState<string | null>(null);

    const handleRaceNameEditStart = () => {
        setRaceNameEdit(race.raceName);
    };

    const handleRaceNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setRaceNameEdit(event.target.value);
    };

    const handleRaceNameSave = () => {
        if (raceNameEdit !== null) {
            void harmony.post(moduleId, "updateRaceName", {
                race: raceIdentity,
                name: raceNameEdit,
            });
            setRaceNameEdit(null);
        }
    };

    return (
        <>
            <div className="race-card">
                <h4>R{race.raceNumber}</h4>
                <h6>{moment(race.raceStartTime).format("LT")}</h6>
            </div>

            <div className="race-details">
                <h4>
                    {raceNameEdit !== null ? (
                        <>
                            <input
                                autoFocus
                                type="text"
                                className="edit-box"
                                value={raceNameEdit}
                                onChange={handleRaceNameChange}
                            />
                            <button
                                className="edit-button"
                                onClick={handleRaceNameSave}
                            >
                                Save
                            </button>
                        </>
                    ) : (
                        <>
                            {race.raceName}
                            {race.editable && (
                                <button
                                    className="edit-button"
                                    onClick={handleRaceNameEditStart}
                                >
                                    Edit
                                </button>
                            )}
                        </>
                    )}
                </h4>
                <h5>
                    {race.raceClassConditions} |&nbsp;
                    {race.distanceMetres}m |&nbsp;
                    <span className={raceStatusClassNames}>
                        {race.raceStatus}
                    </span>
                </h5>
            </div>
        </>
    );
};

export default RaceDetails;
