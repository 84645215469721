import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Components, FixedBackgroundType, RaceIdentity } from "../../types";
import { IGlobalState } from "state/store";
import {
    toggleCarnival,
    toggleFixedBackground,
    toggleScrollDividends,
    setProtestAccusee,
    setProtestAccuser,
    setProtestReason,
} from "../../redux/Module.Redux";

import ComponentButton from "../ComponentButton/ComponentButton";
import ManualPositionsComponentButton from "./ManualPositionsComponentButton";
import { getPlacingsForSelectedRace } from "../../redux/Module.Selectors";

interface IOwnProps {
    raceIdentity: RaceIdentity;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const ProtestButton = (
    props: IOwnProps & StateProps & DispatchProps,
): JSX.Element => {
    const {
        protest: { accuserHorseNumber, accuseeHorseNumber, reason },
        setProtestAccuser,
        setProtestAccusee,
        setProtestReason,
        placingCount,
        placings,
        dividends,
    } = props;

    const options = [
        ["1st", "2nd", "3rd", "4th"].map((pos, index) => ({
            key: `accuser-${pos}`,
            title: pos,
            isSelected: accuserHorseNumber === placings[index],
            onSelected: () =>
                setProtestAccuser(
                    accuserHorseNumber === placings[index]
                        ? null
                        : (placings[index] as string), // TODO this is dodgy,
                ),
        })),
        ["1st", "2nd", "3rd", "4th"].map((pos, index) => ({
            key: `accusee-${pos}`,
            title: pos,
            isSelected: accuseeHorseNumber === placings[index],
            onSelected: () =>
                setProtestAccusee(
                    accuseeHorseNumber === placings[index]
                        ? null
                        : (placings[index] as string), // TODO this is dodgy,
                ),
        })),
    ];
    const textInputs = [
        {
            label: "Reason",
            value: reason,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setProtestReason(e.target.value),
        },
    ];
    const visualContext = {
        accuserHorseNumber,
        accuseeHorseNumber,
        reason,
    };
    return (
        <ComponentButton
            title="Protest"
            componentType={Components.lf_protest}
            componentContext={props.raceIdentity}
            visualContext={visualContext}
            textInputs={textInputs}
            options={options}
            disabled={
                !placingCount ||
                !accuserHorseNumber ||
                !accuseeHorseNumber ||
                !dividends ||
                !dividends.isInterim
            }
            optionsDisabled={!dividends || !dividends.isInterim}
            hideOthers
        />
    );
};

const DividendsButton = (
    props: IOwnProps & StateProps & DispatchProps,
): JSX.Element => {
    const options = [
        {
            title: "Auto Scroll",
            isSelected: props.scrollDividends,
            onSelected: props.onToggleScrollDividends,
        },
        {
            title: "Fix Background",
            isSelected: props.useFixedBackground,
            onSelected: props.onToggleFixedBackground,
        },
    ];

    const visualContext = {
        manualPositions: props.dividends ? null : props.placings,
        autoScroll: props.scrollDividends,
    };

    const disabled = !(props.dividends && props.placingCount);

    return (
        <ComponentButton
            title="Dividends"
            componentType={Components.ff_dividends}
            componentContext={props.raceIdentity}
            visualContext={visualContext}
            fixedBackgroundType={FixedBackgroundType.required}
            disabled={disabled}
            options={options}
            hideOthers
        />
    );
};

const WinningOwnersButton = (props: IOwnProps & StateProps & DispatchProps) => {
    const { dividends, manualPlacings } = props;

    const raceManualPlacings = manualPlacings?.[props.raceIdentity.raceNumber];

    const winningHorseNumbers = useMemo(() => {
        if (dividends) {
            return dividends.places
                .filter(({ place }) => place === 1)
                .map(({ horseNumber }) => horseNumber);
        }

        return raceManualPlacings && raceManualPlacings.length
            ? [raceManualPlacings[0]]
            : [];
    }, [dividends, raceManualPlacings]);

    const visualContext = {
        horseNumbers: winningHorseNumbers,
        isCarnival: props.isCarnival,
    };

    const options = [
        {
            title: "Carnival",
            isSelected: props.isCarnival,
            onSelected: props.onToggleCarnival,
        },
    ];

    return (
        <ComponentButton
            title="Winning Owner"
            componentType={Components.ff_winningOwner}
            componentContext={props.raceIdentity}
            visualContext={visualContext}
            disabled={!winningHorseNumbers.length}
            options={options}
            hideOthers
        />
    );
};

const SponsorButton = (props: IOwnProps): JSX.Element => {
    const visualContext = {
        type: "single",
        windowed: false,
    };

    return (
        <ComponentButton
            title="Race Sponsor"
            componentType={Components.ff_raceSponsor}
            componentContext={props.raceIdentity}
            visualContext={visualContext}
            fixedBackgroundType={FixedBackgroundType.optional}
            hideOthers
        />
    );
};

class PreRaceControls extends React.PureComponent<
    IOwnProps & StateProps & DispatchProps
> {
    public render() {
        const { raceIdentity, race, placingCount, dividends } = this.props;
        if (!race) {
            return null;
        }

        return (
            <React.Fragment>
                {/*<TripleSTimingsComponentButton*/}
                {/*    {...this.props}*/}
                {/*    title="Triple-S Timings"*/}
                {/*    componentType={Components.lf_runningTime} />*/}

                <ManualPositionsComponentButton
                    {...this.props}
                    title="Running Numbers"
                    componentType={Components.lf_runningNumbers}
                    disabled={
                        !this.props.noPhotoFinish ||
                        !placingCount ||
                        !!dividends
                    }
                />

                <ComponentButton
                    title="Photo All Placings"
                    componentType={Components.lf_photoAllPlacings}
                    componentContext={raceIdentity}
                    hideOthers
                />

                <ManualPositionsComponentButton
                    {...this.props}
                    title="Past the Post"
                    componentType={Components.lf_pastThePost}
                    disabled={!placingCount}
                />

                <DividendsButton {...this.props} />
                <ProtestButton {...this.props} />
                <WinningOwnersButton {...this.props} />
                <SponsorButton {...this.props} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IGlobalState, ownProps: IOwnProps) => {
    const {
        selectedRace,
        dividends,
        manualPlacings,
        protest,
        useFixedBackground,
        isCarnival,
        scrollDividends,
    } = state.moduleState;

    const placings = getPlacingsForSelectedRace(state)
        .map((placing) => {
            if (placing.runner) return placing.runner.number;
            if (placing.photo) return "P";
            return null;
        })
        .filter((placing): placing is number | "P" => placing !== null);

    const placingCount = placings.filter((placing) => !!placing).length;
    const noPhotoFinish = !!(
        placings.length && !placings.find((placing) => placing === "P")
    );

    return {
        ...ownProps,
        race: selectedRace,
        placings,
        noPhotoFinish,
        placingCount,
        dividends,
        manualPlacings,
        useFixedBackground: useFixedBackground,
        isCarnival: isCarnival,
        scrollDividends: scrollDividends,
        protest,
    };
};

const mapDispatchToProps = {
    onToggleScrollDividends: toggleScrollDividends,
    onToggleFixedBackground: toggleFixedBackground,
    onToggleCarnival: toggleCarnival,
    setProtestAccusee,
    setProtestAccuser,
    setProtestReason,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreRaceControls);
