import React from "react";
import { connect } from "react-redux";
import { isMatch } from "lodash";
import cn from "classnames";
import withTruck, { WithTruckProps } from "components/withTruck";
import {
    hideComponent,
    showComponent,
} from "components/ModulePage/ModulePage.Redux";
import { Components, FixedBackgroundType, PageInfo } from "../../types";
import { IGlobalState } from "../../../../state/store";
import SettingsIcon from "./SettingsIcon";
import { Dispatch } from "redux";

import DropDownMenu from "components/Pure/DropDownMenu";

import "./ComponentButton.less";
import Checkbox from "../../../../components/Forms/Checkbox";
import { ComponentState } from "resources/harmonyTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface IComponentButtonOptions {
    title: string;
    isSelected: boolean;
    onSelected: (isSelected: boolean) => void;
    key?: string;
}

interface IComponentButtonTextInputs {
    label: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IMergedProps {
    title: string;
    disabled: boolean | undefined;
    optionsDisabled?: boolean | undefined;
    active: boolean;
    componentState: ComponentState | undefined;
    onClick: (() => void) | undefined;

    options?:
        | IComponentButtonOptions[]
        | IComponentButtonOptions[][]
        | undefined;
    textInputs?: IComponentButtonTextInputs[] | undefined;

    customOnClickOption?: (() => void) | undefined;
}

class ComponentButton extends React.PureComponent<IMergedProps> {
    render() {
        const {
            title,
            active,
            disabled,
            optionsDisabled,
            options,
            onClick,
            textInputs,
            customOnClickOption,
        } = this.props;

        const hasOptions = !!(options && options.length);
        const hasTextInputs = !!(textInputs && textInputs.length);
        const multiColOptions = !!(hasOptions && Array.isArray(options[0]));
        const hasCustomOnClickOption = !!customOnClickOption;

        const hasActiveOption =
            (multiColOptions &&
                !!(options as IComponentButtonOptions[][]).find((optionsCol) =>
                    optionsCol.find((option) => option.isSelected),
                )) ||
            (hasOptions &&
                !!(options as IComponentButtonOptions[]).find(
                    (option) => option.isSelected,
                ));
        const optionsButtonClassNames = cn("options-button", {
            active: hasActiveOption,
        });

        const containerClassNames = cn("component-button", {
            "with-options":
                hasOptions || hasTextInputs || hasCustomOnClickOption,
            disabled: disabled,
            "pure-menu-horizontal": hasOptions,
        });

        const titleClassNames = cn("title", {
            active,
        });

        const visibilityClassNames = cn("visibility-icon");

        return (
            <div className={containerClassNames}>
                <div className={titleClassNames} onClick={onClick}>
                    {this.props.componentState &&
                        this.renderVisibilityIcon(visibilityClassNames)}
                    <span>{title}</span>
                </div>

                {(hasOptions || hasTextInputs) && (
                    <DropDownMenu
                        className={optionsButtonClassNames}
                        parent={this.renderParent}
                        render={this.renderMenuItems}
                        disabled={optionsDisabled}
                    />
                )}
                {!hasOptions && !hasTextInputs && hasCustomOnClickOption && (
                    <div
                        onClick={(_e) => customOnClickOption()}
                        className={cn(
                            "pure-menu-item pure-menu-has-children options-button",
                        )}
                    >
                        {this.renderParent()}
                    </div>
                )}
            </div>
        );
    }

    private renderVisibilityIcon = (classNames: string) => {
        return (
            <div className={classNames}>
                {this.props.componentState == ComponentState.Visible ? (
                    <FontAwesomeIcon icon={faEye} size="xs" />
                ) : (
                    <FontAwesomeIcon icon={faEyeSlash} size="xs" />
                )}
            </div>
        );
    };

    private renderParent = () => {
        return <SettingsIcon />;
    };

    private renderMenuItems = () => {
        let optionColumns: IComponentButtonOptions[][] = [];
        if (this.props.options) {
            if (Array.isArray(this.props.options[0])) {
                optionColumns = this.props
                    .options as IComponentButtonOptions[][];
            } else {
                optionColumns = [
                    this.props.options,
                ] as IComponentButtonOptions[][];
            }
        }
        const columns = optionColumns.map((col, i) => (
            <div
                key={i}
                style={{
                    width: `${100 / optionColumns.length}%`,
                    float: "left",
                }}
            >
                <ul
                    style={{
                        paddingLeft: "0px",
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                >
                    {col.map((option) => (
                        <li
                            key={option.key || option.title}
                            className="pure-menu-item"
                        >
                            <Checkbox
                                title={option.title}
                                checked={option.isSelected}
                                onChange={option.onSelected}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        ));

        const inputs =
            this.props.textInputs?.map(
                (input: IComponentButtonTextInputs, index: number) => (
                    <input
                        key={index}
                        id={`input-${index}`}
                        placeholder={input.label}
                        className="text-menu-item"
                        type="text"
                        value={input.value}
                        onChange={input.onChange}
                    />
                ),
            ) ?? [];

        return (
            <div className="pure-menu-children">
                {...columns}
                <div className="text-inputs">{...inputs}</div>
            </div>
        );
    };
}

// ---------------------------------------------------------------------------------------------------------------------

type componentType = string[] | string;
interface IOwnProps<C> {
    title: string;
    componentType: componentType;
    componentContext: C;
    visualContext?: { [name: string]: unknown } | undefined;
    fixedBackgroundType?: FixedBackgroundType;
    hideOthers?: boolean | componentType;
    disabled?: boolean;
    optionsDisabled?: boolean;

    onComponentHide?: (componentContext: C) => void;

    options?:
        | IComponentButtonOptions[]
        | IComponentButtonOptions[][]
        | undefined;
    textInputs?: IComponentButtonTextInputs[];

    customOnClickOption?: () => void;
}

interface IActiveComponentButtonProps {
    components: {
        id: string;
        instanceId: string | undefined;
    }[];

    isActive: boolean;

    componentState: ComponentState | undefined;
    isOnLastPage: boolean;

    backgroundInstanceId?: string | undefined;
    useFixedBackground: boolean;
}

interface IDispatchProps {
    hideComponent: (componentType: string, componentInstanceId: string) => void;
    showComponent: (
        componentType: string,
        visualContext: { [name: string]: unknown } | undefined,
        componentInstanceId: string | undefined,
    ) => void;
}

const mapStateToProps = <C,>(
    state: IGlobalState,
    props: WithTruckProps & IOwnProps<C>,
): WithTruckProps & IOwnProps<C> & IActiveComponentButtonProps => {
    const { componentContext, activeComponents } = props;
    const componentType = new Set(
        (Array.isArray(props.componentType)
            ? props.componentType
            : [props.componentType]) ?? [],
    );

    let { title, visualContext } = props;
    const activeInstances =
        activeComponents?.filter(
            (c) =>
                componentType.has(c.componentId as Components) &&
                // TODO is isMatch the right thing to use here?
                isMatch(
                    componentContext as unknown as object,
                    c.componentContext,
                ),
        ) ?? [];
    const backgroundInstanceId = activeComponents.find(
        (c) => c.componentId == Components.ff_fixedBackground,
    )?.componentInstanceId;

    // TODO make this more efficient
    const components = Array.from(componentType).map((c) => ({
        id: c,
        instanceId: activeInstances.find((i) => i.componentId == c)
            ?.componentInstanceId,
    }));

    const activeInstance =
        componentType.size == 1 ? activeInstances[0] : undefined;

    let isOnLastPage = activeInstances.length > 0;
    const pageInfo = activeInstance?.visualContext?.pageInfo as PageInfo;
    if (pageInfo) {
        title =
            pageInfo.pageCount <= 1
                ? title
                : `${title} (${pageInfo.currentPage + 1}/${
                      pageInfo.pageCount
                  })`;

        visualContext = {
            ...visualContext,
            pageInfo: {
                ...pageInfo,
                currentPage: pageInfo.currentPage + 1,
            },
        };

        isOnLastPage = pageInfo.currentPage + 1 >= pageInfo.pageCount;
    }

    return {
        ...props,
        title,
        visualContext,
        isActive: activeInstances?.length > 0,
        components,
        componentState: activeInstance?.state,
        isOnLastPage,

        activeComponents,
        backgroundInstanceId,
        useFixedBackground: state.moduleState.useFixedBackground,
    };
};

const mapDispatchToProps = <C,>(
    dispatch: Dispatch,
    props: WithTruckProps & IOwnProps<C>,
): IDispatchProps => {
    const { truckId, moduleId, componentContext } = props;

    return {
        hideComponent: (componentType, componentInstanceId) =>
            truckId &&
            moduleId &&
            hideComponent(
                truckId,
                moduleId,
                componentType,
                componentInstanceId,
            )(dispatch),

        showComponent: (componentType, visualContext, componentInstanceId) =>
            truckId &&
            moduleId &&
            showComponent(
                truckId,
                moduleId,
                componentType,
                componentContext,
                visualContext,
                componentInstanceId,
            )(dispatch),
    };
};

const mergeProps = <C,>(
    stateProps: WithTruckProps & IOwnProps<C> & IActiveComponentButtonProps,
    dispatchProps: IDispatchProps,
): IMergedProps => {
    const {
        title,
        disabled,
        optionsDisabled,
        isActive,
        componentState,
        isOnLastPage,
        options,
        backgroundInstanceId,
        useFixedBackground,
        textInputs,
        hideOthers,
        customOnClickOption,
    } = stateProps;
    const { showComponent, hideComponent } = dispatchProps;

    const fixedBackgroundType =
        stateProps.fixedBackgroundType || FixedBackgroundType.never;

    let handleClick: (() => void) | undefined = undefined;

    if (!disabled) {
        const { components, componentContext, visualContext, onComponentHide } =
            stateProps;

        if (isOnLastPage) {
            handleClick = () => {
                components.forEach(
                    (c) => c.instanceId && hideComponent(c.id, c.instanceId),
                );

                if (backgroundInstanceId) {
                    //TODO: 7/08/2018 - gerrod - Need to know how long to wait before hiding the background
                    setTimeout(
                        () =>
                            hideComponent(
                                Components.ff_fixedBackground,
                                backgroundInstanceId,
                            ),
                        600,
                    );
                }

                if (onComponentHide) {
                    onComponentHide(componentContext);
                }
            };
        } else {
            handleClick = () => {
                if (hideOthers) {
                    const { activeComponents } = stateProps;
                    active: for (const component of activeComponents) {
                        if (
                            component.componentId ==
                            Components.ff_fixedBackground
                        ) {
                            continue;
                        }

                        if (
                            !(
                                (hideOthers as boolean) === true ||
                                (hideOthers as string) ==
                                    component.componentId ||
                                (hideOthers as string[]).includes(
                                    component.componentId,
                                )
                            )
                        )
                            continue;

                        for (const ignoreComponent of components) {
                            if (
                                component.componentInstanceId ===
                                ignoreComponent.instanceId
                            ) {
                                continue active;
                            }
                        }

                        hideComponent(
                            component.componentId,
                            component.componentInstanceId,
                        );
                    }
                }

                const show = () => {
                    components.forEach((c) =>
                        showComponent(c.id, visualContext, c.instanceId),
                    );
                };

                if (
                    fixedBackgroundType === FixedBackgroundType.required &&
                    !backgroundInstanceId &&
                    useFixedBackground
                ) {
                    showComponent(Components.ff_fixedBackground, {}, undefined);
                    setTimeout(show, 800);
                } else if (
                    fixedBackgroundType === FixedBackgroundType.optional &&
                    backgroundInstanceId
                ) {
                    show();
                    setTimeout(
                        () =>
                            hideComponent(
                                Components.ff_fixedBackground,
                                backgroundInstanceId,
                            ),
                        3000,
                    );
                } else if (
                    fixedBackgroundType === FixedBackgroundType.never &&
                    backgroundInstanceId
                ) {
                    // This generally shouldn't happen because operators shouldn't transition from an ff => lf component
                    hideComponent(
                        Components.ff_fixedBackground,
                        backgroundInstanceId,
                    );
                    show();
                } else {
                    show();
                }
            };
        }
    }

    return {
        title,
        disabled,
        optionsDisabled,
        active: isActive,
        componentState,
        onClick: handleClick,
        options,
        textInputs,
        customOnClickOption,
    };
};

export default withTruck(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)(ComponentButton),
);
