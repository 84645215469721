import { ReactNode, useCallback, useRef } from "react";
import Sortable from "sortablejs";

type Props = {
    onReorder: (oldIndex: number, newIndex: number) => void;
    children: ReactNode[];
};

export default function SortableComponent(props: Props) {
    const eventHandlerRef = useRef(props.onReorder);
    eventHandlerRef.current = props.onReorder;

    const sortableRef = useRef<Sortable>();
    const elementRef = useCallback((el: HTMLElement | null) => {
        if (sortableRef.current) {
            sortableRef.current.destroy();
            sortableRef.current = undefined;
        }

        if (el) {
            sortableRef.current = Sortable.create(el, {
                onUpdate: (event) => {
                    if (
                        event.oldIndex === undefined ||
                        event.newIndex === undefined
                    ) {
                        return;
                    }

                    eventHandlerRef.current(event.oldIndex, event.newIndex);
                },
            });
        }
    }, []);

    return <div ref={elementRef}>{props.children}</div>;
}
