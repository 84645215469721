import { useCallback, useEffect } from "react";

const useEffectAsync = (
    effect: () => Promise<(() => void) | void>,
    inputs: unknown[] = [],
): void => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    effect = useCallback(effect, inputs);

    useEffect(() => {
        let destroy: (() => void) | void = undefined;

        void effect().then((result) => {
            destroy = result;
        });

        return () => destroy && destroy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effect, ...inputs]);
};

export default useEffectAsync;
