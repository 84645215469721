import { useCallback, useState, useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";

import withTruck, { WithTruckProps } from "components/withTruck";
import SelectionBar from "components/SelectionBar";
import { IRouteToRaceParams, routeFactory } from "../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowCircleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { IGlobalState } from "state/store";
import { getTruckById } from "state/selectors";
import "./MeetingSelector.less";
import ComponentButton from "modules/atc/components/ComponentButton/ComponentButton";
import { Components } from "modules/triples/components/RaceControls/RaceControls";
import { useAppSelector } from "state/hooks";
import Checkbox from "components/Forms/Checkbox";
import FormButton from "components/Forms/FormButton";

interface IProps {
    venkmanName: string;
}

interface IModuleProps {
    numModules: number;
}

const mapStateToProps = (state: IGlobalState): IModuleProps => ({
    numModules: state.availableModules.length,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type MeetingSelectorProps = IModuleProps &
    IProps &
    WithTruckProps &
    PropsFromRedux;

function MeetingSelector(props: MeetingSelectorProps) {
    const params = useParams<IRouteToRaceParams>();

    const [filteredVenues, setFilteredVenues] = useState<string[]>(
        routeFactory.parseVenueList(params.venueList),
    );

    const { venues, dates, selectedDate, selectedEnvironment } = useAppSelector(
        (state) => state.tripleSModuleState,
    );

    const truck = useAppSelector((state) =>
        getTruckById(state, params.truckId!),
    );

    const getVenuesEnabledForEnvironment = useCallback(
        (environment: string | null) =>
            new Set(
                Object.values(
                    truck?.modules[params.moduleId!]?.components ?? {},
                )
                    .flatMap((c) => Object.values(c))
                    .filter(
                        (c) =>
                            !!c.componentContext["venueId"] &&
                            c.componentContext["date"] === selectedDate &&
                            c.componentContext["environment"] === environment,
                    )
                    .map((c) => c.componentContext["venueId"] as string),
            ),
        [truck, params, selectedDate],
    );

    const venuesEnabled = useMemo(
        () => getVenuesEnabledForEnvironment(selectedEnvironment),
        [getVenuesEnabledForEnvironment, selectedEnvironment],
    );

    useEffect(() => {
        setFilteredVenues([...venuesEnabled]);
        // We only want to run this useEffect once on page load
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const { numModules, venkmanName } = props;

    const handleDateSelected = useCallback(
        (date: string) => {
            setFilteredVenues([]);
            const pathname = routeFactory.hasMeetingDate({ ...params, date });
            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [setFilteredVenues, params, navigate],
    );

    const handleEnvironmentSelected = useCallback(
        (environment: string) => {
            setFilteredVenues([...getVenuesEnabledForEnvironment(environment)]);
            const pathname = routeFactory.hasEnvironment({
                ...params,
                environment,
            });
            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [setFilteredVenues, params, navigate, getVenuesEnabledForEnvironment],
    );

    const changeModule = useCallback(() => {
        setFilteredVenues([]);
        const pathname = routeFactory.toModuleSelector(params);

        if (pathname) {
            navigate(pathname, { replace: true });
        }
    }, [setFilteredVenues, params, navigate]);

    const orderedDates = orderDates(dates);

    const orderedEnvironments = selectedDate
        ? orderEnvironments([
              ...new Set(Object.values(venues).flatMap((v) => Object.keys(v))),
          ])
        : [];

    const venuesAtEnvironment = Object.entries(venues)
        .filter(([_, envsAtVenue]) =>
            Object.keys(envsAtVenue).includes(selectedEnvironment ?? ""),
        )
        .map(([venueId, _]) => venueId);

    const venueNames = Object.fromEntries(
        venuesAtEnvironment.map((venueId) => {
            const name = Object.values(venues[venueId]!)[0]?.venueName;
            return [venueId, name];
        }),
    );

    const onSelectRaces = useCallback(() => {
        const pathname = routeFactory.hasVenueList(filteredVenues, params);

        if (pathname) {
            navigate(pathname, { replace: true });
        }
    }, [filteredVenues, params, navigate]);

    return (
        <>
            <section className="sky-meeting-selector">
                {numModules > 1 && (
                    <div className="row">
                        <div className="back" onClick={changeModule}>
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                size="2x"
                            />
                        </div>
                    </div>
                )}
                <SelectionBar
                    title="Date"
                    options={orderedDates}
                    format={renderDate}
                    optionKey={(o) => o}
                    selectedOption={selectedDate ?? undefined}
                    onOptionSelected={handleDateSelected}
                    fallback="No meeting dates!"
                />

                <SelectionBar
                    title="TraCe Stream"
                    options={orderedEnvironments}
                    optionKey={(o) => o}
                    format={renderEnvironment}
                    selectedOption={selectedEnvironment ?? undefined}
                    onOptionSelected={handleEnvironmentSelected}
                    fallback={"Please select the date"}
                />

                {selectedDate && selectedEnvironment && (
                    <>
                        <div className="col venue-selector">
                            {venuesAtEnvironment.map((v) => (
                                <div
                                    key={`venue-filter-${v}`}
                                    className="venue-selector-and-visible"
                                >
                                    <Checkbox
                                        title={
                                            // Hack to get the venue name. The venue id
                                            // entry should definitely exist
                                            venueNames[v] ?? ""
                                        }
                                        checked={
                                            filteredVenues.findIndex(
                                                (vv) => v === vv,
                                            ) != -1
                                        }
                                        onChange={(checked) => {
                                            if (checked) {
                                                setFilteredVenues([
                                                    ...new Set(
                                                        filteredVenues,
                                                    ).add(v),
                                                ]);
                                            } else {
                                                const newFilteredVenues =
                                                    new Set(filteredVenues);
                                                newFilteredVenues.delete(v);
                                                setFilteredVenues([
                                                    ...newFilteredVenues,
                                                ]);
                                            }
                                        }}
                                    />
                                    {venuesEnabled.has(v) && (
                                        <FontAwesomeIcon icon={faEye} />
                                    )}
                                </div>
                            ))}
                        </div>
                        {filteredVenues.length > 0 && (
                            <section className="graphics-test-button">
                                <FormButton
                                    title="Select races"
                                    onClick={onSelectRaces}
                                />
                            </section>
                        )}
                    </>
                )}
            </section>
            <section className="graphics-test-button">
                <hr />
                <ComponentButton
                    title="Graphics Test"
                    componentType={Components.Watermark}
                    componentContext={{}}
                    visualContext={{
                        graphicsTest: true,
                        venkmanName,
                    }}
                />
            </section>
        </>
    );
}

function orderDates(dates: string[]) {
    return dates.slice().sort((a, b) => {
        return Date.parse(a) - Date.parse(b);
    });
}

const knownEnvironments: { [env: string]: number | undefined } = {
    Auto: 0,
    Main: 1,
    Backup: 2,
    Dev: 3,
};

// Always have Main < Backup < Dev. Then sort by alphabetical
function orderEnvironments(environments: string[]) {
    return environments.sort((a, b) => {
        const envA = knownEnvironments[a];
        const envB = knownEnvironments[b];

        if (envA === undefined && envB === undefined) {
            return a.localeCompare(b);
        } else if (envA === undefined) {
            return 1;
        } else if (envB === undefined) {
            return -1;
        } else {
            return envA - envB;
        }
    });
}

function renderDate(date: string) {
    const today = moment().startOf("d").format("YYYY-MM-DD");
    return (
        <div>
            {date === today && <FontAwesomeIcon icon={faCalendarAlt} />}
            {date}
        </div>
    );
}

function renderEnvironment(env: string) {
    return <div>{env}</div>;
}

export default connector(withTruck(MeetingSelector));
