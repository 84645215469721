import moment from "moment";
import { RaceWithNumber, RaceState } from "../../redux/redux";

interface IProps {
    environment: string;
    race: RaceWithNumber;
    raceState: RaceState;
}

const RaceDetails = ({ environment, race, raceState }: IProps) => {
    const raceStatusClassNames = raceState ? raceState.toLowerCase() : "";

    return (
        <>
            <div className="race-card">
                <h4>R{race.number}</h4>
                <h6>{moment.utc(race.scheduledTime).local().format("LT")}</h6>
            </div>

            <div className="race-details">
                <h4>{race.name}</h4>
                <h5>
                    {race.distanceMetres}m |&nbsp;
                    <span className={raceStatusClassNames}>
                        {raceState}
                    </span>{" "}
                    |&nbsp; TraCe Stream: {environment}
                </h5>
            </div>
        </>
    );
};

export default RaceDetails;
