type Guid = string;
export default Guid;

export const isNullOrEmpty = (guid: Guid): boolean => {
    if (!guid) {
        return true;
    }

    const naked = guid.replace(/[{}-]/g, "");
    return !/[^0]/.test(naked);
};

export const newGuid = (): Guid => {
    return [gen(2), gen(1), gen(1), gen(1), gen(3)].join("-");
};

function gen(count: number): string {
    let out = "";
    for (let i = 0; i < count; i++) {
        // tslint:disable-next-line:no-bitwise
        out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return out;
}
