import { connect } from "react-redux";
import { isEqual } from "lodash";
import cn from "classnames";
import withTruck, { WithTruckProps } from "components/withTruck";
import {
    hideComponent,
    showComponent,
} from "components/ModulePage/ModulePage.Redux";
import { IGlobalState } from "../../../../state/store";
import { Dispatch } from "redux";

import "./MultiRaceComponentButton.less";
import { ComponentState } from "resources/harmonyTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface IMergedProps {
    title: string;
    active: boolean;
    componentState: ComponentState | undefined;
    onClick: (() => void) | undefined;
}

function MultiRaceComponentButton(props: IMergedProps) {
    const { title, active, onClick } = props;

    const titleClassNames = cn("title", {
        active,
    });

    const visibilityClassNames = cn("visibility-icon");

    return (
        <div className="multi-component-button" onClick={onClick}>
            <div className={titleClassNames}>
                {props.componentState && (
                    <VisibilityIcon
                        className={visibilityClassNames}
                        state={props.componentState}
                    />
                )}
                <span>{title}</span>
            </div>
        </div>
    );
}

function VisibilityIcon(props: { className: string; state: ComponentState }) {
    return (
        <div className={props.className}>
            {props.state == ComponentState.Visible ? (
                <FontAwesomeIcon icon={faEye} size="xs" />
            ) : (
                <FontAwesomeIcon icon={faEyeSlash} size="xs" />
            )}
        </div>
    );
}

// ---------------------------------------------------------------------------------------------------------------------

interface IOwnProps<C> {
    title: string;
    componentType: string[];
    componentContext: C;
    races?: number[];
    raceKey?: string;
    venuesAndRaces?: { venueId: string; race: number }[];
    visualContext?: { [name: string]: unknown } | undefined;
}

interface IActiveComponentButtonProps {
    components: {
        id: string;
        instanceIds: string[];
    }[];

    isActive: boolean;

    componentState: ComponentState | undefined;
}

interface IDispatchProps {
    hideComponent: (componentType: string, componentInstanceId: string) => void;
    showComponent: (
        componentType: string,
        componentInstanceId: string | undefined,
    ) => void;
}

const mapStateToProps = <C,>(
    state: IGlobalState,
    props: WithTruckProps & IOwnProps<C>,
): WithTruckProps & IOwnProps<C> & IActiveComponentButtonProps => {
    const { componentContext, activeComponents, raceKey } = props;
    const componentType = new Set(
        (Array.isArray(props.componentType)
            ? props.componentType
            : [props.componentType]) ?? [],
    );

    const { title, visualContext } = props;
    const activeInstances =
        activeComponents?.filter(
            (c) =>
                componentType.has(c.componentId) &&
                ((props.races &&
                    isEqual(
                        // We don't care about the race number here
                        {
                            ...c.componentContext,
                            [raceKey ?? "race"]: undefined,
                        },
                        { ...componentContext, [raceKey ?? "race"]: undefined },
                    )) ||
                    (props.venuesAndRaces &&
                        isEqual(
                            // We don't care about the race number and venue id here
                            {
                                ...c.componentContext,
                                [raceKey ?? "race"]: undefined,
                                venueId: undefined,
                            },
                            {
                                ...componentContext,
                                [raceKey ?? "race"]: undefined,
                                venueId: undefined,
                            },
                        ))),
        ) ?? [];
    const activeInstance =
        (activeInstances.length == 1 || undefined) && activeInstances[0];

    const components = Array.from(componentType).map((c) => ({
        id: c,
        instanceIds: activeInstances
            .filter((i) => i.componentId == c)
            .map((c) => c.componentInstanceId),
    }));

    return {
        ...props,
        title,
        visualContext,
        isActive: activeInstances?.length > 0,
        components,
        componentState: activeInstance?.state,
    };
};

const mapDispatchToProps = <C,>(
    dispatch: Dispatch,
    props: WithTruckProps & IOwnProps<C>,
): IDispatchProps => {
    const {
        truckId,
        moduleId,
        componentContext,
        races,
        venuesAndRaces,
        visualContext,
        raceKey,
    } = props;

    return {
        hideComponent: (componentType, componentInstanceId) =>
            truckId &&
            moduleId &&
            hideComponent(
                truckId,
                moduleId,
                componentType,
                componentInstanceId,
            )(dispatch),

        showComponent: (componentType, componentInstanceId) => {
            if (truckId && moduleId) {
                if (races) {
                    races.forEach((raceNumber) => {
                        showComponent(
                            truckId,
                            moduleId,
                            componentType,
                            {
                                ...componentContext,
                                [raceKey ?? "race"]: raceNumber,
                            },
                            visualContext,
                            componentInstanceId,
                        )(dispatch);
                    });
                } else if (venuesAndRaces) {
                    venuesAndRaces.forEach((vr) => {
                        showComponent(
                            truckId,
                            moduleId,
                            componentType,
                            { ...componentContext, ...vr },
                            visualContext,
                            componentInstanceId,
                        )(dispatch);
                    });
                }
            }
        },
    };
};

const mergeProps = <C,>(
    stateProps: WithTruckProps & IOwnProps<C> & IActiveComponentButtonProps,
    dispatchProps: IDispatchProps,
): IMergedProps => {
    const { title, isActive, componentState, components } = stateProps;
    const { showComponent, hideComponent } = dispatchProps;

    const handleClick = () => {
        if (isActive) {
            components.forEach((c) =>
                c.instanceIds.forEach((id) => hideComponent(c.id, id)),
            );
        } else {
            components.forEach((c) =>
                c.instanceIds.length == 0
                    ? showComponent(c.id, undefined)
                    : c.instanceIds.forEach((id) => showComponent(c.id, id)),
            );
        }
    };

    return {
        title,
        active: isActive,
        componentState,
        onClick: handleClick,
    };
};

export default withTruck(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps,
    )(MultiRaceComponentButton),
);
