import React from "react";
import { UserModel } from "../repository";

interface UsersListProps {
    users: UserModel[];
}

export default class UsersList extends React.Component<UsersListProps> {
    render() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <td>Username</td>
                        <td>Tenant</td>
                    </tr>
                </thead>
                <tbody>
                    {this.props.users.map((u) => this.renderUserRow(u))}
                </tbody>
            </table>
        );
    }

    private renderUserRow(user: UserModel) {
        return (
            <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.tenant}</td>
            </tr>
        );
    }
}
