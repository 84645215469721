import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
    to: string;
    className?: string;
    children: React.ReactNode;
}

export default function UnstyledLink(props: IProps) {
    const { to } = props;
    const navigate = useNavigate();
    const handleClick = useCallback(() => {
        navigate(to);
    }, [navigate, to]);

    return (
        <div className={props.className} onClick={handleClick}>
            {props.children}
        </div>
    );
}
