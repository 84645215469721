import React from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { IGlobalState } from "../../../../state/store";
import { IPlacing } from "../../types";

import "./styles.less";
import JockeyTrainerCell from "./JockeyTrainerCell";
import {
    getDeadHeatPlace,
    getPlacingsForSelectedRace,
} from "../../redux/Module.Selectors";

interface IProps {
    isInterim: boolean;
    isFinal: boolean;
    placings: IPlacing[];
    deadHeatPlace: string | null;
}

const ResultsTableRow = (
    props: IPlacing & { rowIndex: number },
): JSX.Element => {
    const { placeNumber, runner, winAmount, placeAmount, rowIndex } = props;

    const rowClasses = cn({
        alt: rowIndex % 2 === 1,
    });

    if (!(runner && placeNumber)) {
        return <tr className={rowClasses} />;
    }

    return (
        <tr key={runner.number} className={rowClasses}>
            <td className="horse-number-cell">{runner.number}</td>
            <td className="silk-cell">
                <img src={runner.silk} />
            </td>
            <td className="name-cell">
                {runner.name} [{runner.barrierNumber}]
            </td>
            <JockeyTrainerCell runner={runner} />
            <td className="price-cell">{winAmount}</td>
            <td className="price-cell">{placeAmount}</td>
        </tr>
    );
};

const ResultsTablePhotoRow = (
    props: IPlacing & { rowIndex: number },
): JSX.Element => {
    const { rowIndex } = props;
    const rowClasses = cn({ alt: rowIndex % 2 === 1 });

    return (
        <tr className={rowClasses}>
            <td className="horse-number-cell">P</td>
            <td className="silk-cell" />
            <td colSpan={2} className="photo-finish-cell">
                Photo Finish
            </td>
            <td className="price-cell" />
            <td className="price-cell" />
        </tr>
    );
};

const ResultsTable = (props: IProps) => {
    const { placings } = props;
    if (!(placings && placings.length)) {
        return null;
    }

    const placingWithRunners = placings.filter(
        (placing) => placing.runner || placing.photo,
    );
    if (!placingWithRunners.length) {
        return null;
    }

    const resultType = props.isFinal
        ? "Final Results"
        : props.isInterim
        ? "Interim Results"
        : "Manual Results";

    const resultTypeClassName = cn("text-center", {
        final: props.isFinal,
        interim: props.isInterim,
    });

    return (
        <table className="table runners-table">
            <thead>
                <tr>
                    <th colSpan={6} className={resultTypeClassName}>
                        {resultType}
                    </th>
                </tr>
                {props.deadHeatPlace && (
                    <tr className="dead-heat">
                        <th colSpan={6} className="text-center">
                            Dead heat for {props.deadHeatPlace} place
                        </th>
                    </tr>
                )}
            </thead>
            <tbody>
                {placingWithRunners.map((placing, rowIndex) => (
                    <React.Fragment
                        key={`${placing.placeNumber}_${
                            (placing.photo && "P") ||
                            (placing.runner && placing.runner.number) ||
                            "_"
                        }`}
                    >
                        {placing.runner && (
                            <ResultsTableRow {...placing} rowIndex={rowIndex} />
                        )}

                        {placing.photo && (
                            <ResultsTablePhotoRow
                                {...placing}
                                rowIndex={rowIndex}
                            />
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    );
};

const mapStateToProps = (state: IGlobalState): IProps => {
    const {
        moduleState: { dividends },
    } = state;

    const hasDividends = !!dividends;

    const isInterim = hasDividends && !!dividends?.isInterim;
    const isFinal = hasDividends && !isInterim;
    const placings = getPlacingsForSelectedRace(state);
    const deadHeatPlace = getDeadHeatPlace(state);

    return {
        isInterim,
        isFinal,
        placings,
        deadHeatPlace,
    };
};

export default connect(mapStateToProps)(ResultsTable);
