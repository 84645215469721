import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import { faChrome } from "@fortawesome/free-brands-svg-icons";

import cn from "classnames";
import { CompositorDetails as ICompositorDetails } from "resources/harmonyTypes";

import { escapeRouteValue } from "core/Routing";

import "./style.less";
import UnstyledLink from "../../../Routing/UnstyledLink";
import { routeFactory } from "components/StandardLayout/Routes";

interface ICompositorDetailsProps {
    compositor: ICompositorDetails;
    tenantId: string;
}

const CompositorIcon = ({
    compositorKey,
}: {
    compositorKey: string;
}): JSX.Element => {
    const isBrowser =
        !compositorKey ||
        compositorKey.toLocaleLowerCase().indexOf("browser") >= 0;

    const icon = isBrowser ? faChrome : faTv;

    return (
        <FontAwesomeIcon icon={icon} size="6x" className="compositor-icon" />
    );
};

const CompositorDetails = (props: ICompositorDetailsProps) => {
    const { compositor, tenantId } = props;
    const { compositorKey, trucks } = compositor;

    const truckIds = useMemo(
        () => (trucks && Object.keys(trucks)) || [],
        [trucks],
    );
    const truckCount = truckIds.length;

    if (!compositor.venkman) {
        console.warn("Some Dodgy Data: ", compositor);
        return null;
    }

    const route = routeFactory.toTruckOpenScreen({
        tenantId,
        compositorId: escapeRouteValue(compositor.compositorKey),
    });
    const classNames = cn("compositor", { "has-truck": truckCount });

    return (
        <UnstyledLink to={route} className={classNames}>
            <h1>{compositor.name}</h1>

            <div className="compositor-details">
                <CompositorIcon compositorKey={compositorKey} />

                <div className="compositor-description">
                    <div>{compositor.description}</div>
                    <div>
                        {compositor.venkman.name} -{" "}
                        <em>{compositor.venkman.description}</em>
                    </div>

                    {truckCount > 0 && (
                        <>
                            <br />
                            <div>Active truck sessions: {truckCount}</div>
                        </>
                    )}
                </div>
            </div>
        </UnstyledLink>
    );
};

export default CompositorDetails;
