import memoize from "memoize-one";
import {
    DividendType,
    IPlacing,
    IRaceDetails,
    ManualPositionType,
    MeetIdentity,
} from "../types";
import * as PlaceAmountCalculator from "../PlaceAmountCalculator";
import { IGlobalState } from "../../../state/store";
import { IRootState } from "./Module.Redux";

/**
 * Builds a meet identity for the the provided selected date/state/venue
 */
export const getMeetIdentity = (
    moduleState: IRootState,
): ((
    date: string,
    state: string,
    eventType: string,
    venue: string,
) => MeetIdentity | null) => {
    if (!moduleState) {
        return () => null;
    }

    const { meets } = moduleState;

    return (
        date: string,
        state: string,
        eventType: string,
        venue: string,
    ): MeetIdentity | null => {
        const racesByVenue = meets[date];
        const statesByVenue = racesByVenue?.[venue];

        return statesByVenue?.[state]
            ? { date, state, eventType, venue }
            : null;
    };
};

function asMoney<T>(value: T) {
    if (typeof value == "number") {
        return value.toFixed(2);
    }

    return value;
}

const buildPlacingsFromDividends = memoize(
    (selectedRace: IRaceDetails, dividends: DividendType): IPlacing[] => {
        return dividends.places.map((place) => {
            const calculatePlaceAmount =
                PlaceAmountCalculator.calculatePlaceAmount(
                    selectedRace.numberOfPlaces,
                );
            const runner = selectedRace.runners.find(
                (r) => r.number === place.horseNumber,
            );

            return {
                placeNumber: place.place,
                runner,
                winAmount: place.place === 1 ? asMoney(place.winAmount) : "",
                placeAmount: calculatePlaceAmount(
                    place.place,
                    asMoney(place.placeAmount),
                ),
                photo: false,
            };
        });
    },
);

const buildPlacingsFromManualPlacings = memoize(
    (
        selectedRace: IRaceDetails,
        manualPlacings: ManualPositionType[],
    ): IPlacing[] => {
        const calculatePlaceAmount = PlaceAmountCalculator.calculatePlaceAmount(
            selectedRace.numberOfPlaces,
        );

        return (
            (manualPlacings &&
                manualPlacings.map((horseNumber, index) => {
                    const placeNumber = index + 1;

                    const runner =
                        horseNumber !== "P"
                            ? selectedRace.runners.find(
                                  (r) => r.number === horseNumber,
                              )
                            : undefined;
                    if (!runner) {
                        return {
                            placeNumber,
                            runner,
                            winAmount: "",
                            placeAmount: "",
                            photo: horseNumber === "P",
                        };
                    }

                    const { winAmount, placeAmount } = runner;

                    return {
                        placeNumber,
                        runner,
                        winAmount: (placeNumber === 1 && winAmount) || "",
                        placeAmount: calculatePlaceAmount(
                            placeNumber,
                            placeAmount,
                        ),
                        photo: false,
                    };
                })) ||
            []
        );
    },
);

/**
 * Gets the placings from the dividends, or from the manual positions as a fallback.
 */
export const getPlacingsForSelectedRace = (state: IGlobalState): IPlacing[] => {
    const { selectedRace, dividends, manualPlacings } = state.moduleState;
    if (!selectedRace) {
        return [];
    }

    if (dividends?.places) {
        return buildPlacingsFromDividends(selectedRace, dividends);
    }

    const placings = manualPlacings[selectedRace.raceNumber];
    if (placings) {
        return buildPlacingsFromManualPlacings(selectedRace, placings);
    }

    return [];
};

const getPlaceName = (placeNumber: number): string => {
    switch (placeNumber) {
        case 1:
            return "1st";
        case 2:
            return "2nd";
        case 3:
            return "3rd";
        default:
            return `${placeNumber}th`;
    }
};

/**
 * Gets the first position (e.g. '1st', '2nd') that had a dead heat, or `null` if there were no dead heats.
 */
export const getDeadHeatPlace = (state: IGlobalState): string | null => {
    const { dividends } = state.moduleState;
    if (!(dividends && dividends.places && dividends.places.length)) {
        return null;
    }

    const { places } = dividends;
    for (let i = 1; i < places.length; i++) {
        const prevPlace = places[i - 1]!;
        const thisPlace = places[i]!;

        if (prevPlace.place === thisPlace.place) {
            return getPlaceName(i);
        }
    }

    return null;
};
