import {
    useCallback,
    useLayoutEffect,
    useRef,
    ReactNode,
    SyntheticEvent,
} from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./Modal.less";

type Props = {
    open: boolean;
    onClose: () => void;
    children?: ReactNode[] | ReactNode;
    className?: string;
};

export default function Modal(props: Props) {
    const dialog = useRef<HTMLDialogElement | null>(null);

    useLayoutEffect(() => {
        if (!dialog.current) {
            return;
        }

        if (props.open && !dialog.current.open) {
            dialog.current.showModal();
        } else if (!props.open && dialog.current.open) {
            dialog.current.close();
        }
    }, [props.open]);

    const { onClose } = props;
    const handleCancel = useCallback(
        (event: SyntheticEvent<HTMLDialogElement>) => {
            if (event.target == event.currentTarget) {
                event.preventDefault();
                onClose();
            }
        },
        [onClose],
    );

    return (
        <dialog
            ref={dialog}
            onCancel={handleCancel}
            className={cn("modal", props.className)}
        >
            <button className="modal-close-icon" onClick={props.onClose}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            {props.children}
        </dialog>
    );
}
