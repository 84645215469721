import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Components,
    FixedBackgroundType,
    HorseType,
    IRaceDetails,
    IRenderComponentContext,
    RaceIdentity,
} from "../../types";
import { IGlobalState } from "../../../../state/store";
import {
    setCommentatorPickTitle,
    setSelectedHorse,
    setTrainerName,
    toggleFixedBackground,
    toggleMarketOrder,
    toggleScrollTotes,
    toggleShowOnlyWinPrice,
} from "../../redux/Module.Redux";
import ComponentButton from "../ComponentButton/ComponentButton";
import FormButton from "../../../../components/Forms/FormButton";
import VenueImageDialog from "../VenueImages/VenueImageDialog";

interface IOwnProps {
    raceIdentity: RaceIdentity;
}

interface IPropsFromState {
    commentatorPickTitle: string;
    race: IRaceDetails | null;
    selectedHorse: HorseType | undefined;
    scrollTotes: boolean;
    trainerName: string;
    showOnlyWinPrice: boolean;
    useFixedBackground: boolean;
    useMarketOrder: boolean;
}

interface IPropsFromDispatch {
    onHorseSelected: (horse: HorseType) => void;
    onToggleScrollTotes: (scrollTotes: boolean) => void;
    onToggleFixedBackground: (useFixedBackground: boolean) => void;
    onToggleMarketOrder: (useMarketOrder: boolean) => void;
    onToggleShowOnlyWinPrice: (showOnlyWinPrice: boolean) => void;
    setCommentatorPickTitle: (title: string) => void;
    setTrainerName: (title: string) => void;
}

const FullFrameTotesButton = (
    props: IOwnProps & IPropsFromState & IPropsFromDispatch,
): JSX.Element => {
    const { scrollTotes: autoScroll, showOnlyWinPrice, useMarketOrder } = props;
    const visualContext = { autoScroll, showOnlyWinPrice, useMarketOrder };

    const options = [
        {
            title: "Win Price Only",
            isSelected: props.showOnlyWinPrice,
            onSelected: props.onToggleShowOnlyWinPrice,
        },
        {
            title: "Auto Scroll",
            isSelected: props.scrollTotes,
            onSelected: props.onToggleScrollTotes,
        },
        {
            title: "Market Order",
            isSelected: props.useMarketOrder,
            onSelected: props.onToggleMarketOrder,
        },
        {
            title: "Fix Background",
            isSelected: props.useFixedBackground,
            onSelected: props.onToggleFixedBackground,
        },
    ];

    return (
        <ComponentButton
            title="FF Totes"
            componentType={Components.ff_Totes}
            componentContext={props.raceIdentity}
            visualContext={visualContext}
            fixedBackgroundType={FixedBackgroundType.required}
            options={options}
            hideOthers
        />
    );
};

// TODO: We should really be getting these from Harmony instead of hardcoded here
const COMMENTATORS = ["Ally", "Chantelle", "Corey"];

const PickButton = (
    props: IOwnProps & IPropsFromState & IPropsFromDispatch,
): JSX.Element => {
    const {
        commentatorPickTitle,
        raceIdentity,
        selectedHorse,
        setCommentatorPickTitle,
    } = props;

    const visualContext = { title: commentatorPickTitle };
    const horseNumber = selectedHorse && selectedHorse.number;
    const textInputs = [
        {
            label: "Pick Title",
            value: commentatorPickTitle,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setCommentatorPickTitle(e.target.value),
        },
    ];

    const options = COMMENTATORS.map((name) => ({
        title: name,
        isSelected: commentatorPickTitle.includes(name),
        onSelected: () => {
            const selectedName = commentatorPickTitle.includes(name)
                ? commentatorPickTitle
                : `${name}'s Pick of the Yard`;
            setCommentatorPickTitle(selectedName);
        },
    }));

    return (
        <ComponentButton
            title="Yard Pick"
            componentType={Components.lf_commentatorPick}
            componentContext={{ ...raceIdentity, horseNumber }}
            disabled={!horseNumber}
            visualContext={visualContext}
            textInputs={textInputs}
            options={options}
            hideOthers
        />
    );
};

const TrainerButton = (
    props: IOwnProps & IPropsFromState & IPropsFromDispatch,
): JSX.Element => {
    const { raceIdentity, selectedHorse, setTrainerName, trainerName } = props;
    const horseNumber = selectedHorse && selectedHorse.number;

    const textInputs = [
        {
            label: "Custom Name",
            value: trainerName,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setTrainerName(e.target.value),
        },
    ];

    const options = selectedHorse?.individualTrainers.map(({ name }) => ({
        title: name,
        isSelected: name === trainerName,
        onSelected: () => {
            const selectedName =
                trainerName === name ? selectedHorse.trainer : name;
            setTrainerName(selectedName);
        },
    }));

    return (
        <ComponentButton
            title="Trainer"
            componentType={Components.lf_silkAndName}
            componentContext={{ ...raceIdentity, horseNumber, type: "trainer" }}
            disabled={!horseNumber}
            visualContext={{
                personTitle: "Trainer",
                customTrainerName: trainerName,
            }}
            textInputs={textInputs}
            options={options}
            hideOthers
        />
    );
};

const ImagesDialogButton = (props: { venueCode: string }) => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <FormButton title="Images" onClick={() => setShowDialog(true)} />

            {showDialog && (
                <VenueImageDialog
                    venueCode={props.venueCode}
                    onClose={() => setShowDialog(false)}
                />
            )}
        </>
    );
};

class PreRaceControls extends React.PureComponent<
    IOwnProps & IPropsFromState & IPropsFromDispatch
> {
    public render() {
        const { raceIdentity, race, selectedHorse } = this.props;
        if (!race) {
            return null;
        }

        const horseNumber = selectedHorse && selectedHorse.number;
        const { ...meetIdentity } = raceIdentity;

        return (
            <React.Fragment>
                <FullFrameTotesButton
                    {...this.props}
                    raceIdentity={raceIdentity}
                />

                <ComponentButton
                    title="Left Totes"
                    componentType={Components.lf_topHorses}
                    componentContext={raceIdentity}
                    hideOthers
                />

                <ComponentButton
                    title="Mounting Yard"
                    componentType={Components.lf_yardPage}
                    componentContext={{ ...raceIdentity, horseNumber }}
                    disabled={!horseNumber}
                    onComponentHide={this.handleMountingYardHide}
                />

                <TrainerButton {...this.props} />

                <ComponentButton
                    title="Jockey"
                    componentType={Components.lf_silkAndName}
                    componentContext={{
                        ...raceIdentity,
                        horseNumber,
                        type: "jockey",
                    }}
                    disabled={!horseNumber}
                    visualContext={{ personTitle: "Jockey" }}
                    hideOthers
                />

                <ComponentButton
                    title="Late Scratching"
                    componentType={Components.lf_lateScratching}
                    componentContext={{ ...raceIdentity, horseNumber }}
                    disabled={!horseNumber}
                    hideOthers
                />

                <ComponentButton
                    title="Race Title"
                    componentType={Components.raceTitle}
                    componentContext={raceIdentity}
                    hideOthers
                />

                <PickButton {...this.props} />

                <div className="spacer" />

                <ComponentButton
                    title="Sponsor Loop"
                    componentType={Components.ff_sponsorCycle}
                    componentContext={meetIdentity}
                    visualContext={{
                        type: "slides",
                    }}
                    hideOthers
                />

                <ImagesDialogButton venueCode={meetIdentity.venue} />
            </React.Fragment>
        );
    }

    private handleMountingYardHide = (
        // TODO fix type
        componentContext: unknown,
    ): void => {
        const { horseNumber } = componentContext as IRenderComponentContext;
        const { race, onHorseSelected } = this.props;

        const nextRunner = race?.runners.find(
            (runner) =>
                !runner.isScratched &&
                horseNumber &&
                runner.number > horseNumber,
        );
        if (nextRunner) {
            onHorseSelected(nextRunner);
        }
    };
}

const mapStateToProps = (
    state: IGlobalState,
    ownProps: IOwnProps,
): IPropsFromState => {
    const {
        commentatorPickTitle,
        selectedRace,
        selectedHorse,
        scrollTotes,
        trainerName,
        showOnlyWinPrice,
        useFixedBackground,
        useMarketOrder,
    } = state.moduleState;

    return {
        ...ownProps,
        commentatorPickTitle,
        race: selectedRace,
        selectedHorse: selectedHorse ?? undefined,
        scrollTotes,
        trainerName,
        showOnlyWinPrice,
        useFixedBackground,
        useMarketOrder,
    };
};

export default connect(mapStateToProps, {
    onHorseSelected: setSelectedHorse,
    onToggleFixedBackground: toggleFixedBackground,
    onToggleScrollTotes: toggleScrollTotes,
    onToggleMarketOrder: toggleMarketOrder,
    onToggleShowOnlyWinPrice: toggleShowOnlyWinPrice,
    setCommentatorPickTitle,
    setTrainerName,
})(PreRaceControls);
