import { useCallback, useEffect, useState } from "react";

import { IRaceDetails, ISponsorLogo, RaceIdentity } from "../../types";
import BusySpinner from "components/BusySpinner";
import FormButton from "components/Forms/FormButton";
import Modal from "components/Modal";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import RaceDetails from "../RaceTitleBar/RaceDetails";
import SponsorLogoTable from "./SponsorLogoTable";
import SponsorLogoUploader, {
    SponsorLogoEditor,
} from "../AssetTypes/SponsorLogo/SponsorLogoUploader";
import { useTruck } from "components/withTruck";

import "./styles.less";

type Props = {
    open: boolean;
    onClose: () => void;

    raceIdentity: RaceIdentity;
    race: IRaceDetails;
};

export default function RaceSponsorDialog(props: Props) {
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [sponsors, setSponsors] = useState<ISponsorLogo[] | undefined>(
        undefined,
    );
    const [selectedSponsor, setSelectedSponsor] = useState<string | undefined>(
        undefined,
    );
    const truck = useTruck();
    const moduleId = truck.moduleId ?? "";

    const executeSaveAction = async (saveAction: () => Promise<boolean>) => {
        setSaveError(null);
        setIsSaving(true);

        try {
            const preventClose = await saveAction();
            setIsSaving(false);

            if (!preventClose) {
                props.onClose();
            }
        } catch (err) {
            setIsSaving(false);
            const errorText =
                (typeof err === "string" && err) ||
                "An error has occurred, and your action could not been completed.";

            setSaveError(errorText);
        }
    };

    const setRaceSponsor = useCallback(
        (race: RaceIdentity, sponsor: string) => {
            return harmony.post<boolean>(moduleId, "SetRaceSponsor", {
                race,
                sponsor,
            });
        },
        [moduleId],
    );

    useEffect(() => {
        // Load sponsors if they haven't been loaded already
        if (!sponsors) {
            void (async () => {
                const sponsors = await harmony.get<ISponsorLogo[]>(
                    moduleId,
                    "LoadSponsorList",
                );

                sponsors.sort((left, right) =>
                    left.name
                        .toLocaleLowerCase()
                        .localeCompare(right.name.toLocaleLowerCase()),
                );

                setSponsors(sponsors);

                // Select the correct sponsor for this race
                if (props.race.logo) {
                    const matchingLogo = sponsors.find(
                        (sponsor) => sponsor.small === props.race.logo,
                    );

                    setSelectedSponsor((s) => s ?? matchingLogo?.id);
                }
            })();
        }
    }, [sponsors, props.race.logo, moduleId]);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const handleSelectSponsor = () => {
        if (selectedSponsor !== undefined) {
            void executeSaveAction(() =>
                setRaceSponsor(props.raceIdentity, selectedSponsor),
            );
        }
    };

    const handleClearSponsor = () => {
        void executeSaveAction(() => setRaceSponsor(props.raceIdentity, ""));
    };

    const handleEditSponsor = () => {
        if (selectedSponsor !== undefined) {
            setEditDialogOpen(true);
        }
    };

    const isLoading = !sponsors;

    const currentSelectedSponsor = selectedSponsor
        ? sponsors?.find((sponsor) => sponsor.id === selectedSponsor)
        : undefined;

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            className="sponsor-logo-dialog"
        >
            <Modal
                open={uploadDialogOpen}
                onClose={() => setUploadDialogOpen(false)}
                className="upload-dialog"
            >
                <SponsorLogoUploader
                    onFinish={(sponsorId) => {
                        setUploadDialogOpen(false);
                        setSelectedSponsor(sponsorId);
                        // reset to reload the sponsor list
                        setSponsors(undefined);
                    }}
                    moduleId={moduleId}
                />
            </Modal>
            <Modal
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                className="upload-dialog"
            >
                {currentSelectedSponsor && (
                    <SponsorLogoEditor
                        onFinish={() => {
                            setEditDialogOpen(false);
                            // reset to reload the sponsor list
                            setSponsors(undefined);
                            if (
                                currentSelectedSponsor.small === props.race.logo
                            ) {
                                // Invokes a dashboard update on the backend
                                // even if the id will be the same. Don't wrap
                                // in executeSaveAction since we don't want to
                                // close the dialog.
                                void setRaceSponsor(
                                    props.raceIdentity,
                                    currentSelectedSponsor.id,
                                );
                            }
                        }}
                        logo={currentSelectedSponsor}
                        moduleId={moduleId}
                        key={currentSelectedSponsor.id}
                    />
                )}
            </Modal>
            <section className="race-title-bar">
                <RaceDetails
                    raceIdentity={props.raceIdentity}
                    race={props.race}
                />
            </section>

            {saveError && (
                <section className="error-banner">{saveError}</section>
            )}

            <div className="busy-spinner-host">
                <SponsorLogoTable
                    sponsors={sponsors ?? []}
                    selectedSponsorId={selectedSponsor}
                    onSponsorSelected={setSelectedSponsor}
                />

                <BusySpinner isBusy={isLoading || isSaving} />

                <div className="button-bar pure-g">
                    <div className="pure-u-20-24">
                        {props.race.logo && (
                            <FormButton
                                title="Clear sponsor"
                                onClick={handleClearSponsor}
                            />
                        )}
                        <FormButton
                            title="Edit sponsor"
                            disabled={selectedSponsor === null}
                            onClick={handleEditSponsor}
                        />
                        <FormButton
                            title="Use this sponsor"
                            disabled={selectedSponsor === null}
                            onClick={handleSelectSponsor}
                        />
                        <FormButton
                            title="Upload new sponsor"
                            onClick={() => setUploadDialogOpen(true)}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}
