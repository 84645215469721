import { ElementType, ReactElement, ReactNode, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Modal from "components/Modal";

import "./AssetList.less";

type BaseProps = {
    children?: ReactElement | ReactElement[];
};

type BaseUploaderProps<T> = {
    onUpload?: (asset: T) => void;
};

type UploadFormProps<T> = {
    onFinish: (asset: T) => void;
};

type ComponentUploaderProps<T> = {
    uploadForm: ElementType<UploadFormProps<T>>;
};

type RenderUploaderProps<T> = {
    renderUploadForm: (props: { onFinish: (asset: T) => void }) => ReactNode;
};

type UploaderProps<T> = BaseUploaderProps<T> &
    (ComponentUploaderProps<T> | RenderUploaderProps<T>);

type PropsWithUploader<T> = BaseProps & UploaderProps<T>;

type Props<T> = BaseProps | PropsWithUploader<T>;

export default function AssetList<T>(props: Props<T>) {
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    let uploadDialog = null;
    if ("uploadForm" in props || "renderUploadForm" in props) {
        const handleUpload = (asset: T) => {
            setUploadDialogOpen(false);
            if (props.onUpload !== undefined) {
                props.onUpload(asset);
            }
        };

        const renderUploadForm =
            "uploadForm" in props
                ? (p: UploadFormProps<T>) => <props.uploadForm {...p} />
                : props.renderUploadForm;

        uploadDialog = (
            <>
                <button
                    className="upload-button"
                    onClick={() => setUploadDialogOpen(true)}
                >
                    <FontAwesomeIcon icon={faPlus} /> Upload
                </button>
                <Modal
                    open={uploadDialogOpen}
                    onClose={() => setUploadDialogOpen(false)}
                    className="upload-dialog"
                >
                    {renderUploadForm({ onFinish: handleUpload })}
                </Modal>
            </>
        );
    }

    return (
        <>
            {uploadDialog}
            <div className="asset-list">{props.children}</div>
        </>
    );
}
