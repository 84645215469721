import { useLayoutEffect, useRef, useState } from "react";
import calculateDropTargetWidthAndHeight from "./calculateDropTargetWidthAndHeight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

interface IProps {
    image: File;
    height: number;
    imageRatio?: [number, number] | undefined;
    extraImageStyles?: React.CSSProperties | undefined;
    alt?: string;

    onImageDelete?: () => void;
}

export default function ImagePreview({
    image,
    imageRatio,
    extraImageStyles,
    height,
    alt,
    onImageDelete,
}: IProps) {
    const [isHovering, setIsHovering] = useState(false);
    const imageUrl = useRef<string | undefined>();
    const [imageType, setImageType] = useState<string>("unknown");

    useLayoutEffect(() => {
        imageUrl.current = URL.createObjectURL(image);
        if (image.type.includes("image")) {
            setImageType("image");
        } else if (image.type.includes("video")) {
            setImageType("video");
        }
        return () => {
            if (imageUrl.current) {
                URL.revokeObjectURL(imageUrl.current);
            }
        };
    }, [image]);

    let imageStyle: React.CSSProperties | undefined = undefined;
    if (!imageUrl.current || imageRatio) {
        imageStyle = calculateDropTargetWidthAndHeight(height, imageRatio);
    }

    return (
        <div
            className="image-preview"
            style={imageStyle}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            {imageType == "image" ? (
                <img
                    src={imageUrl.current}
                    style={extraImageStyles}
                    alt={alt || image.name || "Preview"}
                />
            ) : imageType == "video" ? (
                <video
                    src={imageUrl.current}
                    style={extraImageStyles}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                />
            ) : null}

            <div className="image-preview-controls">
                {isHovering && onImageDelete && (
                    <div className="icon" onClick={onImageDelete}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                )}
            </div>
        </div>
    );
}
