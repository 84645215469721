import React, { useContext, useMemo } from "react";
import { TimeContext } from "../../Time/TimeContext";

const zeroPad = (value: number): string =>
    value < 10 ? `0${value}` : value.toString();

const Clock = () => {
    const { now } = useContext(TimeContext);

    const hour = now.getHours();
    const min = now.getMinutes();

    const time = useMemo(() => {
        const amPm = hour >= 12 ? "PM" : "AM";
        return `${hour}:${zeroPad(min)} ${amPm}`;
    }, [hour, min]);

    return <div className="text-center clock">{time}</div>;
};

export default Clock;
