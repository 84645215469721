import { useCallback } from "react";
import { CompositorDetails as CompositorDetailsType } from "resources/harmonyTypes";
import { onSwitchTenant } from "state/Auth";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { routeFactory } from "components/StandardLayout/Routes";
import CompositorDetails from "./CompositorDetails/CompositorDetails";
import TenantDropDownMenu from "../TenantDropDownMenu";

import "./style.less";

/*
 * {"localbrowser":{"compositorKey":"localbrowser",
 * "description":"Compositor will start up in browser on the server where this is running.",
 * "name":"Truck A (localbrowser)","venkman":{"id":"d8fe26fa1cd5aeba87ddc7afc17f434ec8f0f657430bf15db48843816ec588f3",
 * "name":"MyName","description":"I Am Venkmann!"},
 * "state":"in-use","trucks":{"4592309355877085":{"state":2,"modules":{"SDKOUJKSDJSKZ":{"enabled":false,"data":{"horses":[{"horse":"SAVATIANO","number":1,"jockey":"B.AVDULLA","trainer":"JAMES CUMMINGS"},{"horse":"BOOROOJ","number":2,"jockey":"J.BOWMAN","trainer":"PETER & PAUL SNOWDEN"},{"horse":"FAITH IN HAND","number":3,"jockey":"K.MC EVOY","trainer":"CHRIS WALLER"},{"horse":"ANOTHER SHADOW","number":4,"jockey":"T.ANGLAND","trainer":"GARRY WHITE"},{"horse":"CAPRICE","number":5,"jockey":"J.R COLLETT","trainer":"JOHN P THOMPSON"},{"horse":"DISCREET CHARM","number":6,"jockey":"B.SHINN","trainer":"JOHN P THOMPSON"},{"horse":"EMPRESS MATILDA","number":7,"jockey":"C.BROWN","trainer":"MICHAEL, WAYNE & JOHN HAWKES"},{"horse":"ALL SASSITUDE","number":8,"jockey":"T.CLARK","trainer":"JOHN P THOMPSON"},{"horse":"BALLET BABY","number":9,"jockey":"G.SCHOFIELD","trainer":"CHRIS WALLER"},{"horse":"MADONNICA","number":10,"jockey":"R.KING","trainer":"GAI WATERHOUSE & ADRIAN BOTT"}],"race":{"name":"ACT","location":"WFM"}}}}}}}}
 * */

function CompositorDetailsComparer(
    a: CompositorDetailsType,
    b: CompositorDetailsType,
) {
    if (!a.name) return 1;
    else if (!b.name) return -1;
    return a.name.localeCompare(b.name);
}

export default function AvailableCompositorPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleSwitchTenant = useCallback(
        (t: string) => {
            const pathname = routeFactory.toCompositorSelector({
                tenantId: t,
            });
            navigate(pathname, { replace: true });
            void dispatch(onSwitchTenant(t));
        },
        [dispatch, navigate],
    );

    const compositors = useAppSelector((state) => state.compositor);
    const tenants = useAppSelector((state) => state.auth.tenants);
    const currentTenant = useParams<{ tenantId: string }>().tenantId;

    return (
        <section>
            <div className="section-banner center-center compositors-banner">
                <div>Compositors</div>
                {(tenants?.length ?? 0) > 1 && (
                    <>
                        <div>&nbsp;&nbsp;|</div>
                        <TenantDropDownMenu
                            currentTenant={currentTenant}
                            tenants={tenants}
                            onTenantSwitch={handleSwitchTenant}
                        />
                    </>
                )}
            </div>

            {currentTenant && (
                <div className="pure-g">
                    {Object.entries(compositors)
                        .sort((a, b) => CompositorDetailsComparer(a[1], b[1]))
                        .map(([name, details]) => (
                            <div className="pure-u-1-3" key={name}>
                                <CompositorDetails
                                    tenantId={currentTenant}
                                    compositor={details}
                                />
                            </div>
                        ))}
                </div>
            )}
        </section>
    );
}

export { AvailableCompositorPage };
