import { Route, Routes } from "react-router-dom";

import LoginPageContainer from "./components/LoginPage";
import StandardLayout from "./components/StandardLayout/StandardLayout";

export default function App() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPageContainer />} />
            <Route path="/*" element={<StandardLayout />} />
        </Routes>
    );
}
