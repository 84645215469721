import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch } from "./store";

import { post, get } from "../core/ajax";

const STORED_LOGIN_DETAILS_CACHE_KEY = "grub:auth";
export const STORED_JWT_TOKEN_KEY = "grub:jwt_token";

export const onLogin = (username: string, password: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(slice.actions.loginFailure());
        try {
            post("/auth/login", { username, password })
                .then(async (a) => {
                    if (a.status === 200) {
                        return a.json();
                    } else {
                        throw await a.json();
                    }
                })
                .then(
                    (user) => {
                        dispatch(slice.actions.login(user));
                    },
                    (err: string) => {
                        const j = JSON.parse(err);
                        dispatch(slice.actions.loginFailure(j.message));
                    },
                );
        } catch (error: unknown) {
            dispatch(slice.actions.loginFailure());
        }
    };
};

export const onLogout = () => {
    localStorage.removeItem(STORED_LOGIN_DETAILS_CACHE_KEY);
    localStorage.removeItem(STORED_JWT_TOKEN_KEY);

    return async (dispatch: AppDispatch) => {
        // Dispatch this action first then worry about sending a message back to Harmony.
        dispatch(slice.actions.logout());

        await get("/logout");
    };
};

export const onSwitchTenant = (tenant: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(slice.actions.switchTenant(tenant));
    };
};

export interface IAuth {
    isLoggedIn: boolean;
    username?: string | undefined;
    displayName?: string | undefined;
    message?: string | undefined;
    isAdmin: boolean;
    tenants?: string[] | undefined;
    currentTenant?: string | undefined;
}

function authTypeGuard(arg: any): arg is IAuth {
    return (
        arg &&
        !("tenant" in arg) && // Backwards compatibility with old IAuth stored in local storage
        "isLoggedIn" in arg &&
        typeof arg.isLoggedIn == "boolean" &&
        "isAdmin" in arg &&
        typeof arg.isAdmin == "boolean"
    );
}

const DEFAULT_STATE: IAuth = { isLoggedIn: false, isAdmin: false };
function getInitialState(): IAuth {
    //TODO: 16/10/2018 - gerrod - would be better to get this from Harmony
    const storedLoginDetails = localStorage.getItem(
        STORED_LOGIN_DETAILS_CACHE_KEY,
    );
    const storedJwtToken = localStorage.getItem(STORED_JWT_TOKEN_KEY);

    if (storedLoginDetails && storedJwtToken) {
        const storedStateAsJson = JSON.parse(storedLoginDetails);
        if (authTypeGuard(storedStateAsJson)) {
            return storedStateAsJson;
        }
    }

    localStorage.removeItem(STORED_LOGIN_DETAILS_CACHE_KEY);
    localStorage.removeItem(STORED_JWT_TOKEN_KEY);
    return DEFAULT_STATE;
}

const INITIAL_STATE = getInitialState();

const slice = createSlice({
    name: "auth",
    initialState: INITIAL_STATE,
    reducers: {
        login: (
            state,
            action: PayloadAction<
                Partial<IAuth & { websocketToken?: string | undefined }>
            >,
        ) => {
            const loginDetails = {
                ...state,
                ...action.payload,
                message: undefined,
                isLoggedIn: true,
                currentTenant: action.payload.tenants?.[0],
            };

            localStorage.setItem(
                STORED_LOGIN_DETAILS_CACHE_KEY,
                JSON.stringify(loginDetails),
            );
            localStorage.setItem(
                STORED_JWT_TOKEN_KEY,
                action.payload.websocketToken ?? "",
            );

            return loginDetails;
        },

        logout: (state) => {
            return {
                ...state,
                message: undefined,
                isLoggedIn: false,
                username: undefined,
                displayName: undefined,
                isAdmin: false,
                currentTenant: undefined,
            };
        },

        loginFailure: (state, action: PayloadAction<string | undefined>) => {
            return {
                ...state,
                message: action.payload,
                isLoggedIn: false,
                username: undefined,
                displayName: undefined,
                isAdmin: false,
                currentTenant: undefined,
            };
        },

        switchTenant: (state, action: PayloadAction<string | undefined>) => {
            return {
                ...state,
                currentTenant: action.payload,
            };
        },
    },
});

export default slice.reducer;
