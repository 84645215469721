import React, { ReactNode, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faUnlink } from "@fortawesome/free-solid-svg-icons";

import {
    messageReceived,
    messageSent,
    SocketState,
    socketStateChange,
} from "./WebSocketConnection.redux";
//import { onLogout } from "../../state/Auth";
import * as harmonyUri from "./harmonyUri";
import { useParams } from "react-router-dom";
import store from "../../state/store";
import { HarmonyContext, useHarmonyClient } from "core/harmony/react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { STORED_JWT_TOKEN_KEY } from "state/Auth";

class Harmony {
    private parseResponse(t: Response) {
        // if (!t.headers.get('Content-Length') || parseInt(t.headers.get('Content-Length')) === 0) {
        //     return;
        // }

        if (t.headers.get("Content-Type")?.indexOf("application/json") === 0) {
            return t.json();
        }

        return t.text();
    }

    private handleResponse = async (
        response: Response,
    ): Promise<string | Record<string, unknown> | void> => {
        const body = await this.parseResponse(response);
        if (response.ok) {
            return body;
        } else {
            let msg = response.status.toString();
            if (body) {
                msg =
                    typeof body === "object" && body.message.toString()
                        ? body.message.toString()
                        : body.toString();
            }

            throw Error(msg);
        }
    };

    async get<T = string | Record<string, unknown> | void>(
        moduleId: string,
        name: string,
    ): Promise<T> {
        const tenantId = store.getState().auth.currentTenant;
        const token = localStorage.getItem(STORED_JWT_TOKEN_KEY);
        const myInit: RequestInit = {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: `Bearer ${token ?? ""}`,
                "x-current-tenant": tenantId ?? "",
                "Content-Type": "application/json",
            },
        };

        let url: string;
        if (moduleId) {
            url = `${harmonyUri.getWebAPIRoot()}/grub/module/${moduleId}/${name}`;
        } else {
            url = `${harmonyUri.getWebAPIRoot()}/grub/${name}`;
        }
        const response = await fetch(url, myInit);
        const handledResponse = (await this.handleResponse(response)) as T;

        return handledResponse;
    }

    async post<T = string | Record<string, unknown> | void>(
        moduleId: string,
        name: string,
        body?: { [name: string]: unknown } | File,
    ): Promise<T> {
        const tenantId = store.getState().auth.currentTenant;
        const token = localStorage.getItem(STORED_JWT_TOKEN_KEY);
        const myInit: RequestInit = {
            method: "POST",
            mode: "cors",
            credentials: "include",
        };

        const headers: Record<string, string> = {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token ?? ""}`,
            "x-current-tenant": tenantId ?? "",
            "Content-Type": "application/json",
        };

        let url: string;
        if (moduleId) {
            url = `${harmonyUri.getWebAPIRoot()}/grub/module/${moduleId}/${name}`;
        } else {
            url = `${harmonyUri.getWebAPIRoot()}/grub/${name}`;
        }

        if (body) {
            if (body instanceof File) {
                const formData = new FormData();
                formData.append(body.name, body);
                myInit.body = formData;
                delete headers["Content-Type"];
            } else {
                myInit.body = JSON.stringify(body);
            }
        }

        myInit.headers = headers;
        return fetch(url, myInit).then(this.handleResponse) as Promise<T>;
    }

    send = (_name: string, _payload: Record<string, unknown>): void => {
        console.warn("NOT IMPLEMENTED: send");
    };

    recieve = (
        _name: string,
        _cb: (payload: Record<string, unknown> | string) => void,
    ): void => {
        console.warn("NOT IMPLEMENTED: receive");
    };
}

const harmony = new Harmony();

export { harmony };

//messageSent: bindActionCreators(messageSent, dispatch),
//onLogout: bindActionCreators(onLogout, dispatch),
export default function WebSocketManager(props: { children: ReactNode }) {
    const dispatch = useAppDispatch();
    const tenantFromUrl = useParams<{ tenantId?: string }>().tenantId;
    const tenantFromState = store.getState().auth.currentTenant;
    const token = localStorage.getItem(STORED_JWT_TOKEN_KEY);
    const harmony = useHarmonyClient({
        url: harmonyUri.getWebSocketRoot(),
        tenant: tenantFromUrl ?? tenantFromState,
        token: token ?? "",
        handlers: {
            onStartConnecting: () => {
                dispatch(socketStateChange(SocketState.Connecting));
            },
            onConnected: () => {
                dispatch(socketStateChange(SocketState.Connected));
            },
            onDisconnected: () => {
                dispatch(socketStateChange(SocketState.Disconnected));
            },
            updateCompositors: (compositors) => {
                dispatch(messageReceived("compositorState", compositors));
            },
            updateAvailableModules: (modules) => {
                dispatch(messageReceived("avilableModules", modules));
            },
            updateModuleDashboardState: (state) => {
                dispatch(messageReceived("moduleDashboardState", state));
            },
        },
    });

    const isConnected = useAppSelector((state) => state.connection.isConnected);

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            if (harmony) {
                store
                    .getState()
                    .connection.messages.filter((p) => p && !p.sent)
                    .forEach((msg) => {
                        const data = JSON.stringify({
                            msg: msg.msg,
                            head: msg.head,
                            data: msg.data,
                        });

                        harmony.sendFromRedux(data);
                        dispatch(messageSent(msg.id));
                    });
            }
        });

        return () => {
            unsubscribe();
        };
    }, [dispatch, harmony]);

    return (
        <HarmonyContext.Provider value={harmony}>
            {props.children}
            <FontAwesomeIcon
                icon={isConnected ? faLink : faUnlink}
                style={{ position: "absolute", bottom: 0, right: 0 }}
            />
        </HarmonyContext.Provider>
    );
}
