import React from "react";
import { useParams } from "react-router-dom";
import memoize from "memoize-one";
import { unescapeRouteValue } from "core/Routing";
import { useAppSelector } from "state/hooks";
import { getTruckById } from "state/selectors";
import {
    CompositorDetails,
    ModuleDetails,
    TruckDetails,
    ComponentData,
} from "resources/harmonyTypes";
import { StandardLayoutRouteParams } from "../StandardLayout/Routes";

export type WithTruckProps = ITruckProps;

export interface ActiveComponent extends ComponentData {
    componentId: string;
    truckId: string;
    compositorId: string;
    componentInstanceId: string;
}

interface ITruckProps {
    compositor: CompositorDetails | undefined;
    compositorId: string | undefined;
    truckId: string | undefined;
    truck: TruckDetails | undefined;
    moduleId: string | undefined;
    module: ModuleDetails | undefined;
    activeComponents: ActiveComponent[];
}

const getActiveComponents: (
    compositorId: string,
    truckId: string,
    moduleComponents: { [p: string]: { [p: string]: ComponentData } },
) => ActiveComponent[] = memoize(
    (
        compositorId: string,
        truckId: string,
        moduleComponents: { [p: string]: { [p: string]: ComponentData } },
    ): ActiveComponent[] => {
        if (!moduleComponents) return [];

        const allComponents: ActiveComponent[] = [];

        for (const componentId of Object.keys(moduleComponents)) {
            const components = moduleComponents[componentId];
            if (!components) {
                continue;
            }

            for (const componentInstanceId of Object.keys(components)) {
                const componentInstance = components[componentInstanceId];
                if (!componentInstance) {
                    continue;
                }

                allComponents.push({
                    ...componentInstance,
                    componentInstanceId,
                    componentId,
                    truckId,
                    compositorId,
                });
            }
        }

        return allComponents;
    },
);

export function useTruck(): ITruckProps {
    const params = useParams<StandardLayoutRouteParams>();
    return useAppSelector((state) => {
        const truckId = params?.truckId;
        const truck = truckId ? getTruckById(state, truckId) : undefined;
        const moduleId = params?.moduleId;
        const module = moduleId ? truck?.modules[moduleId] : undefined;

        const escapedCompositorId = truck?.compositorId ?? params?.compositorId;
        const compositorId =
            escapedCompositorId && unescapeRouteValue(escapedCompositorId);
        const compositor = compositorId
            ? state.compositor[compositorId]
            : undefined;

        const activeComponents =
            compositorId && truckId && module
                ? getActiveComponents(compositorId, truckId, module.components)
                : [];

        return {
            compositor,
            compositorId,
            truckId,
            moduleId,
            module,
            truck,
            activeComponents,
        };
    });
}

export default function withTruck<T extends WithTruckProps>(
    Component: React.ComponentType<T>,
) {
    function WrappedComponent(props: Omit<T, keyof WithTruckProps>) {
        const truck = useTruck();

        // This is safe, but typescript isn't smart enough to work it out
        const p = {
            ...props,
            ...truck,
        } as unknown as T;

        return <Component {...p} />;
    }

    return WrappedComponent;
}
