import { useState } from "react";

import ImageDropZone from "components/ImageDropZone";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import AssetUploadForm from "components/AssetManagement/AssetUploadForm";

const DROP_TARGET_HEIGHT_PX = 200;

interface Props {
    moduleId: string;
    onFinish: (sponsorId: string) => void;
}

export default function FullFrameImageUploader(props: Props) {
    const [name, setName] = useState<string>("");
    const [bannerImage, setBannerImage] = useState<File | null>(null);

    async function handleUpload() {
        if (!(name && bannerImage)) {
            throw "You must enter a name for this image, and upload at a banner logo.";
        }

        const { id } = await harmony.post<{ id: string }>(
            props.moduleId,
            `AddFullFrameImage?name=${name}`,
            bannerImage,
        );

        props.onFinish(id);
    }

    return (
        <AssetUploadForm onSubmit={handleUpload}>
            <label htmlFor="name">Name</label>
            <input
                name="name"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
            />

            <ImageDropZone
                title="Banner"
                height={DROP_TARGET_HEIGHT_PX}
                image={bannerImage ?? undefined}
                imageRatio={[16, 9]}
                onImageUploaded={(image) => setBannerImage(image)}
            />
        </AssetUploadForm>
    );
}
