import React from "react";
import { connect } from "react-redux";
import { HorseType, IRaceDetails, RaceIdentity } from "../../types";
import RaceTitle from "../RaceTitleBar/RaceTitle";
import RunnersTable, {
    EditableRunnersTable,
} from "../HorseTables/RunnersTable";
import { IGlobalState } from "../../../../state/store";
import {
    setSelectedHorse,
    toggleFixedBackground,
    toggleScrollTotes,
} from "../../redux/Module.Redux";
import PreRaceButtons from "./PreRaceButtons";
import ManualSuper from "./ManualSuper/ManualSuper";

import "./PreRaceControls.less";

interface IOwnProps {
    raceIdentity: RaceIdentity;
}

interface IPropsFromState {
    race: IRaceDetails | null;
    selectedHorse: HorseType | undefined;
}

interface IPropsFromDispatch {
    onHorseSelected: (horse: HorseType) => void;
}

class PreRaceControls extends React.PureComponent<
    IOwnProps & IPropsFromState & IPropsFromDispatch
> {
    public render() {
        const { raceIdentity, race, selectedHorse } = this.props;
        if (!race) {
            return null;
        }

        const horseNumber = selectedHorse && selectedHorse.number;

        return (
            <section className="column">
                <div className="column-header">
                    <RaceTitle race={race} raceIdentity={raceIdentity} />
                </div>

                <div className="pre-race-controls">
                    <div className="runners-column">
                        <div className="runners-table">
                            {race.editable ? (
                                <EditableRunnersTable
                                    raceIdentity={raceIdentity}
                                    horses={race.runners}
                                    selectedHorseNumber={horseNumber}
                                    onHorseSelected={this.props.onHorseSelected}
                                />
                            ) : (
                                <RunnersTable
                                    horses={race.runners}
                                    selectedHorseNumber={horseNumber}
                                    onHorseSelected={this.props.onHorseSelected}
                                />
                            )}
                        </div>

                        <ManualSuper raceIdentity={raceIdentity} />
                    </div>

                    <div className="button-column">
                        <PreRaceButtons raceIdentity={raceIdentity} />
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (
    state: IGlobalState,
    ownProps: IOwnProps,
): IPropsFromState => {
    const { selectedRace, selectedHorse } = state.moduleState;

    return {
        ...ownProps,
        race: selectedRace,
        selectedHorse: selectedHorse ?? undefined,
    };
};

export default connect(mapStateToProps, {
    onHorseSelected: setSelectedHorse,
    onToggleFixedBackground: toggleFixedBackground,
    onToggleScrollTotes: toggleScrollTotes,
})(PreRaceControls);
