// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Models {
    export interface ModuleDashboardData {
        msg: string;
        data: unknown;
    }
    export interface Preload {
        moduleId: string;
        truckId: string;
        preloadContext: unknown;
    }
    export interface EnableModule {
        moduleId: string;
        truckId: string;
    }
    export interface HideComponent {
        truckId: string;
        moduleId: string | undefined;
        componentId: string | undefined;
        componentInstanceId: string | undefined;
    }
    export interface ShowModuleDashboard {
        moduleId: string;
    }
    export interface ShowComponent {
        truckId: string;
        moduleId: string;
        componentId: string;
        componentContext: unknown;
        visualContext?: unknown;
        componentInstanceId: string | undefined;
    }
    export interface StopTruck {
        truckId: string;
    }
    export interface OpenWindow {
        venkmanKey: string;
        compositorKey: string;
    }
    export interface RestartCompositor {
        venkmanKey: string;
        compositorKey: string;
    }
    export interface HideModuleDashboard {
        moduleId: string;
    }
    export interface SendToOptimus {
        moduleId: string;
        message: string;
        data?: unknown;
    }
}

export enum Methods {
    EnableModule = "enableModule",
    Preload = "preload",
    HideComponent = "hideComponent",
    ShowComponent = "showComponent",
    ShowModuleDashboard = "showModuleDashboard",
    HideModuleDashboard = "hideModuleDashboard",
    ModuleDashboardData = "moduleDashboardData",
    StopTruck = "stopTruck",
    OpenWindow = "openWindow",
    RestartCompositor = "restartCompositor",
    SendToOptimus = "sendToOptimus",
}

declare type CallBack<T> = (data: T) => void;

export interface Incoming {
    (msg: Methods.EnableModule, callback: CallBack<Models.EnableModule>): void;
    (msg: Methods.Preload, callback: CallBack<Models.Preload>): void;
    (
        msg: Methods.HideComponent,
        callback: CallBack<Models.HideComponent>,
    ): void;
    (
        msg: Methods.ShowModuleDashboard,
        callback: CallBack<Models.ShowModuleDashboard>,
    ): void;
    (
        msg: Methods.ShowComponent,
        callback: CallBack<Models.ShowComponent>,
    ): void;
    (msg: Methods.StopTruck, callback: CallBack<Models.StopTruck>): void;
    (msg: Methods.OpenWindow, callback: CallBack<Models.OpenWindow>): void;
    (
        msg: Methods.HideModuleDashboard,
        callback: CallBack<Models.HideModuleDashboard>,
    ): void;
    (
        msg: Methods.ModuleDashboardData,
        callback: CallBack<Models.ModuleDashboardData>,
    ): void;
    (
        msg: Methods.RestartCompositor,
        callback: CallBack<Models.RestartCompositor>,
    ): void;
}

export type OutGoing = { head?: { CorrelationId?: string } } & (
    | { type: Methods.EnableModule; payload: Models.EnableModule }
    | { type: Methods.Preload; payload: Models.Preload }
    | { type: Methods.ShowComponent; payload: Models.ShowComponent }
    | { type: Methods.HideComponent; payload: Models.HideComponent }
    | { type: Methods.ShowModuleDashboard; payload: Models.ShowModuleDashboard }
    | { type: Methods.StopTruck; payload: Models.StopTruck }
    | { type: Methods.OpenWindow; payload: Models.OpenWindow }
    | { type: Methods.HideModuleDashboard; payload: Models.HideModuleDashboard }
    | { type: Methods.ModuleDashboardData; payload: Models.ModuleDashboardData }
    | { type: Methods.RestartCompositor; payload: Models.RestartCompositor }
    | { type: Methods.SendToOptimus; payload: Models.SendToOptimus }
);
