import { CSSProperties } from "react";

export default (
    dropTargetHeight: number,
    ratio?: [number, number],
): CSSProperties => {
    const ratioWidth = ratio?.[0] ?? 1;
    const ratioHeight = ratio?.[1] ?? 1;
    return {
        height: dropTargetHeight,
        width: (dropTargetHeight * ratioWidth) / ratioHeight,
    };
};
