import { useEffect, useState } from "react";

import "./styles.less";
import TitleBar from "./TitleBar";
import BusySpinner from "components/BusySpinner";
import Modal from "components/Modal";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";

import ExistingImageList from "./ExistingImageList";
import VenueImageUploader from "./VenueImageUploader";
import { useTruck } from "components/withTruck";

interface IVenueImages {
    name: string;
    state: string;
    ff_loop: string[];
}

interface IVenueModel {
    [venueId: string]: IVenueImages;
}

type Props = {
    venueCode: string;
    onClose: () => void;
};

function VenueImageDialog(props: Props) {
    const [isBusy, setIsBusy] = useState(false);
    const [images, setImages] = useState<string[]>();
    const [error, setError] = useState<string>();

    const moduleId = useTruck().moduleId ?? "";

    useEffect(() => {
        if (!images) {
            setIsBusy(true);

            void (async () => {
                try {
                    const venues = await harmony.get<IVenueModel>(
                        moduleId,
                        "LoadVenueList",
                    );

                    const images = venues[props.venueCode]?.ff_loop ?? [];
                    setImages(images);
                } catch (err) {
                    if (err && typeof err === "object") {
                        setError(err.toString());
                    }
                } finally {
                    setIsBusy(false);
                }
            })();
        }

        return () => {
            setIsBusy(false);
        };
    }, [props.venueCode, images, moduleId]);

    return (
        <Modal
            open={true}
            onClose={props.onClose}
            className="venue-images-dialog"
        >
            <TitleBar venueCode={props.venueCode} />

            {error && <section className="error-banner">{error}</section>}

            <section className="dialog-content busy-spinner-host">
                <BusySpinner isBusy={isBusy} />

                <ExistingImageList
                    images={images ?? []}
                    onDelete={handleDelete}
                    onReorder={handleReorder}
                    onReorderSave={handleReorderSaved}
                />

                <VenueImageUploader onImageUploaded={handleImageSaved} />
            </section>
        </Modal>
    );

    async function handleImageSaved(image: File) {
        setIsBusy(true);

        try {
            await harmony.post(
                moduleId,
                "VenueLogo?venuecode=" + props.venueCode,
                image,
            );
            // clear image list so it's reloaded
            setImages(undefined);
        } catch (err) {
            if (err && typeof err === "object") {
                setError(err.toString());
            }
        } finally {
            setIsBusy(false);
        }
    }

    function handleReorder(images: string[]) {
        setImages(images);
    }

    async function handleReorderSaved() {
        setIsBusy(true);

        try {
            const requestBody = { images };
            await harmony.post(
                moduleId,
                "ReorderVenueLogos?venuecode=" + props.venueCode,
                requestBody,
            );
            // clear image list so it's reloaded
            setImages(undefined);
        } catch (err) {
            if (err && typeof err === "object") {
                setError(err.toString());
            }
        } finally {
            setIsBusy(false);
        }
    }

    async function handleDelete(image: string) {
        setIsBusy(true);

        try {
            await harmony.post(
                moduleId,
                `RemoveVenueLogo?venuecode=${props.venueCode}&img=${image}`,
            );
            // clear image list so it's reloaded
            setImages(undefined);
        } catch (err) {
            if (err && typeof err === "object") {
                setError(err.toString());
            }
        } finally {
            setIsBusy(false);
        }
    }
}

export default VenueImageDialog;
