import { useEffect, useRef, useState } from "react";
import cn from "classnames";

import "./FormButton.less";

interface IFormButtonProps {
    title: string;
    disabled?: boolean;
    className?: string;
    confirmationToken?: string | undefined;
    onClick: () => void;
}

export default function FormButton({
    title,
    disabled,
    className,
    onClick,
    confirmationToken,
}: IFormButtonProps) {
    const [isConfirming, setIsConfirming] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        setIsConfirming(false);
    }, [confirmationToken]);

    const handleClick = () => {
        if (buttonRef.current) {
            buttonRef.current.blur();
        }

        if (isConfirming || !confirmationToken) {
            setIsConfirming(false);
            onClick();
        }

        if (confirmationToken && !isConfirming) {
            setIsConfirming(true);
        }
    };

    const classNames = cn(
        "form-button",
        {
            confirming: isConfirming,
            disabled,
        },
        className,
    );

    const buttonText = isConfirming ? "Confirm?" : title;

    return (
        <button className={classNames} onClick={handleClick} ref={buttonRef}>
            <div className="title">{buttonText}</div>
        </button>
    );
}
