import { useRef, useState } from "react";

import useEffectAsync from "core/hooks/useEffectAsync";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import AssetList from "components/AssetManagement/AssetList";
import AssetListItem from "components/AssetManagement/AssetListItem";
import Modal from "components/Modal";
import { ISponsorLogo } from "../../../types";

import SponsorLogoUploader, { SponsorLogoEditor } from "./SponsorLogoUploader";

export default function SponsorLogoAssetPage(props: { moduleId: string }) {
    const [editingSponsor, setEditingSponsor] = useState<ISponsorLogo>();
    const [sponsors, setSponsors] = useState<ISponsorLogo[]>([]);

    async function updateSponsors() {
        const sponsors = await harmony.get<ISponsorLogo[]>(
            props.moduleId,
            "LoadSponsorList",
        );
        sponsors.sort((left, right) =>
            left.name
                .toLocaleLowerCase()
                .localeCompare(right.name.toLocaleLowerCase()),
        );
        setSponsors(sponsors);
    }

    useEffectAsync(updateSponsors);

    async function handleDelete(id: string) {
        await harmony.post(props.moduleId, "RemoveSponsor", { sponsorId: id });
        setSponsors(sponsors.filter((sponsor) => sponsor.id != id));
    }

    const assets = sponsors.map((sponsor) => {
        const img = <img src={sponsor.small} />;
        return (
            <AssetListItem
                key={sponsor.id}
                name={sponsor.name}
                icon={img}
                onEdit={() => {
                    setEditingSponsor(sponsor);
                }}
                onDelete={() => handleDelete(sponsor.id)}
            />
        );
    });

    return (
        <>
            <Modal
                open={!!editingSponsor}
                onClose={() => {
                    setEditingSponsor(undefined);
                }}
            >
                {editingSponsor && (
                    <SponsorLogoEditor
                        moduleId={props.moduleId}
                        logo={editingSponsor}
                        onFinish={() => {
                            // TODO: We call updateSponsors to try and update
                            // the sponsor we just edited. This works well for
                            // the sponsor name but the the icon does not update
                            // since the src url is the same and the browser
                            // won't fetch the new image.
                            //
                            // We can fix this by using this hack https://stackoverflow.com/questions/1431512/is-there-a-way-to-force-browsers-to-refresh-download-images
                            // but not sure this is necessary.
                            updateSponsors().then(() => {
                                setEditingSponsor(undefined);
                            });
                        }}
                        key={editingSponsor.id}
                    />
                )}
            </Modal>
            <AssetList
                uploadForm={({ onFinish }) => (
                    <SponsorLogoUploader
                        moduleId={props.moduleId}
                        onFinish={onFinish}
                    />
                )}
                onUpload={updateSponsors}
            >
                {assets}
            </AssetList>
        </>
    );
}
