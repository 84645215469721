import { useState } from "react";

import ImageDropZone from "components/ImageDropZone";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import AssetUploadForm from "components/AssetManagement/AssetUploadForm";

import { ISponsorLogo } from "../../../types";

const DROP_TARGET_HEIGHT_PX = 200;

interface UploaderProps {
    moduleId: string;
    onFinish: (sponsorId: string) => void;
}

export default function SponsorLogoUploader(props: UploaderProps) {
    const [name, setName] = useState<string>("");
    const [keywords, setKeywords] = useState<string>("");
    const [tileImage, setTileImage] = useState<File | null>(null);
    const [bannerImage, setBannerImage] = useState<File | null>(null);

    async function handleUpload() {
        if (!(name && (tileImage || bannerImage))) {
            throw "You must enter a name for this sponsor, and upload at least one tile or banner logo.";
        }

        // TODO check if name is unique

        // Harmony creates an id for the sponsor when the data gets posted
        const { id } = await harmony.post<ISponsorLogo>(
            props.moduleId,
            "sponsor",
            {
                id: null,
                name,
                keywords: keywords.split(" "),
            },
        );

        await Promise.all([
            tileImage &&
                harmony.post(
                    props.moduleId,
                    `SponsorLogo?type=small&sponsorId=${id}`,
                    tileImage,
                ),
            bannerImage &&
                harmony.post(
                    props.moduleId,
                    `SponsorLogo?type=large&sponsorId=${id}`,
                    bannerImage,
                ),
        ]);

        props.onFinish(id);
    }

    return (
        <AssetUploadForm onSubmit={handleUpload}>
            <label htmlFor="name">Name</label>
            <input
                name="name"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
            />

            <label htmlFor="keywords">Keywords</label>
            <textarea
                name="keywords"
                value={keywords}
                onChange={(event) => setKeywords(event.target.value)}
            />
            <span>
                Line separated list of words used to match on race name.
            </span>

            <ImageDropZone
                title="Tile"
                height={DROP_TARGET_HEIGHT_PX}
                imageRatio={[1, 1]}
                image={tileImage ?? undefined}
                onImageUploaded={(image) => setTileImage(image)}
            />

            <ImageDropZone
                title="Banner"
                height={DROP_TARGET_HEIGHT_PX}
                image={bannerImage ?? undefined}
                imageRatio={[16, 9]}
                onImageUploaded={(image) => setBannerImage(image)}
            />
        </AssetUploadForm>
    );
}

interface EditorProps {
    moduleId: string;
    logo: ISponsorLogo;
    onFinish: () => void;
}

export function SponsorLogoEditor(props: EditorProps) {
    const { moduleId, logo } = props;

    const [name, setName] = useState<string>(logo.name);
    const [keywords, setKeywords] = useState<string>(
        logo.keywords?.join(" ") ?? "",
    );
    const [tileImage, setTileImage] = useState<File | null>(null);
    const [bannerImage, setBannerImage] = useState<File | null>(null);

    async function handleEdit() {
        const { id } = await harmony.post<ISponsorLogo>(moduleId, "sponsor", {
            id: logo.id,
            name,
            keywords: keywords.split(" "),
        });

        await Promise.all([
            tileImage &&
                harmony.post(
                    moduleId,
                    `SponsorLogo?type=small&sponsorId=${id}`,
                    tileImage,
                ),
            bannerImage &&
                harmony.post(
                    moduleId,
                    `SponsorLogo?type=large&sponsorId=${id}`,
                    bannerImage,
                ),
        ]);

        props.onFinish();
    }

    return (
        <AssetUploadForm onSubmit={handleEdit} title="Edit Sponsor">
            <i>
                Edit sponsor name or keywords. You can also modify the tile or
                banner image.
            </i>
            <label htmlFor="name">Name</label>
            <input
                name="name"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
            />
            <label htmlFor="keywords">Keywords</label>
            <textarea
                name="keywords"
                value={keywords}
                onChange={(event) => setKeywords(event.target.value)}
            />
            <span>
                Line separated list of words used to match on race name.
            </span>
            <h3>Tile</h3>
            Current:
            {logo.small ? (
                <div style={{ height: `${DROP_TARGET_HEIGHT_PX / 2}px` }}>
                    <img src={logo.small} style={{ height: "100%" }} />
                </div>
            ) : (
                " (None)"
            )}
            <ImageDropZone
                title="New Tile"
                height={DROP_TARGET_HEIGHT_PX}
                imageRatio={[1, 1]}
                image={tileImage ?? undefined}
                onImageUploaded={(image) => setTileImage(image)}
            />
            <h3>Banner</h3>
            Current:
            {logo.large ? (
                <div style={{ height: `${DROP_TARGET_HEIGHT_PX / 2}px` }}>
                    <img src={logo.large} style={{ height: "100%" }} />
                </div>
            ) : (
                " (None)"
            )}
            <ImageDropZone
                title="New Banner"
                height={DROP_TARGET_HEIGHT_PX}
                image={bannerImage ?? undefined}
                imageRatio={[16, 9]}
                onImageUploaded={(image) => setBannerImage(image)}
            />
            <br />
        </AssetUploadForm>
    );
}
