import { HorseType } from "../../types";

interface IProps {
    runner: HorseType;
}

const JockeyTrainerCell = ({ runner }: IProps): JSX.Element => {
    const apprenticeClaim = runner.claimAmount
        ? ` [a${runner.claimAmount}]`
        : "";

    return (
        <td className="jockey-trainer">
            {runner.jockey}
            {apprenticeClaim}
            <br />
            {runner.trainer}
        </td>
    );
};

export default JockeyTrainerCell;
