import cn from "classnames";
import HorsePriceTableCell from "./HorsePriceTableCell";

import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import { HorseType, RaceIdentity } from "../../types";
import { useTruck } from "components/withTruck";

import Sortable from "components/Sortable";
import "./styles.less";
import JockeyTrainerCell from "./JockeyTrainerCell";

interface IHorseTableRowProps {
    raceIdentity?: RaceIdentity | undefined;
    horse: HorseType;
    selected: boolean;
    onSelected: (horse: HorseType) => void;
    rowIndex: number;
    moduleId: string;
}

const RunnerTableRow = ({
    raceIdentity,
    horse,
    selected,
    onSelected,
    rowIndex,
    moduleId,
}: IHorseTableRowProps): JSX.Element => {
    const rowClasses = cn({
        alt: rowIndex % 2 === 1,
        scratched: horse.isScratched,
        selected,
    });

    const handleSelected = () => onSelected(horse);

    const handleToggleBarrier = () => {
        void harmony.post(moduleId, "updateRunnerBarrier", {
            race: raceIdentity,
            horseNumber: horse.number,
            barrier: horse.barrierNumber == "INSIDE" ? "OUTSIDE" : "INSIDE",
        });
    };

    return (
        <tr onClick={handleSelected} className={rowClasses}>
            <td className="horse-number-cell">{horse.number}</td>

            <td className="silk-cell">
                <img src={horse.silk} />
            </td>

            <td>
                {horse.name} [{horse.barrierNumber}]
                {(horse.barrierNumber == "INSIDE" ||
                    horse.barrierNumber == "OUTSIDE") && (
                    <button
                        className="barrier-toggle"
                        onClick={handleToggleBarrier}
                    >
                        Toggle
                    </button>
                )}
            </td>
            <JockeyTrainerCell runner={horse} />

            <td className="text-center">
                {horse.form ? horse.form.split("").join(" ") : "-"}
            </td>
            <td className="text-center">{horse.handicapWeight}</td>
            <td className="text-center">{horse.skyRating}</td>
            <HorsePriceTableCell price={horse.winAmount} name={horse.name} />
            <HorsePriceTableCell price={horse.placeAmount} name={horse.name} />
        </tr>
    );
};

interface IRunnersTableProps {
    raceIdentity?: RaceIdentity;
    horses: HorseType[];
    selectedHorseNumber: number | undefined;
    onHorseSelected: (horse: HorseType) => void;
}

export function EditableRunnersTable(props: IRunnersTableProps) {
    const { horses, selectedHorseNumber, onHorseSelected } = props;
    const sortedHorses = [...horses].sort(
        (left, right) => left.number - right.number,
    );

    const moduleId = useTruck().moduleId ?? "";

    const handleReorder = (oldIndex: number, newIndex: number) => {
        const oldNumber = sortedHorses[oldIndex]!.number;
        const newNumber = sortedHorses[newIndex]!.number;

        void harmony.post(moduleId, "reorderRunner", {
            race: props.raceIdentity,
            oldNumber,
            newNumber,
        });
    };

    return (
        <table className="table runners-table">
            <thead>
                <tr>
                    <th className="text-center">No.</th>
                    <th className="silk-cell">Silk</th>
                    <th>Runner [Barrier]</th>
                    <th>Jockey / Trainer</th>
                    <th className="text-center">Form</th>
                    <th className="text-center">KG</th>
                    <th className="text-center">Sky</th>
                    <th className="price-cell">Win</th>
                    <th className="price-cell">Place</th>
                </tr>
            </thead>
            <Sortable onReorder={handleReorder}>
                {sortedHorses.map((horse, rowIndex) => (
                    <RunnerTableRow
                        key={horse.number}
                        raceIdentity={props.raceIdentity}
                        rowIndex={rowIndex}
                        horse={horse}
                        selected={horse.number === selectedHorseNumber}
                        onSelected={onHorseSelected}
                        moduleId={moduleId}
                    />
                ))}
            </Sortable>
        </table>
    );
}

export default function RunnersTable(props: IRunnersTableProps) {
    const { horses, selectedHorseNumber, onHorseSelected } = props;
    const sortedHorses = [...horses].sort(
        (left, right) => left.number - right.number,
    );

    const moduleId = useTruck().moduleId ?? "";

    return (
        <table className="table runners-table">
            <thead>
                <tr>
                    <th className="text-center">No.</th>
                    <th className="silk-cell">Silk</th>
                    <th>Runner [Barrier]</th>
                    <th>Jockey / Trainer</th>
                    <th className="text-center">Form</th>
                    <th className="text-center">KG</th>
                    <th className="text-center">Sky</th>
                    <th className="price-cell">Win</th>
                    <th className="price-cell">Place</th>
                </tr>
            </thead>
            <tbody>
                {sortedHorses.map((horse, rowIndex) => (
                    <RunnerTableRow
                        key={horse.number}
                        rowIndex={rowIndex}
                        horse={horse}
                        selected={horse.number === selectedHorseNumber}
                        onSelected={onHorseSelected}
                        moduleId={moduleId}
                    />
                ))}
            </tbody>
        </table>
    );
}
