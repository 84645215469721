import { PropsWithChildren, useCallback, useEffect } from "react";
import {
    Components,
    HorseType,
    IRaceDetails,
    IRenderComponentContext,
    PageInfo,
    RaceIdentity,
} from "../types";
import { ActiveComponent } from "components/withTruck";

interface IProps {
    truckId: string;
    moduleId: string;
    raceIdentity: RaceIdentity;
    selectedRace: IRaceDetails;
    selectedHorse: HorseType | undefined;
    activeComponents: ActiveComponent[];

    setSelectedHorse: (horse: HorseType | undefined) => void;
    showComponent: (
        truckId: string,
        moduleId: string,
        componentId: string,
        componentContext: IRenderComponentContext,
        visualContext: { [name: string]: unknown },
        componentInstanceId?: string,
    ) => void;
    hideComponent: (
        truckId: string,
        moduleId: string,
        componentId: string,
        componentInstanceId?: string,
    ) => void;
}

export default function ShortcutKeys(props: PropsWithChildren<IProps>) {
    const { children, ...ownProps } = props;

    const { showComponent, hideComponent } = props;

    const show = useCallback(
        (
            componentId: string,
            componentContext: IRenderComponentContext,
            visualContext: { [name: string]: unknown },
            componentInstanceId?: string,
        ) => {
            const { truckId, moduleId } = ownProps;
            showComponent(
                truckId,
                moduleId,
                componentId,
                componentContext,
                visualContext,
                componentInstanceId,
            );
        },
        [showComponent, ownProps],
    );

    const hide = useCallback(
        (componentId: string, componentInstanceId: string) => {
            const { truckId, moduleId } = ownProps;
            hideComponent(truckId, moduleId, componentId, componentInstanceId);
        },
        [hideComponent, ownProps],
    );

    const handleKey = useCallback(
        (event: KeyboardEvent) => {
            // TODO find out a better way of ignoring input elements
            if (document.activeElement?.tagName == "INPUT") {
                return;
            }

            if (event.key != " ") {
                return;
            }

            const preventDefault = () => {
                if (event.preventDefault) event.preventDefault();
            };

            const { raceIdentity, selectedRace, selectedHorse } = ownProps;
            const componentId = Components.lf_yardPage;

            const mountingYardComponent = ownProps.activeComponents.find(
                (component) => component.componentId === componentId,
            );
            if (!mountingYardComponent) {
                if (!selectedHorse) return;

                const componentContext = {
                    ...raceIdentity,
                    horseNumber: ownProps.selectedHorse?.number,
                };

                show(componentId, componentContext, {});
                preventDefault();

                return;
            }

            const { componentContext, componentInstanceId } =
                mountingYardComponent;
            if (
                componentContext.raceNumber !== raceIdentity.raceNumber ||
                componentContext.venue !== raceIdentity.venue
            ) {
                return;
            }

            const { visualContext } = mountingYardComponent;
            const pageInfo = visualContext.pageInfo as PageInfo;

            if (pageInfo.currentPage === pageInfo.pageCount - 1) {
                // Hide and go to next horse
                hide(componentId, componentInstanceId);

                const nextRunner = selectedRace.runners.find(
                    (runner) =>
                        !runner.isScratched &&
                        // TODO fix type
                        runner.number > (componentContext as any).horseNumber,
                );
                ownProps.setSelectedHorse(nextRunner);
            } else {
                // Go to next page
                const updatedVisualContext = {
                    ...visualContext,
                    pageInfo: {
                        ...pageInfo,
                        currentPage: pageInfo.currentPage + 1,
                    },
                };

                show(
                    componentId,
                    // TODO fix this typing properly
                    componentContext as unknown as IRenderComponentContext,
                    updatedVisualContext,
                    mountingYardComponent.componentInstanceId,
                );
            }

            preventDefault();
        },
        [hide, show, ownProps],
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKey);

        return () => {
            window.removeEventListener("keydown", handleKey);
        };
    }, [handleKey]);

    return <>{children}</>;
}
