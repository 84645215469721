import { useCallback, useState } from "react";

interface SilkViewerProps {
    classNames: string;
    id: string;
    url: string;
    isCustomSilk: boolean;
}

export default function SilkViewer(props: SilkViewerProps) {
    const [showLarge, setShowLarge] = useState(false);

    const showMagnified = useCallback(() => setShowLarge(true), []);
    const hideMagnified = useCallback(() => setShowLarge(false), []);

    return (
        <div onMouseEnter={showMagnified} onMouseLeave={hideMagnified}>
            <img className={props.classNames} src={props.url} />
            {showLarge && (
                <div className="large-silk">
                    <img src={props.url} />
                    <p>{props.id}</p>
                </div>
            )}
        </div>
    );
}
