import React from "react";

import ImagePreview from "./ImagePreview";
import DropTarget from "./DropTarget";

import "./ImageDropZone.less";

interface IProps {
    title?: string;
    height: number;
    image?: File | undefined;

    /** [width, height] */
    imageRatio?: [number, number];
    extraImageStyles?: React.CSSProperties | undefined;
    onImageUploaded: (file: File | null) => void;
}

export default React.memo(function ImageDropZone({
    title,
    height,
    image,
    imageRatio,
    extraImageStyles,
    onImageUploaded,
}: IProps) {
    const ratioText = imageRatio ? ` (${imageRatio[0]}:${imageRatio[1]})` : "";

    return (
        <div className="drop-zone">
            {title && (
                <label>
                    {title}
                    {ratioText}
                </label>
            )}

            {!image && (
                <DropTarget
                    height={height}
                    imageRatio={imageRatio}
                    onImageUploaded={onImageUploaded}
                />
            )}

            {image && (
                <ImagePreview
                    image={image}
                    height={height}
                    imageRatio={imageRatio}
                    extraImageStyles={extraImageStyles}
                    onImageDelete={() => onImageUploaded(null)}
                />
            )}
        </div>
    );
});
