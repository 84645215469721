import React from "react";
import { RingLoader } from "react-spinners";

import "./BusySpinner.less";

const Spinner = ({ isBusy }: { isBusy: boolean }) => {
    if (!isBusy) {
        return null;
    }

    return (
        <div className="busy-spinner">
            <RingLoader size={80} color="#009edd" loading={isBusy} />
        </div>
    );
};

export default Spinner;
