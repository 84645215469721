import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch } from "../../state/store";
import { sendWebSocketMessage } from "components/WebSocketConnection/WebSocketConnection.redux";
import { Methods } from "components/WebSocketConnection/GrubContracts";
import randomString from "../../core/randomString";

import type { IModuleStateUpdate as ATCModuleStateUpdate } from "../../modules/atc/redux/Module.Redux";
import type { IUpdateModuleState as TripleSModuleStateUpdate } from "../../modules/triples/redux/redux";

export const ActionTypes = {
    AVAILABLE_MODULES_RECEIVED: "AVAILABLE_MODULES_RECEIVED",
    MODULE_STATE_UPDATE: "MODULE_STATE_UPDATE",
};

export interface ModuleType {
    id: string;
    name: string;
    icon: string;
    enabled: boolean;
}

function createCorrelationId() {
    return `G-${randomString(7)}`;
}

export const preload =
    (truckId: string, moduleId: string, preloadContext: unknown) =>
    (dispatch: AppDispatch) => {
        const payload = { moduleId, truckId, preloadContext };

        dispatch(sendWebSocketMessage({ type: Methods.Preload, payload }));
    };

export const showComponent =
    (
        truckId: string,
        moduleId: string,
        componentId: string,
        componentContext: unknown,
        visualContext: { [name: string]: unknown } | undefined,
        componentInstanceId: string | undefined,
    ) =>
    (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.ShowComponent,
                payload: {
                    moduleId,
                    truckId,
                    componentId,
                    componentContext,
                    visualContext,
                    componentInstanceId,
                },
                head: { CorrelationId: createCorrelationId() },
            }),
        );
    };

export const hideComponent =
    (
        truckId: string,
        moduleId?: string,
        componentId?: string,
        componentInstanceId?: string,
    ) =>
    (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.HideComponent,
                payload: {
                    moduleId,
                    truckId,
                    componentId,
                    componentInstanceId,
                },
                head: { CorrelationId: createCorrelationId() },
            }),
        );
    };

export const startTruckSession =
    (compositorKey: string, venkmanKey: string) => (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.OpenWindow,
                payload: { compositorKey, venkmanKey },
                head: { CorrelationId: createCorrelationId() },
            }),
        );
    };

export const stopTruckSession =
    (truckId: string) => (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.StopTruck,
                payload: { truckId },
            }),
        );
    };

export const restartCompositor =
    (venkmanKey: string, compositorKey: string) => (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.RestartCompositor,
                payload: { venkmanKey, compositorKey },
            }),
        );
    };

export const hideModuleDashboard =
    (moduleId: string) => (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.HideModuleDashboard,
                payload: { moduleId },
            }),
        );
    };

export const showModuleDashboard =
    (moduleId: string) => (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.ShowModuleDashboard,
                payload: { moduleId },
            }),
        );
    };

export const updateModuleDashboardData =
    (msg: string, data: unknown) => (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.ModuleDashboardData,
                payload: { msg, data },
            }),
        );
    };

export const enableModule =
    (truckId: string, moduleId: string) => (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.EnableModule,
                payload: { truckId, moduleId },
            }),
        );
    };

export const sendOptimusMessage =
    (moduleId: string, message: string, data?: unknown) =>
    (dispatch: AppDispatch) => {
        dispatch(
            sendWebSocketMessage({
                type: Methods.SendToOptimus,
                payload: { moduleId, message, data },
            }),
        );
    };

// ----- REDUCERS -----------------------------------------------------------

const initialState: string[] = [];

const slice = createSlice({
    name: "availableModules",
    initialState,
    reducers: {
        availableModulesReceived: (_, action: PayloadAction<ModuleType[]>) =>
            action.payload.map((m) => m.id),
    },
});

// TODO this action is a bit weird, can we change it to not dispatch to all modules at once somehow?
export const updateModuleState = createAction<
    ATCModuleStateUpdate | TripleSModuleStateUpdate
>(`${slice.name}/updateModuleState`);
export const updateAvailableModules = slice.actions.availableModulesReceived;

export default slice.reducer;
