import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useAppSelector } from "state/hooks";
import WebSocketConnection from "../WebSocketConnection/WebSocketConnection";
import StandardRoutes, { routeFactory } from "./Routes";

import "./style.less";
import TimeContextProvider from "../Time/TimeContext";

export default function StandardLayout() {
    const { isLoggedIn, currentTenant } = useAppSelector((state) => state.auth);
    const location = useLocation();
    // Assume logged in users have at least one tenant which should be automatically set on login
    if (!isLoggedIn || !currentTenant) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    const pathname = routeFactory.toCompositorSelector({
        tenantId: currentTenant,
    });

    return (
        <TimeContextProvider>
            <Routes>
                <Route
                    path=""
                    element={
                        <Navigate to={pathname} state={{ from: location }} />
                    }
                />
                <Route path=":tenantId/*" element={<RoutesWithWebsocket />} />
            </Routes>
        </TimeContextProvider>
    );
}

function RoutesWithWebsocket() {
    return (
        <WebSocketConnection>
            <div className="app-container">
                <StandardRoutes />
            </div>
        </WebSocketConnection>
    );
}
