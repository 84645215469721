import withTruck, { WithTruckProps } from "../../withTruck/index";
import Environment from "./Environment";

const ConnectedCompositor = (props: WithTruckProps) => {
    const venkmanName = props.compositor?.venkman?.name;
    return (
        <div>
            <Environment />
            {venkmanName}
        </div>
    );
};

export default withTruck(ConnectedCompositor);
