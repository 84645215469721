import { connect } from "react-redux";

import { IGlobalState } from "../../../../state/store";
import ComponentButton from "../../../atc/components/ComponentButton/ComponentButton";

import "./AtcHomestraightControls.styles.less";
import { HorseType, IRaceDetails, RaceIdentity } from "modules/atc/types";
import RaceTitle from "../RaceTitleBar/RaceTitle";
import RunnersTable from "../HorseTables/RunnersTable";

interface RaceControlsProps {
    raceIdentity: RaceIdentity;
}

interface PropsFromState {
    selectedRace: IRaceDetails | null;
    selectedHorseNumber: HorseType | undefined;
}

enum Components {
    JockeyList = "Grub_HorseList",
}

function RaceControls(props: RaceControlsProps & PropsFromState) {
    if (!props.raceIdentity.raceNumber || !props.selectedRace) {
        return null;
    }

    const componentTypes = [Components.JockeyList];

    const renderButtons = () => {
        return (
            <div className="button-column">
                <ComponentButton
                    title="Start All Components"
                    componentType={componentTypes}
                    componentContext={props.raceIdentity}
                    visualContext={{}}
                />
                <br />
                <div>Individual Controls</div>
                <ComponentButton
                    title="Jockey List"
                    componentType={Components.JockeyList}
                    componentContext={props.raceIdentity}
                />
            </div>
        );
    };

    return (
        <section className="two-column-container">
            <section className="column">
                <div className="column-header">
                    <RaceTitle
                        race={props.selectedRace}
                        raceIdentity={props.raceIdentity}
                    />
                </div>

                <div className="pre-race-controls">
                    <div className="runners-column">
                        <div className="runners-table">
                            <RunnersTable
                                horses={props.selectedRace.runners}
                                selectedHorseNumber={
                                    props.selectedHorseNumber?.number
                                }
                                onHorseSelected={(h) => {
                                    h;
                                }}
                            />
                        </div>
                    </div>

                    <div className="button-column">{renderButtons()}</div>
                </div>
            </section>
        </section>
    );
}

const mapStateToProps = (state: IGlobalState, ownProps: RaceControlsProps) => {
    const { selectedRace, selectedHorse: selectedHorseNumber } =
        state.moduleState;

    return {
        ...ownProps,
        selectedRace,
        selectedHorseNumber: selectedHorseNumber ?? undefined,
    };
};

export default connect(mapStateToProps, {})(RaceControls);
