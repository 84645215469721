import React from "react";
import { Route, Routes } from "react-router-dom";
import urlJoin from "url-join";
import CompositorPage from "../Compositors/CompositorPage/CompositorPage";
import AvailableCompositorsPage from "../Compositors/AvailableCompositorsPage/AvailableCompositorsPage";
import AssetManagementPage from "../AssetManagement/AssetManagementPage";
import ModulePage from "../ModulePage/ModulePage";
import TruckPage from "../TruckPage/TruckPage";
import PageNotFound from "components/PageNotFound/PageNotFound";
import AdminRoutes from "components/AdminPage/AdminRoutes";
import TimingSponsorManagementPage from "components/TimingSponsorManagement";
import Header from "./Header";

export type StandardLayoutRouteParams = {
    tenantId?: string;
    truckId?: string;
    moduleId?: string;
    compositorId?: string;
};

const withHeader = (Child: React.ElementType) => {
    return (
        <>
            <Header />
            <Child />
        </>
    );
};

export default function StandardRoutes() {
    return (
        <Routes>
            <Route path="admin/*" element={withHeader(AdminRoutes)} />
            <Route path="compositors/*">
                <Route
                    path=":compositorId"
                    element={withHeader(CompositorPage)}
                />
                <Route path="" element={withHeader(AvailableCompositorsPage)} />
            </Route>
            <Route
                path="asset-management/*"
                element={withHeader(AssetManagementPage)}
            />
            <Route path="trucks/:truckId/*">
                <Route
                    path="timing-sponsor-management/*"
                    element={withHeader(TimingSponsorManagementPage)}
                />
                <Route
                    path="modules/:moduleId/*"
                    element={withHeader(ModulePage)}
                />
                <Route path="" element={withHeader(TruckPage)} />
            </Route>
            <Route element={withHeader(PageNotFound)} />
        </Routes>
    );
}

export const routeFactory = {
    toHome: (): string => "/",

    toCompositorSelector: (routeParams: { tenantId: string }): string =>
        urlJoin("/", routeParams.tenantId, "compositors"),

    toAssetManagement: (routeParams: { tenantId: string }): string =>
        urlJoin("/", routeParams.tenantId, "asset-management"),

    toTimingSponsorManagement: (routeParams: {
        tenantId: string;
        truckId: string;
    }): string =>
        urlJoin(
            "/",
            routeParams.tenantId,
            "trucks",
            routeParams.truckId,
            "timing-sponsor-management",
        ),

    toAdminPage: (routeParams: { tenantId: string }): string =>
        urlJoin("/", routeParams.tenantId, "admin"),

    toTruckOpenScreen: (routeParams: {
        tenantId: string;
        compositorId: string;
    }): string =>
        urlJoin(
            "/",
            routeParams.tenantId,
            "/compositors",
            routeParams.compositorId,
        ),

    toModuleSelector: (routeParams: {
        tenantId: string;
        truckId: string;
    }): string =>
        urlJoin("/", routeParams.tenantId, "/trucks", routeParams.truckId),

    toModule: (routeParams: {
        tenantId: string;
        truckId: string;
        moduleId: string;
    }): string =>
        urlJoin(
            "/",
            routeParams.tenantId,
            "/trucks",
            routeParams.truckId,
            "modules",
            routeParams.moduleId,
        ),
};
