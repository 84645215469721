import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import useEffectAsync from "core/hooks/useEffectAsync";
import { ReactElement, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../../components/Modal";

import "./RaceControls";
import SponsorUploader from "../AssetTypes/Sponsor/SponsorUploader";
import SponsorIcon from "../AssetTypes/Sponsor/SponsorIcon";
import { ISponsor } from "../../types";

interface ISponsorSelectorProps {
    moduleId: string;
    selectedVenue: string;
    currentSponsorId: number;
    setSponsor: (sponsor: ISponsor) => void;
    removeSponsor: () => void;
}

const SponsorItem = (props: {
    name: string;
    icon: ReactElement;
    onSelect?: () => void;
    onRemove?: () => void;
}) => {
    return (
        <div className="asset-list-item">
            <div className="asset-icon">{props.icon}</div>
            <span className="asset-name">{props.name}</span>
            {props.onSelect && (
                <button className="select-button" onClick={props.onSelect}>
                    Select
                </button>
            )}
            {props.onRemove && (
                <button className="delete-button" onClick={props.onRemove}>
                    Remove
                </button>
            )}
        </div>
    );
};

const SponsorSelector = (props: ISponsorSelectorProps) => {
    const [searchText, setSearchText] = useState("");

    const [sponsorList, setSponsorList] = useState<ISponsor[]>([]);

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const searchResults = useMemo(
        () =>
            sponsorList.filter((sponsor) => sponsor.name.includes(searchText)),
        [sponsorList, searchText],
    );

    useEffectAsync(async () => {
        const sponsors = await harmony.get<ISponsor[]>(
            props.moduleId,
            "LoadSponsorList",
        );

        sponsors.sort((left, right) =>
            left.name
                .toLocaleLowerCase()
                .localeCompare(right.name.toLocaleLowerCase()),
        );
        setSponsorList(sponsors);

        // prefill search results with current sponsor's name or not?
        // setSearchText(currentSponsorId.name);
    }, [props.currentSponsorId]);

    const selectedCustom =
        props.currentSponsorId !== -1
            ? sponsorList.find(
                  (sponsor) => sponsor.id === props.currentSponsorId,
              )
            : null;

    return (
        <>
            <h2>Select a custom sponsor</h2>
            <div>
                <input
                    className="search-textbox"
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                    placeholder="Enter a custom Sponsor Name"
                />
                <button
                    className="upload-button"
                    onClick={() => setUploadDialogOpen(true)}
                >
                    <FontAwesomeIcon icon={faPlus} /> Upload
                </button>
                <Modal
                    open={uploadDialogOpen}
                    onClose={() => setUploadDialogOpen(false)}
                    className="upload-dialog"
                >
                    <SponsorUploader
                        moduleId={props.moduleId}
                        onFinish={(sponsorId) => {
                            // A useEffect will load the current sponsor when the sponsor is changed
                            // This will then load the rest of the details
                            props.setSponsor({
                                id: sponsorId,
                                name: "",
                                type: "image",
                                url: "",
                            });
                            setUploadDialogOpen(false);
                        }}
                    />
                </Modal>
            </div>
            <br />
            <div>
                {selectedCustom && (
                    <SponsorItem
                        key={selectedCustom.id}
                        name={`${selectedCustom.name} (Current)`}
                        icon={
                            <SponsorIcon
                                moduleId={props.moduleId}
                                sponsor={selectedCustom}
                            />
                        }
                        onRemove={props.removeSponsor}
                    />
                )}
            </div>
            <br />
            <div>
                {searchResults &&
                    searchResults.map((sponsor) =>
                        sponsor.id !== props.currentSponsorId ? (
                            <SponsorItem
                                key={sponsor.id}
                                name={sponsor.name}
                                icon={
                                    <SponsorIcon
                                        moduleId={props.moduleId}
                                        sponsor={sponsor}
                                    />
                                }
                                onSelect={() => {
                                    props.setSponsor(sponsor);
                                }}
                            />
                        ) : null,
                    )}
            </div>
        </>
    );
};

export default SponsorSelector;
