import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    CompositorDetails,
    CompositorMessageType,
    CompositorTruckDetails,
} from "resources/harmonyTypes";

const initialState: CompositorMessageType = {};

const slice = createSlice({
    name: "compositor",
    initialState,
    reducers: {
        compositorInitial: (_, action: PayloadAction<CompositorMessageType>) =>
            action.payload,
    },
});

export default slice.reducer;

export const compositorInitial = slice.actions.compositorInitial;

export const getTruckById = (
    compositorState: CompositorMessageType,
    truckId: string,
): CompositorTruckDetails | undefined => {
    for (const [compositorId, compositor] of Object.entries(compositorState)) {
        const truckState = compositor.trucks[truckId];
        if (truckState) {
            return truckState && { ...truckState, compositorId };
        }
    }

    return undefined;
};

export const getCompositorByTruckId = (
    compositorState: CompositorMessageType,
    truckId: string,
): CompositorDetails | null => {
    for (const [_compositorId, compositor] of Object.entries(compositorState)) {
        const truckState = compositor.trucks[truckId];
        if (truckState) {
            return compositor;
        }
    }
    return null;
};

/*
export const trucksReducer = handleActions({
    [ActionTypes.COMPOSITOR_INITIAL]: (state, action) => {
        const compositorMessage: CompositorMessageType = action.payload;

        // The keys are the compositors
        const allTrucks = Object.keys(compositorMessage)
            .map(compositorId => {
                // Attach the compositor id to the truck
                const trucks: any = {...compositorMessage[compositorId].trucks};
                Object.keys(trucks).forEach((truckId) => {
                    trucks[truckId] = {...trucks[truckId], compositorId};
                });

                return trucks as { [truckId: string]: TruckDetails & {compositorId: string}};
            })
            .reduce((memo, trucks) => ({ ...memo, ...trucks }), {});

        return ({ ...allTrucks });
    }
}, INITIAL_STATE);
*/
