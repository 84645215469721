import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormButton from "../Forms/FormButton";

import "./styles.less";

const RETRY_TIME_SECONDS = 10;

interface IProps {
    fallbackRoute: string;
}

export default function Reconnecting(props: IProps) {
    const { fallbackRoute } = props;
    const navigate = useNavigate();
    const handleCancel = useCallback(() => {
        navigate(fallbackRoute);
    }, [fallbackRoute, navigate]);

    const [retryTimeRemainingSeconds, setRetryTimeRemainingSeconds] =
        useState(RETRY_TIME_SECONDS);
    useEffect(() => {
        const retryUntilMillis =
            new Date().valueOf() + RETRY_TIME_SECONDS * 1000;
        const interval = setInterval(() => {
            const retryTimeRemainingMillis =
                retryUntilMillis - new Date().valueOf();

            if (retryTimeRemainingMillis <= 0) {
                handleCancel();
                return null;
            }

            setRetryTimeRemainingSeconds(
                Math.round(retryTimeRemainingMillis / 1000),
            );
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [handleCancel]);

    return (
        <div className="reconnecting">
            <p>
                Attempting to reconnect, giving up in{" "}
                {retryTimeRemainingSeconds} seconds...
            </p>
            <FormButton title="Cancel" onClick={handleCancel} />
        </div>
    );
}
