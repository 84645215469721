import React from "react";
import "./Icons.less";

const TickIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            version="1.1"
            width="24px"
            height="24px"
            className="lv-icon-tick"
        >
            <g id="surface1">
                <path d="M 22.59375 3.5 L 8.0625 18.1875 L 1.40625 11.5625 L 0 13 L 8.0625 21 L 24 4.9375 Z " />
            </g>
        </svg>
    );
};

export default TickIcon;
