export const calculatePlaceAmount =
    (numberOfPlaces: number) =>
    (place: number, amount = ""): string => {
        if (numberOfPlaces === 0) return "NPP";

        // If 3 (or more?) places, just show the amount
        if (numberOfPlaces > 2) return amount;

        // If only 2 places, show the amount for the first two horses, and 'NTD' for third
        if (numberOfPlaces === 2) {
            return place === 3 ? "NTD" : amount;
        }

        // If only 1 place, show 'NPP' for 2nd and 3rd horses
        return place === 1 ? amount : "NPP";
    };
