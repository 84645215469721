import React from "react";
import cn from "classnames";
import "./styles.less";

// How long it takes for the cell to flicker, should correspond to animation
// time in the .less file
const ANIMATION_TIMEOUT = 2000;

interface IHorsePriceTableCellState {
    delta: number;
}

interface IHorsePriceTableCellProps {
    price: number | string | undefined;
    name: string;
}

class HorsePriceTableCell extends React.Component<
    IHorsePriceTableCellProps,
    IHorsePriceTableCellState
> {
    public state: IHorsePriceTableCellState = { delta: 0 };

    public shouldComponentUpdate(
        nextProps: IHorsePriceTableCellProps,
        nextState: IHorsePriceTableCellState,
    ): boolean {
        const { price } = this.props;
        const { price: nextPrice } = nextProps;

        if (price !== nextPrice) return true;

        const { delta } = this.state;
        const { delta: nextDelta } = nextState;

        return delta !== nextDelta;
    }

    // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
    public UNSAFE_componentWillReceiveProps(
        nextProps: IHorsePriceTableCellProps,
    ): void {
        const { price: priceVal, name: nameVal } = this.props;
        const { price: nextPriceVal, name: nextNamVal } = nextProps;

        if (priceVal === undefined || nextPriceVal === undefined) {
            return;
        }

        const price =
            typeof priceVal === "number" ? priceVal : parseFloat(priceVal);
        const nextPrice =
            typeof nextPriceVal === "number"
                ? nextPriceVal
                : parseFloat(nextPriceVal);

        if (isNaN(price) || isNaN(nextPrice)) return;

        let delta = nextPrice - price;
        if (nameVal !== nextNamVal) {
            delta = 0;
        }

        if (delta !== 0) {
            this.setState(() => ({ delta }));
        }
    }

    render(): JSX.Element {
        const { delta } = this.state;
        const { price } = this.props;

        const classNames = cn("price-cell", {
            "amount-delta-up": delta > 0,
            "amount-delta-down": delta < 0,
        });

        return <td className={classNames}>{price}</td>;
    }

    public componentDidUpdate() {
        const { delta } = this.state;
        if (isNaN(delta) || delta === 0) return;

        const removeDelta = () =>
            this.setState((prevState) => {
                return prevState.delta === delta ? { delta: 0 } : null;
            });

        setTimeout(removeDelta, ANIMATION_TIMEOUT);
    }
}

export default HorsePriceTableCell;
