import { useCallback, useEffect, useRef, useState } from "react";
import Checkbox from "components/Forms/Checkbox";
import leaflet from "leaflet";
import "leaflet.fullscreen";
import "leaflet/dist/leaflet.css";
import "leaflet.fullscreen/Control.FullScreen.css";

import { useAppSelector } from "../../../../state/hooks";

import saddleCloths from "resources/saddle-cloths";

import "./TrackMap.css";

type TrackMapProps = {
    moduleId: string;
    selectedVenue: string;
    selectedEnvironment: string;
    selectedRace: number;
};

const DEFAULT_ZOOM = 16;
const FOLLOW_LEADER_ZOOM = 20;

export default function TrackMap(props: TrackMapProps) {
    const map = useRef<leaflet.Map | undefined>();
    const markers = useRef<Record<string, leaflet.Marker>>({});
    const [shouldFollow, setFollow] = useState(true);
    const mapElementCallback = useCallback((element: HTMLDivElement) => {
        if (element && __MAPBOX_ACCESS_TOKEN__) {
            map.current = leaflet
                .map(element)
                .addLayer(
                    leaflet.tileLayer(
                        `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${__MAPBOX_ACCESS_TOKEN__}`,
                        {
                            attribution:
                                '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
                            maxZoom: 21,
                        },
                    ),
                )
                .addControl(
                    leaflet.control.fullscreen({
                        position: "topleft",
                        forceSeparateButton: true,
                    }),
                );
        } else {
            map.current = undefined;
            markers.current = {};
        }
    }, []);

    const [lat, long] = useAppSelector((state) => {
        const event =
            state.tripleSModuleState.venues?.[props.selectedVenue]?.[
                props.selectedEnvironment
            ];
        return [event?.lat, event?.long];
    });

    const eventType = useAppSelector((state) => {
        return state.tripleSModuleState.venues?.[props.selectedVenue]?.[
            props.selectedEnvironment
        ]?.venueType;
    });

    useEffect(() => {
        if (lat && long) {
            map.current?.setView([lat, long], DEFAULT_ZOOM);
        }
    }, [lat, long]);

    const positions = useAppSelector((state) => {
        return state.tripleSModuleState.venues?.[props.selectedVenue]?.[
            props.selectedEnvironment
        ]?.races[props.selectedRace]?.positions;
    });

    useEffect(() => {
        if (!map.current) {
            return;
        }

        for (const [id, marker] of Object.entries(markers.current)) {
            if (!positions?.[id]?.lat || !positions?.[id]?.long) {
                delete markers.current[id];
                marker.remove();
            }
        }

        if (positions) {
            for (const [id, position] of Object.entries(positions)) {
                if (!position.lat || !position.long) {
                    continue;
                }

                const marker = markers.current[id];
                if (marker) {
                    marker.setLatLng([position.lat, position.long]);
                } else {
                    const harnessMarker = () => {
                        const img = document.createElement("img");
                        img.src =
                            saddleCloths[props.moduleId]?.[parseInt(id)] ?? "";
                        if (img.src) img.style.backgroundColor = "transparent";
                        img.className = "track-map-chiclet";
                        return img;
                    };
                    const horseRacingMarker = () => {
                        const div = document.createElement("div");
                        div.textContent = id;
                        div.style.backgroundColor = position.baseColour;
                        div.style.color = position.textColour;
                        div.className = "track-map-chiclet";
                        return div;
                    };

                    const marker =
                        eventType == "H"
                            ? harnessMarker()
                            : horseRacingMarker();
                    const icon = leaflet.divIcon({
                        html: marker,
                        iconSize: undefined,
                        className: undefined,
                    });
                    markers.current[id] = leaflet
                        .marker([position.lat, position.long], { icon })
                        .addTo(map.current);
                }
            }
            const firstHorse = Object.values(positions).find(
                (p) => p.rank == 1,
            );
            if (shouldFollow && firstHorse?.lat && firstHorse?.long) {
                map.current?.setView(
                    [firstHorse.lat, firstHorse.long],
                    FOLLOW_LEADER_ZOOM,
                );
            }
        }
    }, [positions, props.moduleId, eventType, shouldFollow]);

    if (!lat || !long) {
        return null;
    }

    return (
        <>
            <div ref={mapElementCallback} className="track-map" />
            <div className="follow-leader-checkbox">
                <Checkbox
                    title="Follow leader"
                    checked={shouldFollow}
                    onChange={(b) => {
                        setFollow(b);

                        // Reset map view if not following leader
                        if (!b) map.current?.setView([lat, long], DEFAULT_ZOOM);
                    }}
                />
            </div>
        </>
    );
}
