import { ModuleType } from "components/ModulePage/ModulePage.Redux";
import { CompositorMessageType } from "resources/harmonyTypes";
import type { IModuleStateUpdate as ATCModuleStateUpdate } from "modules/atc/redux/Module.Redux";
import type { IUpdateModuleState as TripleSModuleStateUpdate } from "modules/triples/redux/redux";

export type HarmonyMessageHandlers = {
    onStartConnecting(): void;
    onConnected(): void;
    onDisconnected(): void;
    updateCompositors(compositors: CompositorMessageType): void;
    updateAvailableModules(modules: ModuleType[]): void;
    updateModuleDashboardState(
        state: ATCModuleStateUpdate | TripleSModuleStateUpdate,
    ): void;
};

export default class HarmonyClient {
    private isRunning: boolean;
    private client: WebSocket | null = null;
    private reconnectInterval = 500;
    private reconnectTimeout: number | null = null;

    constructor(
        private readonly messageHandlers: HarmonyMessageHandlers,
        private readonly harmonyUri: string,
        private readonly tenant?: string | undefined,
        private readonly token?: string | undefined,
    ) {
        this.isRunning = true;
        this.reconnect();
    }

    disconnect() {
        if (this.client?.readyState == 1) {
            this.send("close");
        }

        if (this.reconnectTimeout) {
            window.clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }

        this.isRunning = false;
        this.client && this.client.close();
        this.client = null;
    }

    preload<T>(truckId: string, moduleId: string, preloadContext: T) {
        this.send("preload", {
            truckId,
            moduleId,
            preloadContext,
        });
    }

    showComponent<C, V>(
        truckId: string,
        moduleId: string,
        componentId: string,
        componentContext: C,
        visualContext: V,
        componentInstanceId: string | undefined,
    ) {
        this.send("showComponent", {
            truckId,
            moduleId,
            componentId,
            componentContext,
            visualContext,
            componentInstanceId,
        });
    }

    hideComponent(
        truckId: string,
        moduleId: string,
        componentId: string,
        componentInstanceId: string | undefined,
    ) {
        this.send("hideComponent", {
            truckId,
            moduleId,
            componentId,
            componentInstanceId,
        });
    }

    startTruckSession(compositorKey: string, venkmanKey: string) {
        this.send("openWindow", { compositorKey, venkmanKey });
    }

    stopTruckSession(truckId: string) {
        this.send("stopTruck", { truckId });
    }

    restartCompositor(compositorKey: string, venkmanKey: string) {
        this.send("restartCompositor", { compositorKey, venkmanKey });
    }

    showModuleDashboard(moduleId: string) {
        this.send("showModuleDashboard", { moduleId });
    }

    hideModuleDashboard(moduleId: string) {
        this.send("hideModuleDashboard", { moduleId });
    }

    updateModuleDashboardData<T>(moduleId: string, data: T) {
        this.send("moduleDashboardData", { moduleId, data });
    }

    // TODO remove this and only use the above calls
    sendFromRedux(message: string) {
        if (!this.client || this.client.readyState != 1) {
            throw new Error("Socket is not connected.");
        }

        this.client.send(message);
    }

    private send(messageType: string, data?: unknown) {
        if (!this.client || this.client.readyState != 1) {
            throw new Error("Socket is not connected.");
        }

        const message = JSON.stringify({ msg: messageType, data });
        this.client.send(message);
    }

    private reconnect() {
        let uri = `${this.harmonyUri}/grub`;
        const args = [];
        if (this.tenant != undefined) {
            args.push(`loginAsTenant=${this.tenant}`);
        }
        if (this.token != undefined) {
            args.push(`token=${this.token}`);
        }
        if (args.length) {
            uri += "?" + args.join("&");
        }

        try {
            this.client = new WebSocket(uri, "json");
            this.messageHandlers.onStartConnecting();

            this.client.onmessage = ({ data }) => this.handleMessage(data);
            this.client.onopen = () => this.handleOpen();
            this.client.onclose = () => this.handleClose();
        } catch (error) {
            this.handleClose();
        }
    }

    private handleMessage(raw: string) {
        // TODO we should properly handle invalid message data
        const message = JSON.parse(raw);
        if (typeof message != "object" || typeof message?.msg != "string") {
            throw new Error(`Invalid message format`);
        }

        switch (message.msg) {
            case "compositorState":
                this.messageHandlers.updateCompositors(message.data);
                break;
            case "avilableModules":
                this.messageHandlers.updateAvailableModules(message.data);
                break;
            case "moduleDashboardState":
                this.messageHandlers.updateModuleDashboardState(message.data);
                break;
            default:
                throw new Error(`Unknown message type '${message.msg}'`);
        }
    }

    private handleOpen() {
        // reset exponential backoff interval on successful connection
        this.reconnectInterval = 500;

        this.send("ready", {
            browserVersion: navigator.userAgent,
            width: window.innerWidth,
            height: window.innerHeight,
        });

        this.messageHandlers.onConnected();
    }

    private handleClose() {
        if (this.client) {
            this.client = null;
        }

        this.messageHandlers.onDisconnected();

        if (this.isRunning) {
            this.reconnectTimeout = window.setTimeout(() => {
                if (this.isRunning) {
                    this.reconnect();
                    this.reconnectTimeout = null;
                }
            }, this.reconnectInterval);

            // exponential backoff with maximum of 10 seconds
            this.reconnectInterval *= 1.5;
            this.reconnectInterval = Math.min(this.reconnectInterval, 10000);
        }
    }
}
