import Modal from "components/Modal";
import React from "react";
import { connect } from "react-redux";

import ComponentButton from "../../../atc/components/ComponentButton/ComponentButton";
import { selectedRaceChanged } from "../../redux/Module.Redux";

import SponsorSelector from "./SponsorSelector";
import { ISponsor } from "modules/triples/types";
import SponsorIcon from "../AssetTypes/Sponsor/SponsorIcon";

import "./RaceControls.styles.less";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import useEffectAsync from "core/hooks/useEffectAsync";
import { useAppSelector } from "state/hooks";

interface RaceControlsProps {
    venkmanName: string;
    moduleId: string;
    selectedEnvironment: string;
    selectedDate: string;
    selectedVenue: string;
    selectedRace: number;
}

export enum Components {
    TimingSponsor = "lf_timingSponsor",
    RunningDistance = "lf_runningDistance",
    RunningClock = "lf_runningClock",
    JockeyPositions = "lf_jockeyPositions",
    StageTimings = "lf_runningStages",
    TrackProgress = "lf_trackMap",
    Chiclets = "lf_chiclets",
    QuarterSectionals = "lf_quarterSectionals",
    RaceTitle = "lf_raceTitle",
    EventTitle = "lf_eventTitle",
    TopSpeed = "lf_topSpeed",
    MileRate = "lf_mileRate",
    Watermark = "lf_watermark",
    Weather = "lf_weather",
}

const moduleComponents: Record<
    string,
    { auto: Components[]; manual: Components[] }
> = {
    TripleS: {
        auto: [
            Components.RunningClock,
            Components.RunningDistance,
            Components.StageTimings,
            Components.TrackProgress,
            Components.TimingSponsor,
            Components.JockeyPositions,
        ],
        manual: [],
    },
    SkySTC: {
        auto: [
            Components.RunningClock,
            Components.RunningDistance,
            Components.StageTimings,
            Components.TrackProgress,
            Components.TimingSponsor,
            Components.Chiclets,
            Components.JockeyPositions,
        ],
        manual: [],
    },
    SkyOne: {
        auto: [
            Components.RunningClock,
            Components.RunningDistance,
            Components.StageTimings,
            Components.TrackProgress,
            Components.TimingSponsor,
            Components.Chiclets,
            Components.JockeyPositions,
        ],
        manual: [],
    },
    SkyHarness: {
        auto: [
            Components.RunningClock,
            Components.TrackProgress,
            Components.TimingSponsor,
            Components.Chiclets,
            Components.QuarterSectionals,
            Components.TopSpeed,
            Components.MileRate,
        ],
        manual: [],
    },
    HRV: {
        auto: [
            Components.RunningClock,
            Components.RunningDistance,
            Components.TrackProgress,
            Components.TimingSponsor,
            Components.Chiclets,
            Components.QuarterSectionals,
            Components.TopSpeed,
            Components.MileRate,
        ],
        manual: [],
    },
    MRC: {
        auto: [
            Components.RunningClock,
            Components.RunningDistance,
            Components.StageTimings,
            Components.JockeyPositions,
        ],
        manual: [],
    },
    QldHorseRacing: {
        auto: [
            Components.RunningClock,
            Components.RunningDistance,
            Components.StageTimings,
            Components.TrackProgress,
            Components.TimingSponsor,
            Components.Chiclets,
            Components.RaceTitle,
            Components.JockeyPositions,
        ],
        manual: [],
    },
    QldHarnessRacing: {
        auto: [
            Components.RunningClock,
            Components.RunningDistance,
            Components.StageTimings,
            Components.TrackProgress,
            Components.TimingSponsor,
            Components.JockeyPositions,
            Components.Chiclets,
            Components.RaceTitle,
            Components.QuarterSectionals,
            Components.TopSpeed,
            Components.MileRate,
        ],
        manual: [],
    },
    BreezeUp: {
        auto: [Components.JockeyPositions, Components.RunningClock],
        manual: [],
    },
};

export function getComponentsForModule(moduleId: string) {
    return (
        moduleComponents[moduleId] ?? {
            auto: [],
            manual: [],
        }
    );
}

function RaceControls(props: RaceControlsProps) {
    const [sponsorSelectorOpen, setSponsorSelectorOpen] = React.useState(false);
    const [currentSponsor, setCurrentSponsor] = React.useState<
        ISponsor | undefined
    >();

    const { selectedEnvironment, selectedDate, selectedVenue, selectedRace } =
        props;

    const venueName = useAppSelector(
        (state) =>
            state.tripleSModuleState.venues[selectedVenue]?.[
                selectedEnvironment
            ]?.venueName ?? null,
    );

    useEffectAsync(async () => {
        const sponsor = await harmony.get<ISponsor>(
            props.moduleId,
            `SponsorForVenue?venueId=${props.selectedVenue}`,
        );
        if (sponsor.id !== currentSponsor?.id) {
            setCurrentSponsor(sponsor);
        }
    }, [currentSponsor?.id, props.moduleId, props.selectedVenue]);

    if (!selectedRace) {
        return null;
    }

    const componentContextWithoutRace = {
        date: selectedDate,
        venueId: selectedVenue,
        environment: selectedEnvironment,
    };

    const componentContext = {
        date: selectedDate,
        venueId: selectedVenue,
        environment: selectedEnvironment,
        race: selectedRace,
    };

    const componentTypes = getComponentsForModule(props.moduleId);

    const allComponents = [...componentTypes.auto, ...componentTypes.manual];

    const handleSetSponsor = async (newSponsorId: number) => {
        await harmony.post(props.moduleId, "SetSponsorForVenue", {
            selectedDate,
            sponsorId: newSponsorId,
            venueId: selectedVenue,
            venueName,
        });
    };

    const handleRemoveSponsor = async () => {
        await harmony.post(props.moduleId, "RemoveSponsorForVenue", {
            selectedDate,
            venueId: selectedVenue,
        });
    };

    const renderSponsorButtonWithSelector = () => {
        return (
            <>
                <ComponentButton
                    title="Sponsor"
                    componentType={Components.TimingSponsor}
                    componentContext={componentContext}
                    customOnClickOption={() => setSponsorSelectorOpen(true)}
                    visualContext={{
                        sponsorId: currentSponsor?.id ?? -1,
                    }}
                />
                <div className="current-sponsor-preview">
                    {currentSponsor && currentSponsor.id !== -1 ? (
                        <SponsorIcon
                            moduleId={props.moduleId}
                            sponsor={currentSponsor}
                        />
                    ) : (
                        "* No sponsor selected"
                    )}
                </div>
                <br />
                <Modal
                    open={sponsorSelectorOpen}
                    onClose={() => setSponsorSelectorOpen(false)}
                    className="sponsor-modal"
                >
                    <SponsorSelector
                        moduleId={props.moduleId}
                        selectedVenue={componentContext.venueId}
                        currentSponsorId={currentSponsor?.id ?? -1}
                        setSponsor={(sponsor) => {
                            handleSetSponsor(sponsor.id).finally(() => {
                                setCurrentSponsor(sponsor);
                                setSponsorSelectorOpen(false);
                            });
                        }}
                        removeSponsor={() => {
                            handleRemoveSponsor().finally(() => {
                                setCurrentSponsor(undefined);
                                setSponsorSelectorOpen(false);
                            });
                        }}
                    />
                </Modal>
            </>
        );
    };

    const renderButtons = () => {
        return (
            <div className="button-column">
                <ComponentButton
                    title="Start All Components"
                    componentType={componentTypes.auto}
                    componentContext={componentContext}
                    visualContext={{
                        sponsorId: currentSponsor?.id ?? -1,
                    }}
                />
                <br />
                <div>Individual Controls</div>
                {allComponents.includes(Components.TimingSponsor) &&
                    renderSponsorButtonWithSelector()}
                {allComponents.includes(Components.RunningClock) && (
                    <ComponentButton
                        title="Running Clock"
                        componentType={Components.RunningClock}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.RunningDistance) && (
                    <ComponentButton
                        title="Running Distance"
                        componentType={Components.RunningDistance}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.JockeyPositions) && (
                    <ComponentButton
                        title="Jockey Positions"
                        componentType={Components.JockeyPositions}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.StageTimings) && (
                    <ComponentButton
                        title="Stage Timings"
                        componentType={Components.StageTimings}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.TrackProgress) && (
                    <ComponentButton
                        title="Track Progress"
                        componentType={Components.TrackProgress}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.Chiclets) && (
                    <>
                        <br />
                        <hr />
                        <br />
                        <ComponentButton
                            title="Chiclets"
                            componentType={Components.Chiclets}
                            componentContext={componentContext}
                        />
                    </>
                )}
                {allComponents.includes(Components.RaceTitle) ||
                    allComponents.includes(Components.QuarterSectionals) ||
                    allComponents.includes(Components.TopSpeed) ||
                    (allComponents.includes(Components.MileRate) && (
                        <>
                            <br />
                            <hr />
                            <br />
                        </>
                    ))}
                {allComponents.includes(Components.QuarterSectionals) && (
                    <ComponentButton
                        title="Quarters"
                        componentType={Components.QuarterSectionals}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.TopSpeed) && (
                    <ComponentButton
                        title="Top Speed"
                        componentType={Components.TopSpeed}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.MileRate) && (
                    <ComponentButton
                        title="Mile Rate"
                        componentType={Components.MileRate}
                        componentContext={componentContext}
                    />
                )}
                {allComponents.includes(Components.Weather) && (
                    <>
                        <br />
                        <hr />
                        <br />
                        <ComponentButton
                            title="Weather"
                            componentType={Components.Weather}
                            componentContext={componentContextWithoutRace}
                        />
                    </>
                )}
                {allComponents.includes(Components.RaceTitle) && (
                    <>
                        <br />
                        <hr />
                        <br />
                        <ComponentButton
                            title="Race Title"
                            componentType={Components.RaceTitle}
                            componentContext={componentContext}
                        />
                    </>
                )}
                {allComponents.includes(Components.EventTitle) && (
                    <ComponentButton
                        title="Event Title"
                        componentType={Components.EventTitle}
                        componentContext={componentContextWithoutRace}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <div className="race-controls">
                <div>{renderButtons()}</div>
            </div>
        </>
    );
}

export default connect(null, {
    setSelectedRace: selectedRaceChanged,
})(RaceControls);
