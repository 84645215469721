import { connect } from "react-redux";
import { IGlobalState } from "../../../../state/store";
import { WageringProduct } from "../../types";

import "./ExoticsTable.less";

interface IProps {
    dividends: WageringProduct[];
}

const ExoticsTableRow = ({
    dividend,
}: {
    dividend: WageringProduct;
}): JSX.Element => {
    return (
        <tr>
            <td>{dividend.name}</td>
            <td className="exotic-numbers-cell">
                {dividend.selections.join("-")}
            </td>
            <td className="price-cell">{dividend.amount.toFixed(2)}</td>
        </tr>
    );
};

const ExoticsTable = ({ dividends }: IProps) => {
    if (!dividends.length) {
        return null;
    }

    return (
        <table className="table exotics-table">
            <thead>
                <tr>
                    <th colSpan={3}>Exotic Results</th>
                </tr>
            </thead>
            <tbody>
                {dividends.map((dividend) => (
                    <ExoticsTableRow
                        key={`${dividend.name}_${dividend.selections.join(
                            "|",
                        )}`}
                        dividend={dividend}
                    />
                ))}
            </tbody>
        </table>
    );
};

const mapStateToProps = (state: IGlobalState): IProps => {
    const {
        moduleState: { dividends },
    } = state;

    return {
        dividends: (dividends && dividends.dividends) || [],
    };
};

export default connect(mapStateToProps)(ExoticsTable);
