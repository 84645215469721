import { useState } from "react";
import ImageDropZone from "components/ImageDropZone";
import FormButton from "components/Forms/FormButton";

type Props = {
    onImageUploaded: (image: File) => void;
};

export default function VenueImageUploader(props: Props) {
    const [image, setImage] = useState<File>();

    return (
        <div className="venue-image-uploader">
            <div className="dialog-title-bar">
                Upload a new image (1920 x 1080)
            </div>

            <ImageDropZone
                height={400}
                image={image}
                imageRatio={[1920, 1080]}
                onImageUploaded={(f) => setImage(f ?? undefined)}
            />

            <div className="dialog-action-bar">
                <FormButton
                    title="Upload"
                    disabled={!image}
                    onClick={handleImageSaved}
                />
            </div>
        </div>
    );

    function handleImageSaved() {
        if (image) {
            props.onImageUploaded(image);
            setImage(undefined);
        }
    }
}
