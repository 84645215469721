import { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { CompositorDetails, ModelState } from "resources/harmonyTypes";
import {
    startTruckSession,
    stopTruckSession,
} from "../../../ModulePage/ModulePage.Redux";
import { useAppDispatch } from "../../../../state/hooks";

import "./style.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv, faPlug } from "@fortawesome/free-solid-svg-icons";

interface IOwnProps {
    compositor: CompositorDetails;
}

export default function StartTruckSessionButton(props: IOwnProps) {
    const [triggered, setTriggered] = useState(false);
    const [connectingTruckId, setConnectingTruckId] = useState<string>();

    const dispatch = useAppDispatch();

    const { compositor } = props;

    useEffect(() => {
        const newConnectingTruckId = Object.entries(compositor.trucks).find(
            (truck) => truck[1].state === ModelState.Connecting,
        )?.[0];

        if (!newConnectingTruckId && connectingTruckId) {
            setTriggered(false);
        }

        setConnectingTruckId(newConnectingTruckId);
    }, [compositor, connectingTruckId]);

    const handleClick = useCallback(() => {
        if (!triggered) {
            dispatch(
                startTruckSession(
                    compositor.compositorKey,
                    compositor.venkman.id,
                ),
            );
        } else {
            Object.keys(compositor.trucks).forEach((truckId) =>
                dispatch(stopTruckSession(truckId)),
            );
        }

        setTriggered(!triggered);
    }, [triggered, compositor, dispatch]);

    const buttonClassNames = cn("plug", {
        plugMode: triggered && !connectingTruckId,
        plugging: triggered && connectingTruckId,
    });

    const message = !triggered ? (
        "Start New Truck Session"
    ) : (
        <div>
            <span>{connectingTruckId ? "Connecting" : "Starting"}</span>
            <Dots />
        </div>
    );

    return (
        <div className="start-truck-session">
            <div className="inner" onClick={handleClick}>
                <div className="icons">
                    <FontAwesomeIcon icon={faTv} size="8x" />
                    <FontAwesomeIcon
                        icon={faPlug}
                        size="8x"
                        className={buttonClassNames}
                    />
                </div>
                <div className="message">
                    <div>{message}</div>
                </div>

                <div className="cancel">
                    {triggered && "Click to cancel request"}
                </div>
            </div>
        </div>
    );
}

function Dots() {
    const [numDots, setNumDots] = useState(3);

    useEffect(() => {
        const interval = setInterval(() => {
            setNumDots((d) => (d + 1) % 5);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    return <span style={{ position: "absolute" }}>{".".repeat(numDots)}</span>;
}
