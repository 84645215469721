import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch } from "../../../state/store";

import { IModuleState, IUpdateModuleState, Position } from "./redux";

import {
    updateModuleDashboardData,
    updateModuleState,
} from "../../../components/ModulePage/ModulePage.Redux";

export const DashboardDataTypes = {
    SELECTED_DATE: "SelectedDate",
    SELECTED_RACE: "SelectedRace",
};

export const ActionTypes = {
    SET_SELECTED_DATE: "TRIPLES/SET_SELECTED_DATE",
    SET_SELECTED_VENUE: "TRIPLES/SET_SELECTED_VENUE",
    SET_SELECTED_ENVIRONMENT: "TRIPLES/SET_SELECTED_ENVIRONMENT",
    SET_SELECTED_RACE: "TRIPLES/SET_SELECTED_RACE",
};

export const selectedDateChanged =
    (date: string | null) => (dispatch: AppDispatch) => {
        dispatch(slice.actions.setSelectedDate(date));
        if (date != null) {
            updateModuleDashboardData(DashboardDataTypes.SELECTED_DATE, {
                date,
            })(dispatch);
        }
    };

export const selectedVenueChanged =
    (venue: string | null) => (dispatch: AppDispatch) => {
        dispatch(slice.actions.setSelectedVenue(venue));
    };

export const selectedEnvironmentChanged =
    (environment: string | null) => (dispatch: AppDispatch) => {
        dispatch(slice.actions.setSelectedEnvironment(environment));
    };

export const selectedRaceChanged =
    (
        date: string | null,
        venueId: string | null,
        environment: string | null,
        raceNumber: number | null,
    ) =>
    (dispatch: AppDispatch) => {
        dispatch(slice.actions.setSelectedRace(raceNumber));
        if (raceNumber != null) {
            updateModuleDashboardData(DashboardDataTypes.SELECTED_RACE, {
                date,
                venueId,
                environment,
                raceNumber,
            })(dispatch);
        }
    };

const initialState: IModuleState = {
    showGraphicsTest: false,
    dates: [],
    selectedDate: null,
    selectedVenue: null,
    selectedEnvironment: null,
    selectedRace: null,
    venues: {},
    heartbeat: {
        traceLastSeen: "",
        clockLastSeen: "",
    },
    raceState: "Unknown",
};

const slice = createSlice({
    name: "tripleSModule",
    initialState,
    reducers: {
        setSelectedDate: (state, action: PayloadAction<string | null>) => {
            state.selectedDate = action.payload;
        },
        setSelectedVenue: (state, action: PayloadAction<string | null>) => {
            state.selectedVenue = action.payload;
        },
        setSelectedEnvironment: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.selectedEnvironment = action.payload;
        },
        setSelectedRace: (state, action: PayloadAction<number | null>) => {
            state.selectedRace = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateModuleState, (state, action) => {
            const {
                dates,
                venues,
                selectedDate,
                jockeyPositions,
                runningClock,
                tripleSHeartbeat,
                raceState,
            } = action.payload as IUpdateModuleState; // TODO this typecast is dodgy

            if (dates) {
                state.dates = dates;
            }

            if (venues) {
                // Keep the old jockeypositions if available
                Object.entries(state.venues).forEach(([venueId, venue]) => {
                    Object.entries(venue).forEach(
                        ([environment, envAtVenue]) => {
                            Object.entries(envAtVenue.races).forEach(
                                ([raceNumber, race]) => {
                                    if (!race.positions) return;

                                    const incomingRace =
                                        venues[venueId]?.[environment]?.races?.[
                                            // Object.Entries gives us
                                            // incorrectly typed string keys
                                            // instead of number
                                            raceNumber as unknown as number
                                        ];
                                    if (incomingRace) {
                                        incomingRace.positions = {
                                            ...race.positions,
                                        };
                                    }
                                },
                            );
                        },
                    );
                });
                state.venues = venues;
            }

            if (selectedDate !== undefined) {
                state.selectedDate = selectedDate;
            }

            if (tripleSHeartbeat) {
                state.heartbeat = tripleSHeartbeat;
            }

            if (raceState) {
                state.raceState = raceState;
            }

            if (jockeyPositions) {
                const {
                    venueId,
                    environment,
                    raceNumber,
                    lf_jockeyPositions: { positions },
                } = jockeyPositions;

                const positionsByHorseNumber: Record<number, Position> = [];
                positions.forEach(
                    (jockey) =>
                        (positionsByHorseNumber[jockey.number] = jockey),
                );

                const race =
                    state.venues[venueId]?.[environment]?.races?.[raceNumber];
                if (race) {
                    race.positions = positionsByHorseNumber;
                }
            }

            if (runningClock) {
                const {
                    venueId,
                    environment,
                    raceNumber,
                    lf_runningClock: runningClockData,
                } = runningClock;
                const race =
                    state.venues[venueId]?.[environment]?.races?.[raceNumber];
                if (race) {
                    race.clock = runningClockData;
                }
            }
        });
    },
});

export default slice.reducer;
