import React, { useState } from "react";
import "./ManualSuper.less";
import { useAppSelector, useAppDispatch } from "state/hooks";

import Checkbox from "../../../../../components/Forms/Checkbox";
import ComponentButton from "../../ComponentButton/ComponentButton";
import {
    setManualSuper,
    buildManualSuperKey,
} from "../../../redux/Module.Redux";
import {
    Components,
    HorseType,
    RaceIdentity,
    ManualSuperType,
} from "../../../types";

const MANUAL_SUPER_TYPE_MAP = {
    [ManualSuperType.Manual]: "Free form",
    [ManualSuperType.Jockey]: "Jockey",
    [ManualSuperType.Trainer]: "Trainer",
    [ManualSuperType.Horse]: "Horse",
};

const MANUAL_SUPER_OPTIONS = Object.entries(MANUAL_SUPER_TYPE_MAP).map(
    ([value, label]) => ({
        value,
        label,
    }),
);

type Props = {
    raceIdentity: RaceIdentity;
};

export default function ManualSuper(props: Props) {
    const { raceIdentity } = props;

    const [type, setType] = useState(ManualSuperType.Manual);
    const [sendSilk, setSendSilk] = useState(true);

    const dispatch = useAppDispatch();
    const runner = useAppSelector(
        (state) => state.moduleState.selectedHorse ?? undefined,
    );

    const key = { race: raceIdentity, horse: runner?.number, type };
    const { topLine, bottomLine } =
        useAppSelector(
            (state) => state.moduleState.manualSupers[buildManualSuperKey(key)],
        ) ?? getDefaultManualSuperContent(type, runner);

    const componentContext = {
        raceIdentity,
        topLine,
        bottomLine,
        horse: runner,
        silk: sendSilk ? runner?.silk : undefined,
    };

    const hasContext = componentContext.topLine || componentContext.bottomLine;

    return (
        <section className="manual-super">
            <div className="section-banner text-center">Field Edits</div>

            <form className="pure-form pure-form-stacked">
                <fieldset>
                    <div className="pure-g">
                        <div className="pure-u-1-3">
                            <label htmlFor="template">Template</label>
                            <select
                                className="pure-u-23-24 pure-dropdown"
                                value={type}
                                onChange={handleManualSuperTypeChanged}
                            >
                                {MANUAL_SUPER_OPTIONS.map(
                                    ({ value, label }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ),
                                )}
                            </select>
                        </div>

                        <div className="pure-u-1-3">
                            <label htmlFor="top-line">Top Line</label>
                            <input
                                id="top-line"
                                className="pure-u-23-24"
                                type="text"
                                value={topLine}
                                onChange={handleTopLineChanged}
                            />
                        </div>

                        <div className="pure-u-1-3">
                            <label htmlFor="bottom-line">Bottom Line</label>
                            <input
                                id="bottom-line"
                                className="pure-u-24-24"
                                type="text"
                                value={bottomLine}
                                onChange={handleBottomLineChanged}
                            />
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <div className="pure-g">
                        <div className="pure-u-1-3" />

                        <div className="pure-u-1-3 include-silk">
                            <Checkbox
                                title="Include Silk"
                                disabled={!runner}
                                checked={sendSilk}
                                onChange={handleSendSilkChanged}
                            />
                        </div>

                        <div className="pure-u-1-3">
                            <ComponentButton
                                title="Send & Render"
                                componentType={Components.manualSuper}
                                componentContext={componentContext}
                                disabled={!hasContext}
                            />
                        </div>
                    </div>
                </fieldset>
            </form>
        </section>
    );

    function handleManualSuperTypeChanged(
        event: React.ChangeEvent<HTMLSelectElement>,
    ) {
        setType(event.target.value as ManualSuperType);
    }

    function handleTopLineChanged(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setManualSuper(key, { topLine: event.target.value, bottomLine }),
        );
    }

    function handleBottomLineChanged(
        event: React.ChangeEvent<HTMLInputElement>,
    ) {
        dispatch(
            setManualSuper(key, { topLine, bottomLine: event.target.value }),
        );
    }

    function handleSendSilkChanged(checked: boolean) {
        setSendSilk(checked);
    }
}

function getDefaultManualSuperContent(
    type: ManualSuperType,
    runner: HorseType | undefined,
) {
    if (runner) {
        switch (type) {
            case ManualSuperType.Jockey:
                return {
                    topLine: `${runner.jockey}`,
                    bottomLine: `Jockey - ${runner.number}. ${runner.name} (${runner.barrierNumber})`,
                };

            case ManualSuperType.Trainer:
                return {
                    topLine: `${runner.trainer}`,
                    bottomLine: `Trainer - ${runner.number}. ${runner.name} (${runner.barrierNumber})`,
                };

            case ManualSuperType.Horse:
                return {
                    topLine: `${runner.name}`,
                    bottomLine: `${runner.barrierNumber}`,
                };
        }
    }

    return { topLine: "", bottomLine: "" };
}
