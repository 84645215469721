export default function formatTime(
    totalMilliseconds: number,
    millisecondsPlaces = 1,
) {
    const totalSeconds = totalMilliseconds / 1000;
    const totalWholeSeconds = Math.floor(totalSeconds);
    const seconds = totalWholeSeconds % 60;
    const minutes = (totalWholeSeconds - seconds) / 60;

    let secondsDisplay: string, minutesDisplay: string;
    if (minutes > 0) {
        secondsDisplay = seconds.toString().padStart(2, "0");
        minutesDisplay = `${minutes}:`;
    } else {
        secondsDisplay = seconds.toString();
        minutesDisplay = "";
    }

    const milliseconds = totalMilliseconds - totalWholeSeconds * 1000;
    const millisecondsDisplay = milliseconds
        .toString()
        .padStart(3, "0")
        .substring(0, millisecondsPlaces)
        .padEnd(millisecondsPlaces, "0");

    return `${minutesDisplay}${secondsDisplay}.${millisecondsDisplay}`;
}
