import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch } from "../../state/store";
import { compositorInitial } from "../../state/Compositor.redux";
import {
    updateModuleState,
    updateAvailableModules,
} from "../ModulePage/ModulePage.Redux"; // circular dependency?
import Guid, { newGuid } from "../../core/types/Guid";
import { OutGoing } from "./GrubContracts";

export const ActionTypes = {
    CONNECTION_CONNECTING: `connection connecting`,
    CONNECTION_ESTABLISHED: `connection established`,
    //CONNECTION_CLOSING: `connection closing`,
    CONNECTION_CLOSED: `connection closed`,

    SEND_MESSAGE: "SEND_MESSAGE",
    MESSAGE_SENT: "MESSAGE_SENT",

    TRUCK_INITIAL: "TRUCK_INITIAL",
    MODULE_DASHBOARD_STATE: "MODULE_DASHBOARD_STATE",
    //MESSAGE_RECEIVED: `message received`
};

const MessageTypes = {
    COMPOSITOR_STATE: "compositorState",
    AVAILABLE_MODULES: "avilableModules", // Have to spell this wrong because it it's spelled wrong in Harmony?
    TRUCK_STATE: "truckState",
    MODULE_DASHBOARD_STATE: "moduleDashboardState",
};

export enum SocketState {
    Connected,
    Connecting,
    Disconnected,
}

export const socketStateChange =
    (state: SocketState) => (dispatch: AppDispatch) => {
        switch (state) {
            case SocketState.Connected:
                dispatch(slice.actions.connectionEstablished());
                break;
            case SocketState.Connecting:
                dispatch(slice.actions.connectionConnecting());
                break;
            case SocketState.Disconnected:
                dispatch(slice.actions.connectionClosed());
                break;
        }
    };

export const messageSent = (id: string) => (dispatch: AppDispatch) => {
    dispatch(slice.actions.messageSent({ id }));
};

// TODO verify types of payload
export const messageReceived =
    (type: string, payload: any) => (dispatch: AppDispatch) => {
        switch (type) {
            case MessageTypes.COMPOSITOR_STATE:
                Object.keys(payload).forEach(
                    (p) => (payload[p]!.compositorKey = p),
                );
                dispatch(compositorInitial(payload));
                break;
            /* TODO what is this used for
            case MessageTypes.TRUCK_STATE:
                console.log(payload);
                dispatch({
                    type: ActionTypes.TRUCK_INITIAL,
                    payload: payload,
                });
                break;
            */
            case MessageTypes.MODULE_DASHBOARD_STATE: {
                dispatch(updateModuleState(payload));
                break;
            }
            case MessageTypes.AVAILABLE_MODULES: {
                dispatch(updateAvailableModules(payload));
                break;
            }
            default:
                console.log("Incoming", type, payload);
        }
    };

export interface IWebSocketMessage {
    id: Guid;
    msg: string;
    head?: { CorrelationId?: string } | undefined;
    data: unknown;
    sent: boolean;
}

interface IWebSocketState {
    channelName: string | null;
    isChanging: boolean;
    isConnected: boolean;
    messages: IWebSocketMessage[];
}

interface IMessageSent {
    id: string;
}

const initialState: IWebSocketState = {
    channelName: null,
    isChanging: false,
    isConnected: false,
    messages: [],
};

export interface ConnectionType {
    channelName: string;
    isChanging: boolean;
    isConnected: boolean;
}

const slice = createSlice({
    name: "connection",
    initialState,
    reducers: {
        connectionConnecting: (state) => {
            state.isConnected = false;
        },

        connectionEstablished: (state) => {
            state.isConnected = true;
        },

        connectionClosed: (state) => {
            state.isConnected = false;
        },

        sendMessage: (state, action: PayloadAction<OutGoing>) => {
            const { type, payload, head } = action.payload;

            const webSocketMessage = {
                id: newGuid(),
                msg: type,
                head,
                data: payload,
                sent: false,
            };

            state.messages.push(webSocketMessage);
        },

        messageSent: (state, action: PayloadAction<IMessageSent>) => {
            const idx = state.messages.findIndex(
                (m) => m.id === action.payload.id,
            );
            if (idx > -1) {
                state.messages.splice(idx, 1);
            }
        },
    },
});

export const sendWebSocketMessage = slice.actions.sendMessage;

export default slice.reducer;
