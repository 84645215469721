import cn from "classnames";
import { ISponsorLogo } from "../../types";

////////////////////

interface ISponsorLogoRowProps {
    sponsor: ISponsorLogo;
    isSelected: boolean;
    onSelect: (sponsor: string) => void;
}

const SponsorLogoRow = ({
    sponsor,
    isSelected,
    onSelect,
}: ISponsorLogoRowProps) => {
    const handleClick = () => onSelect(sponsor.id);
    const classNames = cn("sponsor-logo-table-row", { selected: isSelected });

    return (
        <div className={classNames} onClick={handleClick}>
            <div className="logo-cell">
                <img alt={sponsor.name} src={sponsor.small} />
            </div>
            <span>{sponsor.name}</span>
        </div>
    );
};

////////////////////

interface ISponsorLogoTableProps {
    sponsors: ISponsorLogo[];
    selectedSponsorId: string | undefined;
    onSponsorSelected: (sponsor: string) => void;
}

const SponsorLogoTable = ({
    sponsors,
    selectedSponsorId,
    onSponsorSelected,
}: ISponsorLogoTableProps) => {
    return (
        <div className="sponsor-logo-table">
            <h2>Select a sponsor logo</h2>

            <div className="sponsor-logo-table-rows">
                {sponsors.map((sponsor) => (
                    <SponsorLogoRow
                        key={sponsor.id}
                        sponsor={sponsor}
                        isSelected={sponsor.id === selectedSponsorId}
                        onSelect={onSponsorSelected}
                    />
                ))}
            </div>
        </div>
    );
};

export default SponsorLogoTable;
