import { Middleware } from "redux";
import { IGlobalState } from "../../../state/store";
import { setSelectedHorse } from "./Module.Redux";

const resetSelectionsMiddleware: Middleware<unknown, IGlobalState> =
    (store) => (next) => (action) => {
        // Since we can't intercept the updateModuleState action, we use middleware to dispatch actions to reset
        // selections if the race/meet has changed.
        const { moduleState: lastModuleState } = store.getState();
        const result = next(action);

        // If the updated state contains a change to the race number, then reset whatever the user was doing
        const { moduleState } = store.getState();

        const lastRaceNumber =
            (lastModuleState.selectedRace &&
                lastModuleState.selectedRace.raceNumber) ||
            0;
        const raceNumber =
            (moduleState.selectedRace && moduleState.selectedRace.raceNumber) ||
            lastRaceNumber;

        if (lastRaceNumber !== raceNumber) {
            store.dispatch(setSelectedHorse(null));
        }

        return result;
    };

export default resetSelectionsMiddleware;
