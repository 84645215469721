import { useState } from "react";
import cn from "classnames";
import { IRaceDetails, RaceIdentity } from "../../types";
import Heartbeats from "./Heartbeats";
import RaceSponsorDialog from "../RaceSponsor/RaceSponsorDialog";
import RaceDetails from "./RaceDetails";

import "./styles.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface IRaceTitleProps {
    raceIdentity: RaceIdentity;
    race: IRaceDetails;
}

const RaceTitleBarLogo = (props: {
    raceIdentity: RaceIdentity;
    race: IRaceDetails;
    logo: string;
}) => {
    const { raceIdentity, race, logo } = props;

    const [showDialog, setShowDialog] = useState(false);
    const dialogProps = {
        open: showDialog,
        onClose: () => setShowDialog(false),
    };

    if (!raceIdentity) {
        return null;
    }

    const classNames = cn("logo", { "no-logo": !logo });

    return (
        <div className={classNames}>
            {showDialog && (
                <RaceSponsorDialog
                    {...dialogProps}
                    raceIdentity={raceIdentity}
                    race={race}
                />
            )}

            {logo && (
                <img
                    src={props.logo}
                    alt="logo"
                    onClick={() => setShowDialog(true)}
                />
            )}
            {!logo && (
                <FontAwesomeIcon
                    icon={faQuestionCircle}
                    size="2x"
                    onClick={() => setShowDialog(true)}
                />
            )}
        </div>
    );
};

const RaceTitle = ({ race, raceIdentity }: IRaceTitleProps) => {
    if (!race) {
        return null;
    }

    return (
        <section className="race-title-bar">
            <RaceTitleBarLogo
                raceIdentity={raceIdentity}
                race={race}
                logo={race.logo}
            />
            <RaceDetails raceIdentity={raceIdentity} race={race} />
            <Heartbeats heartbeat={race.heartbeat} />
        </section>
    );
};

export default RaceTitle;
