import { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import usePrevious from "core/hooks/usePrevious";
import { useTruck } from "../withTruck/index";
import ModuleButton from "./ModuleDetails/ModuleButton";
import { routeFactory } from "components/StandardLayout/Routes";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { onSwitchTenant } from "state/Auth";

import ATCIcons from "modules/atc/icons";
import TimingIcons from "modules/triples/icons";

type ModuleInfo = {
    name: string;
    icon: string;
};

const moduleInfo: { [id: string]: ModuleInfo } = {
    RacingAustralia: {
        name: "Racing NSW",
        icon: ATCIcons.RNSW,
    },
    AtcHomestraight: {
        name: "ATC Homestraight module",
        icon: ATCIcons.Homestraight,
    },
    ATCBigScreen: {
        name: "ATC Big Screen",
        icon: TimingIcons.ATC,
    },
    AtcWinningOwner: {
        name: "ATC Winning Owner",
        icon: TimingIcons.ATC,
    },
    RedcliffeInternalScreens: {
        name: "Redcliffe Internal Screens",
        icon: ATCIcons.RedcliffeHarness,
    },
    TripleS: {
        name: "ATC Timing",
        icon: TimingIcons.ATC,
    },
    BreezeUp: {
        name: "Magic Millions Sales Event",
        icon: TimingIcons.MagicMillions,
    },
    SkySTC: {
        name: "Sky STC",
        icon: TimingIcons.Sky,
    },
    SkyOne: {
        name: "Sky One",
        icon: TimingIcons.Sky,
    },
    SkyHarness: {
        name: "Sky Harness Timing",
        icon: TimingIcons.Sky,
    },
    MRC: {
        name: "MRC Timing",
        icon: TimingIcons.MRC,
    },
    HRV: {
        name: "HRV Timing",
        icon: TimingIcons.HRV,
    },
    QldHarnessRacing: {
        name: "QLD Harness Timing",
        icon: TimingIcons.RQLD,
    },
    QldHorseRacing: {
        name: "QLD Horseracing Timing",
        icon: TimingIcons.RQLD,
    },
};

export default function TruckPage() {
    const { compositor, truck, truckId } = useTruck();
    const tenantId = useParams<{ tenantId: string }>().tenantId;

    const currentTenantId = useAppSelector((state) => state.auth.currentTenant);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (tenantId && tenantId !== currentTenantId) {
            dispatch(onSwitchTenant(tenantId));
        }
    }, [tenantId, currentTenantId, dispatch]);

    const prevTruck = usePrevious(truck);
    const autoRedirect =
        prevTruck && !truck && compositor && tenantId
            ? routeFactory.toTruckOpenScreen({
                  tenantId,
                  compositorId: compositor.compositorKey,
              })
            : null;

    const [redirectTo, setRedirectTo] = useState<string | null>(autoRedirect);

    const handleModuleSelected = useCallback(
        (moduleId: string) => {
            if (tenantId && truckId) {
                const redirectTo = routeFactory.toModule({
                    tenantId,
                    truckId,
                    moduleId,
                });
                setRedirectTo(redirectTo);
            }
        },
        [truckId, tenantId],
    );

    const moduleIds = useAppSelector((state) => state.availableModules);
    const modules = useMemo(() => {
        if (!(truck && truck.modules && moduleIds)) {
            return null;
        }

        return moduleIds.map((module) => ({
            id: module,
            name: moduleInfo[module]?.name ?? "Unknown module",
            icon: moduleInfo[module]?.icon ?? "",
            active: truck.modules[module] !== undefined,
        }));
    }, [moduleIds, truck]);

    if (redirectTo) {
        return <Navigate to={redirectTo} />;
    }

    if (!compositor) {
        return null;
    }

    const module = modules?.[0];
    if (module && modules.length == 1) {
        handleModuleSelected(module.id);
        return null;
    }

    return (
        <section>
            <div className="section-banner center-center">Select modules</div>

            <div className="pure-g">
                {modules?.map((module) => (
                    <div className="pure-u-1-5" key={module.id}>
                        <ModuleButton
                            id={module.id}
                            name={module.name}
                            icon={module.icon}
                            active={module.active}
                            onSelected={handleModuleSelected}
                        />
                    </div>
                ))}
            </div>
        </section>
    );
}
