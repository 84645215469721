import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDog,
    faHorseHead,
    faWheelchair,
    faHorse,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { RaceType } from "modules/atc/types";

interface IRaceTypeIconProps {
    raceType: RaceType | string;
}

export default function RaceTypeIcon(props: IRaceTypeIconProps) {
    let icon = <FontAwesomeIcon icon={faQuestionCircle} />;
    switch (props.raceType) {
        case "G":
            icon = <FontAwesomeIcon icon={faDog} />;
            break;
        case "R":
            icon = <FontAwesomeIcon icon={faHorse} />;
            break;
        case "H":
            icon = (
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faWheelchair} />
                    <FontAwesomeIcon
                        icon={faHorseHead}
                        transform={{ size: 10, x: 10, y: -6 }}
                    />
                </span>
            );
            break;
    }

    return icon;
}
