import * as fromCompositors from "./Compositor.redux";
import { IGlobalState } from "./store";
import { CompositorTruckDetails } from "../resources/harmonyTypes";

// ----- SELECTORS ---------------------------------------------------

export const getTruckById = (
    state: IGlobalState,
    truckId: string,
): CompositorTruckDetails | undefined => {
    return fromCompositors.getTruckById(state.compositor, truckId);
};
