import { useState } from "react";

import useEffectAsync from "core/hooks/useEffectAsync";
import { harmony } from "components/WebSocketConnection/WebSocketConnection";
import AssetList from "components/AssetManagement/AssetList";
import AssetListItem from "components/AssetManagement/AssetListItem";

import SponsorIcon from "./SponsorIcon";
import { ISponsor, ISponsorAndVenue } from "modules/triples/types";
import AssetUploadForm from "components/AssetManagement/AssetUploadForm";

export default function SponsorAndVenueAssetPage(props: { moduleId: string }) {
    const [sponsors, setSponsors] = useState<ISponsorAndVenue[]>([]);

    async function updateSponsors() {
        const sponsors = await harmony.get<ISponsorAndVenue[]>(
            props.moduleId,
            "GetAllVenueSponsors",
        );
        sponsors.sort((left, right) => {
            const leftName = left.venueName;
            const rightName = right.venueName;
            if (leftName == null && rightName == null) {
                return left.venueId.localeCompare(right.venueId);
            } else if (leftName == null) {
                return 1;
            } else if (rightName == null) {
                return -1;
            } else {
                return leftName
                    .toLocaleLowerCase()
                    .localeCompare(rightName.toLocaleLowerCase());
            }
        });
        setSponsors(sponsors);
    }

    useEffectAsync(updateSponsors);

    async function handleDelete(venueId: string) {
        await harmony.post(props.moduleId, "RemoveSponsorForVenue", {
            selectedDate: "",
            venueId,
        });
        setSponsors(sponsors.filter((sponsor) => sponsor.venueId != venueId));
    }

    const assets = sponsors.map((sponsor) => {
        const venueName = sponsor.venueName
            ? `${sponsor.venueName} (${sponsor.venueId})`
            : `Venue ${sponsor.venueId}`;
        return (
            <AssetListItem
                key={`list-item-${sponsor.id}-${sponsor.venueId}`}
                name={`${venueName} => ${sponsor.name}`}
                icon={
                    <SponsorIcon moduleId={props.moduleId} sponsor={sponsor} />
                }
                onDelete={() => handleDelete(sponsor.venueId)}
            />
        );
    });

    return (
        <AssetList
            renderUploadForm={(p) => (
                <SponsorSelectorForVenue moduleId={props.moduleId} {...p} />
            )}
            onUpload={updateSponsors}
        >
            {assets}
        </AssetList>
    );
}

function SponsorSelectorForVenue(props: {
    moduleId: string;
    onFinish: (_: unknown) => void;
}) {
    const [venueId, setVenueId] = useState<string>("");
    const [venueName, setVenueName] = useState<string>("");
    const [sponsorId, setSponsorId] = useState<number>(-1);

    const [sponsors, setSponsors] = useState<ISponsor[]>([]);

    async function updateSponsors() {
        const sponsors = await harmony.get<ISponsor[]>(
            props.moduleId,
            "LoadSponsorList",
        );
        sponsors.sort((left, right) =>
            left.name
                .toLocaleLowerCase()
                .localeCompare(right.name.toLocaleLowerCase()),
        );
        setSponsors(sponsors);
        if (sponsors.length > 0) {
            setSponsorId(sponsors[0]!.id);
        }
    }

    useEffectAsync(updateSponsors);

    const handleSelected = async () => {
        if (!venueId || !venueName || sponsorId == -1) {
            throw "You must enter a venue id, venue name and select a sponsor";
        }

        await harmony
            .post(props.moduleId, `SetSponsorForVenue`, {
                selectedDate: "",
                sponsorId,
                venueId,
                venueName,
            })
            .then(() => {
                props.onFinish(undefined);
            })
            .catch((_err: unknown) => {
                throw "Could not set sponsor for venue. Please wait and try again.";
            });
    };

    const selectedSponsor =
        sponsorId !== -1 ? sponsors.find((s) => s.id === sponsorId) : undefined;

    return (
        <AssetUploadForm onSubmit={handleSelected}>
            <label htmlFor="venue-id">Venue Id</label>
            <input
                name="name"
                type="text"
                value={venueId}
                onChange={(event) => setVenueId(event.target.value)}
            />
            <br />
            <label htmlFor="venue-name">Venue Name</label>
            <input
                name="name"
                type="text"
                value={venueName}
                onChange={(event) => setVenueName(event.target.value)}
            />
            <br />
            <br />
            <select
                onChange={(event) => setSponsorId(parseInt(event.target.value))}
            >
                {sponsors.map((s) => (
                    <option value={s.id} key={`sponsor-${s.id}`}>
                        {s.name}
                    </option>
                ))}
            </select>
            <br />
            <br />
            {selectedSponsor && (
                <>
                    <SponsorIcon
                        moduleId={props.moduleId}
                        sponsor={selectedSponsor}
                    />
                    <br />
                </>
            )}
            <br />
            <br />
        </AssetUploadForm>
    );
}
