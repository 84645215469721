import React, { RefObject } from "react";
import cn from "classnames";
import PlacingError from "./PlacingError";
import { Key } from "ts-keycode-enum";
import { HorseType, ManualPositionType } from "../../../types";

interface IProps {
    placingIndex: number;
    placingNumber?: number | undefined;
    runningNumber: number | string;
    selectedHorse: HorseType | undefined;
    error?: PlacingError | undefined;
    edit: boolean;
    dividendsStatus: "interim" | "final";

    onBeginEdit: (placeNumber: number) => void;
    onEndEdit: (placeNumber: number, moveToNextPlacing: boolean) => void;
    onChange: (
        placeNumber: number,
        runnerNumber: ManualPositionType,
    ) => boolean;
}

export default class PlacingBox extends React.PureComponent<IProps> {
    private readonly inputRef: RefObject<HTMLInputElement> = React.createRef();

    private rejectHorse = false;

    private static getBoxTitle = (placingIndex: number): string | null => {
        // placing index is zero-based
        switch (placingIndex) {
            case 0:
                return "1st";
            case 1:
                return "2nd";
            case 2:
                return "3rd";
            case 3:
                return "4th";
            case 4:
                return "5th";
        }

        return null;
    };

    public render() {
        const {
            placingIndex,
            placingNumber,
            runningNumber,
            error,
            dividendsStatus,
        } = this.props;
        const title = PlacingBox.getBoxTitle(
            placingNumber !== null && placingNumber !== undefined
                ? placingNumber
                : placingIndex,
        );
        if (!title) return null;

        const classNames = cn(
            "running-number",
            {
                duplicate: error === PlacingError.duplicate,
                scratched: error === PlacingError.scratched,
                invalid: error === PlacingError.invalid,
            },
            dividendsStatus,
        );

        return (
            <div className={classNames} onClick={this.handleFocus}>
                <div className="position">{title}</div>
                <input
                    ref={this.inputRef}
                    type="text"
                    value={runningNumber || ""}
                    onKeyPress={this.handleKeyPress}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleNumberChanged}
                    readOnly={!!dividendsStatus}
                />
            </div>
        );
    }

    public componentDidUpdate(prevProps: IProps) {
        const { edit, selectedHorse } = this.props;
        const { edit: wasEditing, selectedHorse: lastSelectedHorse } =
            prevProps;

        if (!edit || !this.inputRef.current) return;

        // Highlight the horse number when first selected
        if (!wasEditing) {
            this.inputRef.current.select();
            return;
        }

        const selectedHorseNumber = selectedHorse?.number;
        const lastSelectedHorseNumber = lastSelectedHorse?.number;

        if (
            selectedHorseNumber &&
            selectedHorseNumber !== lastSelectedHorseNumber
        ) {
            const wasAccepted = this.props.onChange(
                this.props.placingIndex,
                selectedHorseNumber,
            );
            if (wasAccepted) {
                this.props.onEndEdit(this.props.placingIndex, true);
            } else {
                this.inputRef.current.focus();
                this.rejectHorse = true;
            }
        }
    }

    private handleFocus = (): void => {
        if (this.props.dividendsStatus) {
            if (this.inputRef.current) this.inputRef.current.blur();
            return;
        }

        this.props.onBeginEdit(this.props.placingIndex);
    };

    // If the user clicks outside the box, then stop editing this field. We put this inside a timeout to cater for
    // the user's click having been on a horse - this will automatically set the field and move focus along.
    private handleBlur = (): void => {
        if (this.props.dividendsStatus) return;

        setTimeout(() => {
            if (!this.rejectHorse) {
                // Don't raise the event unless this is still the field that is marked as being edited
                const { edit } = this.props;
                if (edit) {
                    this.props.onEndEdit(this.props.placingIndex, false);
                }
            }

            this.rejectHorse = false;
        }, 250);
    };

    private handleKeyPress = (event: React.KeyboardEvent): void => {
        if (this.props.dividendsStatus) return;

        if (event.keyCode === Key.Escape) {
            this.props.onEndEdit(this.props.placingIndex, false);
        }
    };

    private handleNumberChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        if (this.props.dividendsStatus) return;

        const { value } = event.target;
        if (value === "") {
            this.props.onChange(this.props.placingIndex, "");
        }

        const runningNumber = parseInt(event.target.value, 10);
        if (!isNaN(runningNumber)) {
            this.props.onChange(this.props.placingIndex, runningNumber);
        }

        if (value.toUpperCase() === "P") {
            this.props.onChange(this.props.placingIndex, "P");
        }
    };
}
