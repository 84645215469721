import { Route, Routes } from "react-router-dom";
import urlJoin from "url-join";
import {
    StandardLayoutRouteParams,
    routeFactory as parentRoutes,
} from "components/StandardLayout/Routes";
import TimingPage from "./components/TimingPage";

export type IRouteToRaceParams = StandardLayoutRouteParams & {
    date?: string;
    environment?: string;
    venueList?: string;
    venue?: string;
    race?: string;
};

export default function SkyRoutes() {
    // TODO come up with a better way of handling these
    return (
        <Routes>
            <Route path="" element={<TimingPage />} />
            <Route path=":date" element={<TimingPage />} />
            <Route path=":date/:environment" element={<TimingPage />} />
            <Route
                path=":date/:environment/:venueList"
                element={<TimingPage />}
            />
            <Route
                path=":date/:environment/:venueList/:venue/:race"
                element={<TimingPage />}
            />
        </Routes>
    );
}

export const routeFactory = {
    toModuleSelector: (routeParams: IRouteToRaceParams): string | undefined => {
        const { tenantId, truckId } = routeParams;
        if (tenantId && truckId) {
            return parentRoutes.toModuleSelector({ tenantId, truckId });
        }
    },

    hasMeetingDate: (routeParams: IRouteToRaceParams): string | undefined => {
        const { date, tenantId, truckId, moduleId } = routeParams;
        if (date && tenantId && truckId && moduleId) {
            const parent = parentRoutes.toModule({
                tenantId,
                truckId,
                moduleId,
            });
            return urlJoin(parent, date);
        }
    },
    hasEnvironment: (routeParams: IRouteToRaceParams): string | undefined => {
        const dateUrl = routeFactory.hasMeetingDate(routeParams);
        return (
            dateUrl &&
            routeParams.environment &&
            urlJoin(dateUrl, routeParams.environment)
        );
    },
    hasVenueList: (
        eventList: string[],
        routeParams: IRouteToRaceParams,
    ): string | undefined => {
        const environmentUrl = routeFactory.hasEnvironment(routeParams);
        return (
            environmentUrl &&
            eventList &&
            urlJoin(environmentUrl, eventList.join("+"))
        );
    },
    parseVenueList: (venueList: string | undefined): string[] => {
        return venueList?.split("+") ?? [];
    },
    hasRace: (routeParams: IRouteToRaceParams): string | undefined => {
        const eventList = routeParams.venueList?.split("+") ?? [];
        const environmentUrl = routeFactory.hasVenueList(
            eventList,
            routeParams,
        );
        return (
            environmentUrl &&
            routeParams.venue &&
            routeParams.race &&
            urlJoin(environmentUrl, routeParams.venue, routeParams.race)
        );
    },
};
