import { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Event, Race, RaceWithNumber } from "../../redux/redux";
import { routeFactory } from "../../Routes";

import "./SelectionHeader.styles.less";
import RaceCountdownTimer from "./RaceCountdownTimer";

import cn from "classnames";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapMarkerAlt,
    faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

import MultiRaceComponentButton from "../MultiRaceComponentButton/MultiRaceComponentButton";
import { getComponentsForModule } from "../RaceControls/RaceControls";

interface SelectionHeaderProps {
    event: Event;
    selectedRace: number | null;

    environment: string;
    moduleId: string;
}

const getRaceStatus = (minutesToRace: number): string => {
    // Cater for old data where the status wasn't included as part of the race details.
    return minutesToRace >= 0 ? "Normal" : "Paying";
};

export default function SelectionHeader(props: SelectionHeaderProps) {
    const params = useParams();
    const navigate = useNavigate();

    const handleRaceSelected = useCallback(
        (race: RaceWithNumber) => {
            const pathname = routeFactory.hasRace({
                ...params,
                race: race.number.toString(),
            });
            if (pathname) {
                navigate(pathname, { replace: true });
            }
        },
        [params, navigate],
    );

    const handleChangeLocation = useCallback(() => {
        const pathname = routeFactory.hasMeetingVenue(params);
        if (pathname) {
            navigate(pathname, { replace: true });
        }
    }, [params, navigate]);

    const { event, selectedRace, moduleId, environment } = props;

    const meetRaces = sortRaces(event.races);

    return (
        <section className="meeting-selector">
            <div className="row">
                <div
                    className="back"
                    style={{ cursor: "pointer" }}
                    onClick={handleChangeLocation}
                >
                    <FontAwesomeIcon icon={faArrowCircleLeft} size="2x" />
                </div>
                <div
                    key="location"
                    className="row-name option highlight location"
                >
                    <div className="content">
                        <div className="icon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
                        </div>
                        <div className="details">
                            <p>{event.venueName}</p>
                            <p>{event.date}</p>
                        </div>
                    </div>
                </div>
                <div className="multi-enable">
                    <MultiRaceComponentButton
                        title="Enable all races"
                        componentType={getComponentsForModule(moduleId).auto}
                        componentContext={{
                            date: event.date,
                            venueId: event.venueId,
                            environment: environment,
                        }}
                        races={meetRaces.map((race) => race.number)}
                    />
                </div>
                <div className="options">
                    {meetRaces.map((race) => (
                        <RaceButton
                            key={race.number}
                            race={race}
                            date={event.date}
                            isSelected={selectedRace === race.number}
                            onSelected={handleRaceSelected}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

function sortRaces(races: Record<string, Race>) {
    return Object.entries(races)
        .map(([number, race]) => ({
            ...race,
            number: parseInt(number),
        }))
        .sort((a, b) => a.number - b.number);
}

type RaceButtonProps = {
    race: RaceWithNumber;
    date: string;

    isSelected: boolean;
    onSelected: (race: RaceWithNumber) => void;
};

function RaceButton(props: RaceButtonProps) {
    const classNames = cn("selectable option", {
        selected: props.isSelected,
    });

    const { race, onSelected } = props;

    const handleSelected = useCallback(
        () => onSelected(race),
        [race, onSelected],
    );

    const raceTime = moment.utc(race.scheduledTime).local();
    const minutesToRace = raceTime.diff(new Date(), "minutes");

    const raceStatus = getRaceStatus(minutesToRace);
    const detailsClassNames = cn("details", `status-${raceStatus}`);

    return (
        <div
            key={`R${race.number}`}
            className={classNames}
            onClick={handleSelected}
        >
            <div className="content center">
                <div className={detailsClassNames}>
                    <p>
                        R{race.number} - {raceTime.format("HH:mm")}
                    </p>
                    <p className="time-to-race">
                        <RaceCountdownTimer
                            raceStartTime={race.scheduledTime}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
}
