import ComponentButton from "../ComponentButton/ComponentButton";
import { Components, RaceIdentity } from "../../types";

interface IManualPositionsComponentButtonProps {
    raceIdentity: RaceIdentity;
    title: string;
    componentType: Components;
    placings: (number | string)[];
    disabled?: boolean;
}

const ManualPositionsComponentButton = (
    props: IManualPositionsComponentButtonProps,
) => {
    const { raceIdentity, placings } = props;
    const visualContext = { manualPositions: placings };

    return (
        <ComponentButton
            {...props}
            componentContext={raceIdentity}
            visualContext={visualContext}
            hideOthers
        />
    );
};

export default ManualPositionsComponentButton;
